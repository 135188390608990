import {
  useEffect,
  useState,
} from 'react';

import moment from 'moment';
import {
  AlertTriangle,
  Edit3,
} from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { CustomerAction } from 'src/actions/customer.action';
import {
  DEFAULT_ROW_PER_PAGE,
  ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';

import {
  Box,
  Card,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';

import CustomerCareCreateDialog from './CustomerCareCreateDialog';

const CustomerCareListResults = ({ ...rest }) => {
  const dispatch = useDispatch()
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(DEFAULT_ROW_PER_PAGE);
  const [page, setPage] = useState(0);
  const [careResult, setCareResult] = useState({});

  const { history, total } = useSelector(state => state.customer)

  const [open, setOpen] = useState(false);

  const handleClickCare = (customer) => {
    setCareResult(customer)
    setOpen(true);
  };

  const handleClickAlert = (customer) => {

  }

  const handleClose = () => {
    setOpen(false);
  };


  useEffect(() => {
    dispatch(CustomerAction.history({ page, count: limit, status: 'APPOINTMENT' }))
  }, [page, limit])

  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = history.map((customer) => customer.id);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(1));
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  #
                </TableCell>
                <TableCell>
                  Thuê bao
                </TableCell>
                <TableCell>
                  Loai thuê bao
                </TableCell>
                <TableCell>
                  Gói cước
                </TableCell>
                <TableCell>
                  Ngày đăng ký
                </TableCell>
                <TableCell>
                  Ngày hết hạn
                </TableCell>
                <TableCell>
                  Chu kỳ hiện tại
                </TableCell>
                <TableCell>
                  Trạng thái
                </TableCell>
                <TableCell>
                  Gói có thể ĐK
                </TableCell>
                <TableCell>
                  Hành động
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.slice(0, limit).map((care, index) => (
                <TableRow
                  hover
                  key={care.id}
                  selected={selectedCustomerIds.indexOf(care.id) !== -1}
                >
                  <TableCell>
                    {page * limit + index + 1}
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {care.customer ? care.customer.phone : ''}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {care.customer ? care.customer.type : ''}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {care.customer ? care.customer.package : ''}
                  </TableCell>
                  <TableCell>
                    {care.customer ? moment(care.customer.packageRegisterAt).format('DD-MM-YYYY HH:mm:ss') : ''}
                  </TableCell>
                  <TableCell>
                    {care.customer ? moment(care.customer.packageExpiredAt).format('DD-MM-YYYY HH:mm:ss') : ''}
                  </TableCell>
                  <TableCell>
                    {care.customer ? care.customer.packageCycle : ''}
                  </TableCell>
                  <TableCell>
                    {care.customer ? care.customer.state : ''}
                  </TableCell>
                  <TableCell>
                    {care.customer ? care.customer.packagesCanRegister : ''}
                  </TableCell>
                  <TableCell width={180}>
                    <IconButton aria-label="edit" onClick={() => handleClickCare(care.customer)}>
                      <Edit3 color='green' />
                    </IconButton>
                    <IconButton aria-label="edit" onClick={() => handleClickAlert(care.customer)}>
                      <AlertTriangle color='red' />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      />
      <CustomerCareCreateDialog open={open} handleClose={handleClose} customer={careResult} />
    </Card>
  );
};

export default CustomerCareListResults;
