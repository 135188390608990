import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { Search as SearchIcon } from 'react-feather';
import { useDispatch } from 'react-redux';
import { AppAction } from 'src/actions/app.action';
import { MfyAction } from 'src/actions/mfy.action';
import * as XLSX from 'xlsx';

import {
  Box,
  Button,
  Card,
  CardContent,
  InputAdornment,
  SvgIcon,
  TextField,
  Select,
  MenuItem,
} from '@material-ui/core';

import {
  DEFAULT_ROW_PER_PAGE,
} from 'src/utils/constants';

import {
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import dayjs from 'dayjs';

import moment from 'moment';

import CreateEditDialog from './CreateEditDialog';

import { saveAs } from 'file-saver';
import { array } from 'prop-types';

const MfyToolbar = (props) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);
  const [preview, setPreview] = useState(false);
  const [assign, setAssign] = useState(false);
  const { filter } = useSelector(state => state.mfy)
  const { role, info } = useSelector(state => state.profile);
  const [values, setValues] = useState({
    phone: "",
    trang_thai: '-99',
    trang_thai_yeu_cau: '-99',
    trang_thai_thu_tien: '-99',
    startDate: dayjs(filter.startDate, moment(new Date()).format('DD/MM/YYYY')),
    endDate: dayjs(filter.endDate, moment(new Date()).format('DD/MM/YYYY')),
  });

  useEffect(() => {
    const today = new Date();

    const payload = {
      page: 0,
      limit: DEFAULT_ROW_PER_PAGE,
      phone: "",
      trang_thai: '-99',
      trang_thai_yeu_cau: '-99',
      trang_thai_thu_tien: '-99',
      startDate: dayjs(filter.startDate, moment(new Date()).format('DD/MM/YYYY')),
      endDate: dayjs(filter.endDate, moment(new Date()).format('DD/MM/YYYY')),
    };
    dispatch(MfyAction.updateFilter(payload));
  }, []);

  const fileInput = React.useRef();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleTraCuuDTCT = async () => {
    var phone = prompt("Nhập số tra cứu");

    //ok
    if (phone !== null) {
      const response = await dispatch(MfyAction.traCuuDoiTuong({phone}));
      if (response.result == 'success') {
        alert(`${response.mes}`)
      } else {
        alert(`Lỗi không xác định!`)
      }
    }
    //cancel
    else {
      //alert("You clicked Cancel or closed the prompt.");
    }
  };

  const handleImportFile = (e) => {
    e.preventDefault();
    dispatch(AppAction.appLoading(true))
    const files = e.target.files, f = files[0];
    const reader = new FileReader();
    reader.onload = function (e) {
      let data = e.target.result;
      let readedData = XLSX.read(data, { type: 'binary' });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      const temp = dataParse.splice(1).map((c) => ({
        phone: c[1],
        type: c[2],
        package: c[3],
        registerAt: c[4],
        expiredAt: c[5],
        packageCycle: c[6],
        state: c[7],
        status: "NORMAL",
        packageCanRegister: c[8]
      }));
      dispatch(MfyAction.importData(temp));
      dispatch(AppAction.appLoading(false))
      setPreview(true)
      e.target.value = null;
    };
    reader.readAsBinaryString(f)
  }

  const handleClosePreview = () => {
    fileInput.current.value = null;
    setPreview(false)
  }

  const handleCloseAssign = () => {
    fileInput.current.value = null;
    setAssign(false)
  }

  const handleOpenAssign = () => {
    dispatch(MfyAction.countAssign())
    setAssign(true)
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const onLookup = () => {
    const payload = {
      page: 'no_update',
      limit: 'no_update',
      phone: values.phone,
      trang_thai: values.trang_thai,
      trang_thai_yeu_cau: values.trang_thai_yeu_cau,
      trang_thai_thu_tien: values.trang_thai_thu_tien,
      startDate: values.startDate.format("DD/MM/YYYY"),
      endDate: values.endDate.format("DD/MM/YYYY"),
    };
    dispatch(MfyAction.updateFilter(payload));
    dispatch(MfyAction.list())
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onLookup();
    }
  };

  const handleStartDateChange = (date) => {

    setValues({
      ...values,
      startDate: date
    });
  };

  const handleEndDateChange = (date) => {
    setValues({
      ...values,
      endDate: date
    });
  };

  const reindexArray = (array) => {
    let newArray = [];
    array.forEach((item, index) => {
      newArray.push(item);
    });
    return newArray;
  }

  const xuatFile = async () => {
    if (role.priority == 5) return;
    dispatch(AppAction.appLoading(true))
    try {
      const payload = {
        page: 0,
        limit: 9999999999,
        phone: values.phone,
        trang_thai: values.trang_thai,
        trang_thai_yeu_cau: values.trang_thai_yeu_cau,
        trang_thai_thu_tien: values.trang_thai_thu_tien,
        startDate: values.startDate.format("DD/MM/YYYY"),
        endDate: values.endDate.format("DD/MM/YYYY"),
      };
      dispatch(MfyAction.updateFilter(payload));
      const response = await dispatch(MfyAction.list());
      console.log(response)

      const data = [
        [
          '#', 
          'Cty đối tác',
          'Thuê bao chủ',
          'Thuê bao', 
          'Username nhân viên', 
          'Tên nhân viên', 
          'Mã gói', 
          'Trạng thái yêu cầu',
          'Trạng thái',
          'Ghi chú cập nhật trạng thái',
          'Trạng thái thu tiền',
          'Ghi chú cập nhật trạng thái thu tiền',
          'Trạng thái duyệt',
          'Ghi chú cập nhật trạng thái duyệt',
          'Doanh thu', 
          'Loại giá bán', 
          'Doanh thu tính lương',
          'Tỉ lệ doanh thu tính lương',
          'Truy thu',
          'Ghi chú truy thu',
          'Ngày lên đơn',
          'Ghi chú'
        ],
      ];

      if (role.priority > 2) {
        delete data[0][1];
        data[0] = reindexArray(data[0]);
      }

      if (typeof response !== 'undefined' && response != null && typeof response.data !== 'undefined' && response.data && typeof response.data.values !== 'undefined' && response.data.values && response.data.values.length > 0) {
        var count = 1;
        response.data.values.forEach((element) => {
          let trangThaiYeuCau = "";
          let trangThai = "";
          let trangThaiThuTien = "";
          let trangThaiDuyetThongTin = "";

          if (element.trang_thai_yeu_cau == 'cho_soan_y') trangThaiYeuCau = "Chờ soạn Y lần 1";
          else if (element.trang_thai_yeu_cau == 'qua_han') trangThaiYeuCau = "Quá hạn lần 1";
          else if (element.trang_thai_yeu_cau == 'da_soan_y_lan_1') trangThaiYeuCau = "Đã soạn Y lần 1";
          else if (element.trang_thai_yeu_cau == 'cho_soan_y_lan_2') trangThaiYeuCau = "Chờ soạn Y lần 2";
          else if (element.trang_thai_yeu_cau == 'qua_han_lan_2') trangThaiYeuCau = "Quá hạn lần 2";
          else if (element.trang_thai_yeu_cau == 'dang_cho_xu_ly') trangThaiYeuCau = "Đang chờ xử lý";
          else if (element.trang_thai_yeu_cau == 'thanh_cong') trangThaiYeuCau = "Thành công";
          else if (element.trang_thai_yeu_cau == 'thu_hoi') trangThaiYeuCau = "Thu hồi";

          if (element.trang_thai == 'dang_cho_duyet') trangThai = "Đang chờ duyệt";
          else if (element.trang_thai == 'duyet') trangThai = "Duyệt lần 1";
          else if (element.trang_thai == 'duyet_lan_2') trangThai = "Duyệt lần 2";
          else if (element.trang_thai == 'da_soan_y_lan_1') trangThai = "Đã soạn Y lần 1";
          else if (element.trang_thai == 'dang_ky_thanh_cong') trangThai = "Đăng ký thành công";
          else if (element.trang_thai == 'thu_hoi') trangThai = "Thu hồi";
          else if (element.trang_thai == 'huy_qua_han') trangThai = "Hủy quá hạn lần 1";
          else if (element.trang_thai == 'huy_qua_han_lan_2') trangThai = "Hủy quá hạn lần 2";

          if (element.trang_thai_thu_tien == 'da_chuyen_khoan') trangThaiThuTien = "Đã chuyển khoản";
          else if (element.trang_thai_thu_tien == 'da_thu_tien_mat') trangThaiThuTien = "Đã thu tiền mặt";
          else if (element.trang_thai_thu_tien == 'chua_thu_tien') trangThaiThuTien = "Chưa thu tiền";
          else if (element.trang_thai_thu_tien == 'yeu_cau_thu_hoi') trangThaiThuTien = "Yêu cầu thu hồi";

          if (element.trang_thai_duyet_thong_tin == 'dang_cho_duyet') trangThaiDuyetThongTin = "Đang chờ duyệt";
          else if (element.trang_thai_duyet_thong_tin == 'da_duyet') trangThaiDuyetThongTin = "Đã duyệt";
          else if (element.trang_thai_duyet_thong_tin == 'tu_choi') trangThaiDuyetThongTin = "Từ chối";
          
          let pushData = [
            count,
            element.cty ?? "",
            element.so_dien_thoai_chu,
            role.priority < 3 ? element.so_dien_thoai.slice(-9) : ('****' + element.so_dien_thoai.slice(-5)),
            element.username_nv,
            element.ten_nv,
            element.ma_goi,
            trangThaiYeuCau,
            trangThai,
            role.priority < 3 ? (element.username_thay_doi_trang_thai + ' (' + moment(element.thoi_diem_thay_doi_trang_thai ? element.thoi_diem_thay_doi_trang_thai : element.created_at).format('DD-MM-YYYY HH:mm:ss') + ')') : "",
            trangThaiThuTien,
            role.priority < 3 ? (element.username_thay_doi_trang_thai_thu_tien + ' (' + moment(element.thoi_diem_thay_doi_trang_thai_thu_tien ? element.thoi_diem_thay_doi_trang_thai_thu_tien : element.created_at).format('DD-MM-YYYY HH:mm:ss') + ')') : "",
            trangThaiDuyetThongTin,
            role.priority < 3 ? (element.username_duyet_trang_thai_duyet_thong_tin + ' (' + moment(element.thoi_diem_duyet_thong_tin ? element.thoi_diem_duyet_thong_tin : element.created_at).format('DD-MM-YYYY HH:mm:ss') + ')') : "",
            element.doanh_thu, 
            "T" + element.gia_ban_goi,
            element.doanh_thu_tinh_luong,
            element.ti_le_doanh_thu + '%',
            element.truy_thu,
            element.truy_thu > 0 ? "Khách hủy " + element.truy_thu / 30000 + " lần" : "",
            moment(element.created_at).format('DD-MM-YYYY HH:mm:ss'),
            element.note ?? "",
          ];

          if (role.priority > 2) {
            delete pushData[1];
            pushData = reindexArray(pushData);
          }

          data.push(pushData)
          count++;
        })

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'Bao-cao-Mfy.xlsx');
      }

      dispatch(AppAction.appLoading(false))
    } catch (error) {
      if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
    }
  }

  const handleTrangThaiChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const setColor = (value) => {
    if (value == 'cho_soan_y') return 'blue';
    else if (value == 'cho_soan_y_lan_2') return 'blue';
    else if (value == 'qua_han') return 'red';
    else if (value == 'qua_han_lan_2') return 'red';
    else if (value == 'dang_cho_xu_ly') return 'blue';
    else if (value == 'thanh_cong') return 'green';
    else if (value == 'that_bai') return 'red';
    else if (value == 'dang_cho_duyet') return 'blue';
    else if (value == 'duyet') return 'green';
    else if (value == 'duyet_lan_2') return 'green';
    else if (value == 'da_soan_y_lan_1') return 'orange';
    else if (value == 'dang_ky_thanh_cong') return 'green';
    else if (value == 'thu_hoi') return 'red';
    else if (value == 'huy_qua_han') return 'red';
    else if (value == 'huy_qua_han_lan_2') return 'red';
    else if (value == 'da_chuyen_khoan') return 'green';
    else if (value == 'da_thu_tien_mat') return 'purple';
    else if (value == 'chua_thu_tien') return 'red';
    else if (value == 'yeu_cau_thu_hoi') return 'orange';
    else if (value == 'dang_cho_duyet') return 'blue';
    else if (value == 'da_duyet') return 'green';
    else if (value == 'tu_choi') return 'red';
    else return 'black';
  }

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={handleTraCuuDTCT}
        >
          Tra cứu ĐTCT
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleClickOpen}
          style={{marginLeft: 15}}
        >
          Tạo bản ghi
        </Button>
        {role.priority < 5 && <Button
          color="primary"
          variant="contained"
          onClick={xuatFile}
          style={{marginLeft: 15}}
        >
          Xuất file
        </Button>}
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card className="filter_card">
          <CardContent>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box sx={{ maxWidth: '100%' }}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon
                          fontSize="small"
                          color="action"
                          >
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  name="phone"
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  value={values.phone}
                  placeholder="Tìm SĐT"
                  variant="outlined"
                  style={{ height: 56, marginTop: 0, width: "200px", marginLeft: 0 }}
                />
                <DatePicker
                  label="Thời gian bắt đầu"
                  style={{ width: '200px' }}
                  format='DD/MM/YYYY'
                  value={values.startDate}
                  onChange={(newValue) => handleStartDateChange(newValue)}
                  />
                <DatePicker
                  label="Thời gian kết thúc"
                  style={{ width: '200px' }}
                  value={values.endDate}
                  format='DD/MM/YYYY'
                  onChange={(newValue) => handleEndDateChange(newValue)}
                />
                <Select
                  style={{ height: 56, marginTop: 0, width: "250px", marginLeft: 0 }}
                  name="trang_thai_yeu_cau"
                  value={values.trang_thai_yeu_cau || -99}
                  onChange={(newValue) => handleTrangThaiChange(newValue)}
                  variant="outlined"
                  startAdornment={(
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )}
                >
                  <MenuItem value="-99">Trạng thái yêu cầu...</MenuItem>
                  <MenuItem style={{ color: setColor('cho_soan_y') }} value="cho_soan_y">Chờ soạn Y lần 1</MenuItem>
                  <MenuItem style={{ color: setColor('qua_han') }} value="qua_han">Quá hạn lần 1</MenuItem>
                  <MenuItem style={{ color: setColor('da_soan_y_lan_1') }} value="da_soan_y_lan_1">Đã soạn Y lần 1</MenuItem>
                  <MenuItem style={{ color: setColor('cho_soan_y_lan_2') }} value="cho_soan_y">Chờ soạn Y lần 2</MenuItem>
                  <MenuItem style={{ color: setColor('qua_han_lan_2') }} value="qua_han">Quá hạn lần 2</MenuItem>
                  <MenuItem style={{ color: setColor('dang_cho_xu_ly') }} value="dang_cho_xu_ly">Đang chờ xử lý</MenuItem>
                  <MenuItem style={{ color: setColor('thanh_cong') }} value="thanh_cong">Thành công</MenuItem>
                  <MenuItem style={{ color: setColor('thu_hoi') }} value="thu_hoi">Thu hồi</MenuItem>
                </Select>
                <Select
                  style={{ height: 56, marginTop: 0, width: "250px", marginLeft: 0 }}
                  name="trang_thai"
                  value={values.trang_thai || -99}
                  onChange={(newValue) => handleTrangThaiChange(newValue)}
                  variant="outlined"
                  startAdornment={(
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )}
                >
                  <MenuItem value="-99">Trạng thái...</MenuItem>
                  <MenuItem style={{ color: setColor('dang_cho_duyet') }} value="dang_cho_duyet">Đang chờ duyệt</MenuItem>
                  <MenuItem style={{ color: setColor('duyet') }} value="duyet">Duyệt lần 1</MenuItem>
                  <MenuItem style={{ color: setColor('duyet_lan_2') }} value="duyet_lan_2">Duyệt lần 2</MenuItem>
                  <MenuItem style={{ color: setColor('da_soan_y_lan_1') }} value="da_soan_y_lan_1">Đã soạn Y lần 1</MenuItem>
                  <MenuItem style={{ color: setColor('dang_ky_thanh_cong') }} value="dang_ky_thanh_cong">Đăng ký thành công</MenuItem>
                  <MenuItem style={{ color: setColor('thu_hoi') }} value="thu_hoi">Thu hồi</MenuItem>
                  <MenuItem style={{ color: setColor('huy_qua_han') }} value="huy_qua_han">Hủy quá hạn</MenuItem>
                  <MenuItem style={{ color: setColor('huy_qua_han_lan_2') }} value="huy_qua_han_lan_2">Hủy quá hạn lần 2</MenuItem>
                </Select>
                <Select
                  style={{ height: 56, marginTop: 0, width: "250px", marginLeft: 0 }}
                  name="trang_thai_thu_tien"
                  value={values.trang_thai_thu_tien || -99}
                  onChange={(newValue) => handleTrangThaiChange(newValue)}
                  variant="outlined"
                  startAdornment={(
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )}
                >
                  <MenuItem value="-99">Trạng thái thu tiền...</MenuItem>
                  <MenuItem style={{ color: setColor('da_chuyen_khoan') }} value="da_chuyen_khoan">Đã chuyển khoản</MenuItem>
                  {/* <MenuItem style={{ color: setColor('da_thu_tien_mat') }} value="da_thu_tien_mat">Đã thu tiền mặt</MenuItem> */}
                  <MenuItem style={{ color: setColor('chua_thu_tien') }} value="chua_thu_tien">Chưa thu tiền</MenuItem>
                  <MenuItem style={{ color: setColor('yeu_cau_thu_hoi') }} value="yeu_cau_thu_hoi">Yêu cầu thu hồi</MenuItem>
                </Select>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onLookup}
                  style={{ height: 56, marginTop: 0, width: "20%", marginLeft: 15 }}
                  >
                  Tra cứu
                </Button>
              </Box>
            </LocalizationProvider>
          </CardContent>
        </Card>
      </Box>
      <CreateEditDialog open={open} handleClose={handleClose} type={"CREATE"} />
    </Box>
  )
};

export default MfyToolbar;
