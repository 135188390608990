import {
  useEffect,
  useState,
} from 'react';

import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { ReportAction } from 'src/actions/report.acction';
import { RoleAction } from 'src/actions/role.acction';

import {
  DEFAULT_PAGE,
  DEFAULT_ROW_PER_PAGE,
  InvitationStatus,
} from 'src/utils/constants';

import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  CardContent,
  MenuItem,
  TextField,
  InputAdornment,
  SvgIcon,
  Button,
  Select,
} from '@material-ui/core';

import { Search as SearchIcon } from 'react-feather';

import {
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const ReportListResults1 = (props) => {

  const dispatch = useDispatch()
  const { query9332, items9332, total9332, query9084, items9084, total9084, filter1 } = useSelector(state => state.report)
  const [limit, setLimit] = useState(filter1.limit || DEFAULT_ROW_PER_PAGE);
  const [page, setPage] = useState(filter1.page || 0);
  const [items, setItems] = useState([])
  const [query, setQuery] = useState(undefined)
  const [total, setTotal] = useState(0)
  const { role } = useSelector(state => state.profile)

  console.log(useSelector(state => state.report))

  // useEffect(() => {
  //   if (props.type === '9332') {
  //     let payload = {
  //       endDate: moment(new Date()).format('DD/MM/YYYY'),
  //       status: '',
  //       startDate: moment(new Date()).format('DD/MM/YYYY'),
  //       user: '',
  //       page: page,
  //       count: limit
  //     }
  //     dispatch(ReportAction.list9332(payload))
  //   }
  // }, [page, limit])

  // useEffect(() => {
  //   if (props.type === '9332') {
  //     setTotal(total9332)
  //     setItems(items9332)
  //     setQuery(query9332)
  //   }
  // }, [query9332, items9332, total9332])

  // useEffect(() => {
  //   if (props.type === '9084') {
  //     setTotal(total9084)
  //     setItems(items9084)
  //     setQuery(query9084)
  //   }
  // }, [query9084, items9084, total9084])

  useEffect(() => {
    if (props.type === '9332') {
      setTotal(total9332)
      setItems(items9332)
      //setQuery(query9332)
    }
  }, [query9332, items9332, total9332])

  useEffect(() => {
    const payload = {
      ...filter1,
      limit: limit,
      page: page,
    };
    dispatch(ReportAction.updateFilter1(payload));
    dispatch(ReportAction.list9332())
  }, [page, limit]);

  const [values, setValues] = useState({
    phone: "",
    startDate: filter1 ? dayjs(filter1.startDate, moment(new Date()).format('DD/MM/YYYY')) : '',
    endDate: filter1 ? dayjs(filter1.endDate, moment(new Date()).format('DD/MM/YYYY')) : '',
  });

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };


  const onSelected = (id) => {
    dispatch(RoleAction.findOne(id));
  };

  const handleStartDateChange = (date) => {

    setValues({
      ...values,
      startDate: date
    });
  };

  const handleEndDateChange = (date) => {
    setValues({
      ...values,
      endDate: date
    });
  };

  const handlephoneChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const onLookup = () => {
    const payload = {
      page: 'no_update',
      limit: 'no_update',
      phone: values.phone,
      startDate: values.startDate.format("DD/MM/YYYY"),
      endDate: values.endDate.format("DD/MM/YYYY"),
    };
    dispatch(ReportAction.updateFilter1(payload));
    dispatch(ReportAction.list9332())
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onLookup();
    }
  };

  return (
    <><Card className="filter_card">
      <CardContent>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box sx={{ 
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon
                      fontSize="small"
                      color="action"
                      >
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              name="phone"
              onChange={handlephoneChange}
              onKeyDown={handleKeyDown}
              value={values.phone}
              placeholder="Tìm SĐT"
              variant="outlined"
              style={{ height: 56, marginTop: 6, width: "200px", marginLeft: 0 }}
              />
            <DatePicker
              label="Thời gian bắt đầu"
              style={{ width: '200px' }}
              format='DD/MM/YYYY'
              value={values.startDate}
              onChange={(newValue) => handleStartDateChange(newValue)}
              />
            <DatePicker
              label="Thời gian kết thúc"
              style={{ width: '200px' }}
              value={values.endDate}
              format='DD/MM/YYYY'
              onChange={(newValue) => handleEndDateChange(newValue)}
            />
            <Button
              color="primary"
              variant="contained"
              onClick={onLookup}
              style={{ height: 56, marginTop: 6, width: "20%", marginLeft: 0 }}
              >
              Tra cứu
            </Button>
          </Box>
        </LocalizationProvider>
      </CardContent>
    </Card>
    <Card>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  #
                </TableCell>
                <TableCell>
                  •Số thuê bao<br></br>•Tên gói cước<br></br>•Giá gói cước
                </TableCell>
                <TableCell>
                  •Mã nhân viên<br></br>•Tên nhân viên
                </TableCell>
                <TableCell>
                  Trạng thái mời gói
                </TableCell>
                <TableCell>
                  Mã kết quả
                </TableCell>
                <TableCell>
                  Loại giao dịch
                </TableCell>
                <TableCell>
                  Tin nhắn trả về
                </TableCell>
                <TableCell>
                  Thời gian
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items && items.slice(page * limit, page * limit + limit).map((item, index) => (
                <TableRow
                  hover
                  key={item.id}
                >
                  <TableCell>
                    {page * limit + index + 1}
                  </TableCell>
                  <TableCell>
                    •{role.priority < 3 ? item.msisdn.slice(-9) : ('****' + item.msisdn.slice(-5))}
                    <br></br>
                    •{item.packCode}
                    <br></br>
                    •{item.packPrice}
                  </TableCell>
                  <TableCell>
                    {item.invitation != null && "•" + item.invitation.username_nv}
                    {item.invitation != null && <><br></br></>}
                    {item.invitation != null && "•" + item.invitation.ten_nv}
                  </TableCell>
                  <TableCell>
                    {InvitationStatus.filter(stt => stt.code === item.status)[0]?.name}
                  </TableCell>
                  <TableCell >
                    {item.resultCodeMO == "REGISTER_SUCCESS" ? "Thành công" : "Thất bại"}
                  </TableCell>
                  <TableCell >
                    {item.requestType == "GIAHAN" ? "Gia hạn" : (item.requestType == "DK" ? "Đăng ký" : "")}
                  </TableCell>
                  <TableCell width={"20%"}>
                    {item.resultDesMt != null && item.resultDesMt}
                    {item.resultDesMO != null && <><br></br><br></br>----------<br></br><br></br></>}
                    {item.resultDesMO != null && item.resultDesMO}
                  </TableCell>
                  <TableCell>
                    {moment(item.genDate).format("DD/MM/YYYY HH:mm")}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[10, 20, 50]}
      />
    </Card></>
  );
};

export default ReportListResults1;
