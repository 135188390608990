import {
  useEffect,
  useState,
} from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { InviteAction } from 'src/actions/invite.action';
import { InviteTypes } from 'src/utils/constants';

import {
  Box,
  Button,
  TextField,
} from '@material-ui/core';

const InvitaionImmediate = (props) => {

  const dispatch = useDispatch()
  const { renew } = useSelector(state => state.invite)

  const [values, setValues] = useState({
    package: '',
    phone: ''
  });

  useEffect(() => {
    setValues({
      package: '',
      phone: '',
    })
  }, [renew])

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleDK = () => {
    dispatch(InviteAction.inviteImmediate({
      type: InviteTypes.IMMEDIATE,
      phone: values.phone,
      package: values.package,
      command: "DK"
    }))
  }

  const handleGH = () => {
    dispatch(InviteAction.inviteImmediate({
      type: InviteTypes.IMMEDIATE,
      phone: values.phone,
      package: values.package,
      command: "GIAHAN"
    }))
  }

  return (
    <form {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        <TextField
          fullWidth
          label="Tên gói cước"
          margin="normal"
          name="package"
          onChange={handleChange}
          required
          value={values.package}
          variant="outlined"
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <TextField
          fullWidth
          label="Số điện thoại"
          margin="normal"
          name="phone"
          onChange={handleChange}
          required
          value={values.phone}
          variant="outlined"
        />
        <Button
          color="primary"
          variant="contained"
          disabled={!values.phone || !values.package}
          onClick={handleDK}
          style={{ height: 56, marginTop: 6, width: 200, marginLeft: 24 }}
        >
          Đăng ký
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={!values.phone || !values.package}
          onClick={handleGH}
          style={{ height: 56, marginTop: 6, width: 200, marginLeft: 24 }}
        >
          Gia hạn
        </Button>
      </Box>
    </form>
  );
};

export default InvitaionImmediate;
