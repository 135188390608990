import { QuetdtctService } from 'src/services/quetdtct.service';
import { showToast } from 'src/utils/common';
import { NOTI_TYPES } from 'src/utils/constants';

import { AppAction } from './app.action';

export const QUETDTCT_NEW_CARE_SUCCESS = 'QUETDTCT:NEW_CARE_SUCCESS'
export const QUETDTCT_LIST_SUCCESS = 'QUETDTCT:FIND_ALL_SUCCESS'
export const QUETDTCT_LIST_FAILD = 'QUETDTCT:FIND_ALL_FAILD'
export const QUETDTCT_COUNT_ASSIGN_SUCCESS = 'QUETDTCT:COUNT_ASSIGN_SUCCESS'
export const QUETDTCT_COUNT_ASSIGN_FAILD = 'QUETDTCT:COUNT_ASSIGN_FAILD'

export const QUETDTCT_CREATE_SUCCESS = 'QUETDTCT:CREATE_SUCCESS'
export const QUETDTCT_CREATE_FAILD = 'QUETDTCT:CREATE_FAILD'

export const QUETDTCT_IMPORT_SUCCESS = 'QUETDTCT:IMPORT_SUCCESS'
export const QUETDTCT_SAVE_SUCCESS = 'QUETDTCT:SAVE_SUCCESS'
export const QUETDTCT_SAVE_FAILD = 'QUETDTCT:SAVE_SUCCESS'

export const QUETDTCT_DEL_SUCCESS = 'QUETDTCT:DEL_SUCCESS'
export const QUETDTCT_DEL_FAILD = 'QUETDTCT:DEL_FAILD'

export const QUETDTCT_ONE_SUCCESS = 'QUETDTCT:FIND_ONE_SUCCESS'
export const QUETDTCT_ONE_FAILD = 'QUETDTCT:FIND_ONE_FAILD'

export const QUETDTCT_HISTORY_LIST_SUCCESS = 'QUETDTCT:HISTORY_FIND_ALL_SUCCESS'
export const QUETDTCT_HISTORY_LIST_FAILD = 'QUETDTCT:HISTORY_FIND_ALL_FAILD'

export const QUETDTCT_LIST_BY_FILENAME_SUCCESS = 'QUETDTCT:FIND_ALL_BY_FILENAME_SUCCESS'
export const QUETDTCT_LIST_BY_FILENAME_FAILD = 'QUETDTCT:FIND_ALL_BY_FILENAME_FAILD'

export const QUETDTCT_INFO_BY_FILENAME_SUCCESS = 'QUETDTCT:INFO_BY_FILENAME_SUCCESS'
export const QUETDTCT_INFO_BY_FILENAME_FAILD = 'QUETDTCT:INFO_BY_FILENAME_FAILD'

export const QUETDTCT_UPDATE_FILTER = 'QUETDTCT:UPDATE_FILTER'

export const QuetdtctAction = {
    list,
    findAssign,
    create,
    importData,
    saveData,
    update,
    del,
    assign,
    countAssign,
    history,
    findOne,
    saveDataUpload,
    findAllByFileName,
    updateFilter,
    deleteFile,
    getInfoByFileName,
}

function updateFilter(payload) {
    return { type: QUETDTCT_UPDATE_FILTER, data: payload }
}

function deleteFile(FileName) {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        QuetdtctService.deleteFile(FileName).then((rs) => {
            showToast(NOTI_TYPES.SUCCESS, "Xóa file '" + FileName + "' thành công!");
        }).catch((error) => {
            showToast(NOTI_TYPES.ERROR, "Xóa file '" + FileName + "' thất bại!");
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
}

function countAssign() {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        QuetdtctService.countAssign().then((rs) => {
            dispatch(success(rs.data.total));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: QUETDTCT_COUNT_ASSIGN_SUCCESS, data } }
    function failure(error) { return { type: QUETDTCT_COUNT_ASSIGN_FAILD, error } }
}

function findAssign(page = 0, count = 20) {
    return (dispatch, getState) => {
        const { quetdtct } = getState()
        dispatch(AppAction.appLoading(true));
        QuetdtctService.findAssign(page, count).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...quetdtct.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: QUETDTCT_LIST_SUCCESS, data } }
    function failure(error) { return { type: QUETDTCT_LIST_FAILD, error } }
}

function list() {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        const { quetdtct } = getState();
        const page = quetdtct && typeof quetdtct.filter !== 'undefined' && quetdtct.filter && typeof quetdtct.filter.page !== 'undefined' ? quetdtct.filter.page : 0;
        const limit = quetdtct && typeof quetdtct.filter !== 'undefined' && quetdtct.filter && typeof quetdtct.filter.limit !== 'undefined' ? quetdtct.filter.limit : 10;
        QuetdtctService.findAll(page, limit).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                const oldItems = quetdtct.items;
                const rsDataValues = rs.data.values;

                var uniqueItems = oldItems;
                for (var i = limit * page; i < limit * page + limit; i++) {
                    if (typeof rsDataValues[i - limit * page] !== 'undefined') {
                        uniqueItems[i] = rsDataValues[i - limit * page];
                    } else if (typeof uniqueItems[i] !== 'undefined') {
                        delete uniqueItems[i];
                    }
                }
                
                dispatch(success({
                    items: uniqueItems,
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: QUETDTCT_LIST_SUCCESS, data } }
    function failure(error) { return { type: QUETDTCT_LIST_FAILD, error } }
}

function getInfoByFileName(payload) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            QuetdtctService.getInfoByFileName(payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    }
    function success(data) { return { type: QUETDTCT_INFO_BY_FILENAME_SUCCESS, data } }
    function failure(error) { return { type: QUETDTCT_INFO_BY_FILENAME_FAILD, error } }
}

function findAllByFileName(payload) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            QuetdtctService.findAllByFileName(payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    }
    function success(data) { return { type: QUETDTCT_LIST_BY_FILENAME_SUCCESS, data } }
    function failure(error) { return { type: QUETDTCT_LIST_BY_FILENAME_FAILD, error } }
}

function importData(payload) {
    return { type: QUETDTCT_IMPORT_SUCCESS, data: payload }
}

function saveData(payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        let count = 0
        for (let i = 0; i < payload.length; i += 200) {
            // eslint-disable-next-line no-loop-func
            QuetdtctService.save(payload.slice(i, i + 200)).then((rs) => {
                count += parseInt(rs.data.saved, 10)
                if (count === payload.length) {
                    dispatch(success(rs));
                    dispatch(QuetdtctAction.list())
                    dispatch(AppAction.appLoading(false));
                }
            }).catch((error) => {
                dispatch(failure(error));
                dispatch(AppAction.appLoading(false));
            })
        }
    };

    function success(data) { return { type: QUETDTCT_SAVE_SUCCESS, data } }
    function failure(error) { return { type: QUETDTCT_SAVE_FAILD, error } }
}

function create(payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        QuetdtctService.create(payload).then((rs) => {
            dispatch(success(rs));
            dispatch(QuetdtctAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: QUETDTCT_CREATE_SUCCESS, data } }
    function failure(error) { return { type: QUETDTCT_CREATE_FAILD, error } }
}

function update(id, payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        QuetdtctService.update(id, payload).then((rs) => {
            dispatch(success(rs));
            dispatch(QuetdtctAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: QUETDTCT_CREATE_SUCCESS, data } }
    function failure(error) { return { type: QUETDTCT_CREATE_FAILD, error } }
}

function del(id) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        QuetdtctService.delete(id).then((rs) => {
            dispatch(success(rs));
            dispatch(QuetdtctAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: QUETDTCT_DEL_SUCCESS, data } }
    function failure(error) { return { type: QUETDTCT_DEL_FAILD, error } }
}

function findOne(id) {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        QuetdtctService.find(id).then((rs) => {
            dispatch(success(rs.data));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: QUETDTCT_ONE_SUCCESS, data } }
    function failure(error) { return { type: QUETDTCT_ONE_FAILD, error } }
}

function assign(payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        QuetdtctService.assign(payload).then((rs) => {
            showToast('success', "Phân chia dữ liệu thành công.")
            dispatch(QuetdtctAction.list())
        }).catch((error) => {
            showToast('error', "Phân chia dữ liệu thất bại.\nVui lòng thử lại lần sau.")
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
}

function history(payload) {
    return (dispatch, getState) => {
        const { quetdtct } = getState()
        dispatch(AppAction.appLoading(true));
        QuetdtctService.history(payload).then((rs) => {
            if (payload.page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...quetdtct.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: QUETDTCT_HISTORY_LIST_SUCCESS, data } }
    function failure(error) { return { type: QUETDTCT_HISTORY_LIST_FAILD, error } }
}

function saveDataUpload(payload) {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        let count = 0
        for (let i = 0; i < payload.length; i += 200) {
            // eslint-disable-next-line no-loop-func
            QuetdtctService.save(payload.slice(i, i + 200)).then((rs) => {
                count += parseInt(rs.data.saved, 10)
                if (count === payload.length) {
                    dispatch(success(rs));
                    dispatch(AppAction.appLoading(false));
                }
            }).catch((error) => {
                dispatch(failure(error));
                dispatch(AppAction.appLoading(false));
            })
        }
    };

    function success(data) { return { type: QUETDTCT_SAVE_SUCCESS, data } }
    function failure(error) { return { type: QUETDTCT_SAVE_FAILD, error } }
}