import {
  useEffect,
  useState,
} from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { Helmet } from 'react-helmet';
import InvitaionImmediate from 'src/components/invitation/InvitaionImmediate';
import InvitaionRenew from 'src/components/invitation/InvitaionRenew';
import InvitaionSelling from 'src/components/invitation/InvitaionSelling';
import InvitationOTP from 'src/components/invitation/InvitationOTP';
import InvitationOTPnew from 'src/components/invitation/InvitationOTPnew';
import InvitationOTPnew1 from 'src/components/invitation/InvitationOTPnew1';
import InvitationOTPnew2 from 'src/components/invitation/InvitationOTPnew2';
import ReportListResults from '../components/report/ReportListResults';
import ReportListResultsnew from '../components/report/ReportListResultsnew';
import ReportListResultsnew1 from '../components/report/ReportListResultsnew1';
import ReportListResultsnew2 from '../components/report/ReportListResultsnew2';
import ReportListResults1 from '../components/report/ReportListResults1';

import {
  Box,
  Container,
  Divider,
  Paper,
  Tab,
  Tabs,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { AccountAction } from 'src/actions/account.acction';

const useStyles = makeStyles((theme) => ({
  // root: {
  //   backgroundColor: theme.palette.background.paper,
  //   width: '100%',
  // },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

const Invitation = () => {
  const dispatch = useDispatch()
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [maxacnhan1, setMaXacnhan1] = useState(false);
  const [maxacnhan2, setMaXacnhan2] = useState(false);
  const [maxacnhan3, setMaXacnhan3] = useState(false);
  const [maxacnhan4, setMaXacnhan4] = useState(false);
  const { permissions, role, info } = useSelector(state => state.profile)
  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(value, newValue);
  };

  useEffect(() => {
    const getChannel = async () => {
      let channel = null;
      let channelBrand = null;

      let channel1 = null;
      let channelBrand1 = null;

      let channel2 = null;
      let channelBrand2 = null;

      let channel3 = null;
      let channelBrand3 = null;

      try {
        channel = await dispatch(AccountAction.findChannel(info.username, 'ma_xac_nhan_1'));
      } catch (e) {

      }

      try {
        channelBrand = await dispatch(AccountAction.findChannel(typeof info.brand !== 'undefined' && typeof info.brand.username !== 'undefined' ? info.brand.username : null, 'ma_xac_nhan_1'));
      } catch (e) {

      }

      try {
        channel1 = await dispatch(AccountAction.findChannel(info.username, 'ma_xac_nhan_2'));
      } catch (e) {

      }

      try {
        channelBrand1 = await dispatch(AccountAction.findChannel(typeof info.brand !== 'undefined' && typeof info.brand.username !== 'undefined' ? info.brand.username : null, 'ma_xac_nhan_2'));
      } catch (e) {

      }

      try {
        channel2 = await dispatch(AccountAction.findChannel(info.username, 'ma_xac_nhan_3'));
      } catch (e) {

      }

      try {
        channelBrand2 = await dispatch(AccountAction.findChannel(typeof info.brand !== 'undefined' && typeof info.brand.username !== 'undefined' ? info.brand.username : null, 'ma_xac_nhan_3'));
      } catch (e) {

      }

      try {
        channel3 = await dispatch(AccountAction.findChannel(info.username, 'ma_xac_nhan_4'));
      } catch (e) {

      }

      try {
        channelBrand3 = await dispatch(AccountAction.findChannel(typeof info.brand !== 'undefined' && typeof info.brand.username !== 'undefined' ? info.brand.username : null, 'ma_xac_nhan_4'));
      } catch (e) {

      }

      console.log(channel, channelBrand, channel1, channelBrand1, channel2, channelBrand2, info);

      if (role.priority < 3) {
        setMaXacnhan1(true);
        setMaXacnhan2(true);
        setMaXacnhan3(true);
        setMaXacnhan4(true);
      } else {
        if (channel && channel.active || channelBrand && channelBrand.active) setMaXacnhan1(true);
        else setMaXacnhan1(false);

        if (channel1 && channel1.active || channelBrand1 && channelBrand1.active) setMaXacnhan2(true);
        else setMaXacnhan2(false);

        if (channel2 && channel2.active || channelBrand2 && channelBrand2.active) setMaXacnhan3(true);
        else setMaXacnhan3(false);

        if (channel3 && channel3.active || channelBrand3 && channelBrand3.active) setMaXacnhan4(true);
        else setMaXacnhan4(false);
      }
    }
    getChannel()
  }, [])

  return (
    <>
      <Helmet>
        <title>Mời gói cước</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={true}>
          <Paper className={classes.root}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              {permissions && permissions.filter((p) => p.code.includes('care.')).map((per, idx) => {
                if (per.code === 'care.otp') {
                  return (
                    <Tab label="Mã xác nhận 1" style={{ flex: 1, height: 72, display: (maxacnhan1 ? 'inherit' : 'none') }} />
                  )
                }

                if (per.code === 'care.otpnew') {
                  return (
                    <Tab label="Mã xác nhận 2" style={{ flex: 1, height: 72, display: (maxacnhan2 ? 'inherit' : 'none') }} />
                  )
                }

                if (per.code === 'care.otpnew1') {
                  return (
                    <Tab label="Mã xác nhận 3" style={{ flex: 1, height: 72, display: (maxacnhan3 ? 'inherit' : 'none') }} />
                  )
                }

                if (per.code === 'care.otpnew2') {
                  return (
                    <Tab label="Mã xác nhận 4" style={{ flex: 1, height: 72, display: (maxacnhan4 ? 'inherit' : 'none') }} />
                  )
                }

                if (per.code === 'care.9332') {
                  return (
                    <Tab label="Gia hạn gói cước" style={{ flex: 1, height: 72 }} />
                  )
                }

                if (per.code === 'care.selling') {
                  return (
                    <Tab label="Bán gói cước" style={{ flex: 1, height: 72 }} />
                  )
                }

                if (per.code === 'care.dk') {
                  return (
                    <Tab label="Đăng ký trực tiếp" style={{ flex: 1, height: 72 }} />
                  )
                }
                return null
              })}
            </Tabs>
            <Divider />
            {permissions && permissions.filter((p) => p.code.includes('care.')).map((per, idx) => {

              if (per.code === 'care.otp') {
                return (
                  <TabPanel value={value} index={idx} style={{ display: (maxacnhan1 ? 'inherit' : 'none') }}>
                    <InvitationOTP />
                    <Box sx={{ pt: 3 }}>
                      <ReportListResults type='9084' />
                    </Box>
                  </TabPanel>
                )
              }

              if (per.code === 'care.otpnew') {
                return (
                  <TabPanel value={value} index={idx} style={{ display: (maxacnhan2 ? 'inherit' : 'none') }}>
                    <InvitationOTPnew />
                    <Box sx={{ pt: 3 }}>
                      <ReportListResultsnew type='9084new' />
                    </Box>
                  </TabPanel>
                )
              }

              if (per.code === 'care.otpnew1') {
                return (
                  <TabPanel value={value} index={idx} style={{ display: (maxacnhan3 ? 'inherit' : 'none') }}>
                    <InvitationOTPnew1 />
                    <Box sx={{ pt: 3 }}>
                      <ReportListResultsnew1 type='9084new1' />
                    </Box>
                  </TabPanel>
                )
              }

              if (per.code === 'care.otpnew2') {
                return (
                  <TabPanel value={value} index={idx} style={{ display: (maxacnhan4 ? 'inherit' : 'none') }}>
                    <InvitationOTPnew2 />
                    <Box sx={{ pt: 3 }}>
                      <ReportListResultsnew2 type='9084new2' />
                    </Box>
                  </TabPanel>
                )
              }

              if (per.code === 'care.9332') {
                return (
                  <TabPanel value={value} index={idx}>
                    <InvitaionRenew />
                    <Box sx={{ pt: 3 }}>
                      <ReportListResults1 type='9332' />
                    </Box>
                  </TabPanel>
                )
              }

              if (per.code === 'care.selling') {
                return (
                  <TabPanel value={value} index={idx}>
                    <InvitaionSelling />
                  </TabPanel>
                )
              }

              if (per.code === 'care.dk') {
                return (
                  <TabPanel value={value} index={idx}>
                    <InvitaionImmediate />
                    <Box sx={{ pt: 3 }}>
                      <ReportListResults1 type='9332' />
                    </Box>
                  </TabPanel>
                )
              }
              return null
            })}
          </Paper>
        </Container>
      </Box>
    </>
  )
};

export default Invitation;
