import React, { useEffect, useState } from 'react';

import { Search as SearchIcon } from 'react-feather';
import dayjs from 'dayjs';

import {
  Box,
  Button,
  Card,
  CardContent,
  InputAdornment,
  SvgIcon,
  TextField,
  MenuItem,
} from '@material-ui/core';

import Select from 'react-select';

import {
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import {
  DEFAULT_ROW_PER_PAGE,
} from 'src/utils/constants';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { TinhluongAction } from 'src/actions/tinhluong.action';
import { AccountAction } from 'src/actions/account.acction';

import moment from 'moment';

import { AppAction } from 'src/actions/app.action';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const TinhluongListToolbar = (props) => {
  const dispatch = useDispatch();
  const { filter } = useSelector(state => state.tinhluong)
  const { items, total } = useSelector(state => state.account)
  const [preview, setPreview] = useState(false);

  const [values, setValues] = useState({
    message: "",
    username: "",
    startDate: dayjs(filter.startDate, moment(new Date()).format('MM/YYYY')),
  });

  const handleStartDateChange = (date) => {

    setValues({
      ...values,
      startDate: date
    });
  };

  const handleEndDateChange = (date) => {
    setValues({
      ...values,
      endDate: date
    });
  };

  const handleMessageChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleUsernameChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleUsernameChange1 = (name, newValue) => {
    setValues({
      ...values,
      [name]: newValue
    });
  };

  useEffect(() => {
    const today = new Date();

    const payload = {
      page: 0,
      limit: DEFAULT_ROW_PER_PAGE,
      message: "",
      username: "",
      startDate: dayjs(new Date(today.getFullYear(), today.getMonth(), 1)).format("MM/YYYY"),
    };
    dispatch(TinhluongAction.updateFilter(payload));
    dispatch(AccountAction.listNoLimit());
  }, []);

  const onLookup = () => {
    const payload = {
      page: 'no_update',
      limit: 'no_update',
      message: values.message,
      username: values.username,
      startDate: values.startDate.format("MM/YYYY"),
    };
    dispatch(TinhluongAction.updateFilter(payload));
    dispatch(TinhluongAction.list())
  };

  const exportExcel = async () => {
    dispatch(AppAction.appLoading(true))
    try {
      const payload = {
        page: 0,
        limit: 9999999999,
        message: values.message,
        username: values.username,
        startDate: values.startDate.format("MM/YYYY"),
      };
      dispatch(TinhluongAction.updateFilter(payload));
      const response = await dispatch(TinhluongAction.listExport());

      const data = [
        [
          '#', 
          'Username nhân viên',
          'Tên nhân viên', 
          'Ngày công thực tế', 
          'Mức lương CB',  
          'Lương theo ngày công',
          'Lương học việc',
          'Chuyên cần',
          'Tổng lương cơ bản (1)',
          'Tổng doanh thu VAS tính lương',
          'Tổng doanh thu giá gốc',
          'BQ doanh thu /ngày',
          'Mức % hoa hồng',
          'Hoa hồng thực tính (2)',
          'HH dịch vụ',
          'HH sim',
          'Thi đua tháng',
          'Thưởng KK',
          'Thưởng lễ/ Tết',
          'PCTN/ Hỗ trợ BHXH',
          'Nhận khác',
          'Tổng thưởng/ PC khác (3)',
          'Tổng lương tháng',
          'BHXH',
          'Phí công đoàn (1% - Max150k)',
          'Chế tài',
          'Truy thu đã trừ ck',
          'Ký quỹ/ Tạm ứng',
          'Tổng khấu trừ (4)',
          'Lương thực nhận',
        ],
      ];

      if (typeof response !== 'undefined' && response != null && typeof response.data !== 'undefined' && response.data != null && typeof response.data.values !== 'undefined' && response.data.values != null && response.data.values.length > 0) {
        var count = 1;
        response.data.values.forEach((element) => {
          data.push([
            count,
            element.username,
            element.fullname,
            element.ngayCongThucTe,
            element.luongCoBan,
            element.luongTheoNgayCong,
            element.luongHocViec,
            element.chuyenCan,
            element.tongLuongCoBan,
            element.tongDoanhThuVasTinhLuong,
            element.tongDoanhThuGiaGoc,
            element.binhQuanDoanhThuTheoNgay,
            element.hoaHongPhanTram + '%',
            element.hoaHongThucTinh,
            element.hoaHongDichVu,
            element.hoaHongSim,
            element.thiDuaThang,
            element.thuongKhuyenKhich,
            element.thuongLeTet,
            element.hoTroBaoHiemXaHoi,
            element.nhanKhac,
            element.tongThuongPhuCap,
            element.tongLuongThang,
            element.baoHiemXaHoi,
            element.phiCongDoan,
            element.tongCheTai,
            element.truyThuSauChietKhau,
            element.kyQuyTamUng,
            element.tongKhauTru,
            element.luongThucNhan,
          ])
          count++;
        })

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'Doi-soat-doanh-thu-tinh-luong.xlsx');
      }

      dispatch(AppAction.appLoading(false))
    } catch (error) {
      if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
    }
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onLookup();
    }
  };

  const nhapFileChamCong = (e) => {
    e.preventDefault();
    dispatch(AppAction.appLoading(true))
    const files = e.target.files, f = files[0];
    const reader = new FileReader();
    reader.onload = function (e) {
      let data = e.target.result;
      let readedData = XLSX.read(data, { type: 'binary' });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1, range: 1, raw: false });
      const temp = dataParse.splice(0).map((c) => ({
        username: c[0],
        gioVao: c[4],
        gioRa: c[5],
      }));
      let temp1 = {}
      let index = 0;
      if (temp != null && temp.length > 0) {
        temp.forEach(function (element) {
          temp1[index] = element
          index++;
        })
      }
      temp1 = Object.values(temp1)
      dispatch(TinhluongAction.saveFileChamCong(temp1));
      setPreview(true)
      e.target.value = null;
      alert("Tải file thành công: \"" + files[0].name + "\", bảng lương sẽ tạm ẩn đi một lúc để hệ thống tính toán lại. Sau khi tính toán xong sẽ hiển thị lại bình thường cùng dữ liệu được cập nhật theo file.");
    };
    reader.readAsBinaryString(f)
  }

  const nhapFileDuLieuNhapTay = (e) => {
    e.preventDefault();
    dispatch(AppAction.appLoading(true))
    const files = e.target.files, f = files[0];
    const reader = new FileReader();
    reader.onload = function (e) {
      let data = e.target.result;
      let readedData = XLSX.read(data, { type: 'binary' });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1, range: 1 });
      const temp = dataParse.splice(0).map((c) => ({
        username: c[0],
        luongHocViec: c[1],
        hoaHongDichVu: c[2],
        hoaHongSim: c[3],
        thiDuaThang: c[4],
        thuongKhuyenKhich: c[5],
        thuongLeTet: c[6],
        hoTroBaoHiemXaHoi: c[7],
        nhanKhac: c[8],
        baoHiemXaHoi: c[9],
        cheTaiSaiNoiQuyCongTy: c[10],
        cheTaiSaiNghiepVu: c[11],
        cheTaiKhieuNai: c[12],
        cheTaiKhac: c[13],
        kyQuyTamUng: c[14],
      }));
      let temp1 = {}
      let index = 0;
      if (temp != null && temp.length > 0) {
        temp.forEach(function (element) {
          temp1[index] = element
          index++;
        })
      }
      temp1 = Object.values(temp1)
      dispatch(TinhluongAction.saveFileDuLieuNhapTay(temp1));
      setPreview(true)
      e.target.value = null;
      alert("Tải file thành công: \"" + files[0].name + "\", bảng lương sẽ tạm ẩn đi một lúc để hệ thống tính toán lại. Sau khi tính toán xong sẽ hiển thị lại bình thường cùng dữ liệu được cập nhật theo file.");
    };
    reader.readAsBinaryString(f)
  }

  return (
    <Box {...props}>
      <Box sx={{ mt: 3 }}>
        <Card className="filter_card" style={{overflow: 'visible'}}>
          <CardContent>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box sx={{ 
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={nhapFileChamCong}
                  style={{ height: 56, marginTop: 6, width: 200, marginLeft: 0 }}
                  >
                  Nhập file chấm công
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={nhapFileDuLieuNhapTay}
                  style={{ height: 56, marginTop: 6, width: 200, marginLeft: 0 }}
                  >
                  Nhập file dữ liệu nhập tay
                </Button>
                <div style={{ width: '250px', zIndex: '10' }} className="full-width-select">
                  <Select
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        height: 56,
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      }),
                      option: (provided) => ({
                        ...provided,
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      }),
                    }}
                    options={items}
                    getOptionLabel={(item) => `${item.role.priority == 4 ? '--' : (item.role.priority == 5 ? '----' : '')} ${item.username} - ${item.fullname}`}
                    placeholder={values.username || "Chọn nhân viên xem doanh thu..."}
                    components={{
                      DropdownIndicator: () => <SearchIcon size={16} color="action" />,
                      IndicatorSeparator: null,
                    }}
                    value={values.username || null}
                    onChange={(newValue) => handleUsernameChange1('username', newValue.username)}
                    isSearchable
                  />
                </div>
                <DatePicker
                  label="Chọn tháng tính lương"
                  style={{ width: '100%', marginLeft: 24 }}
                  value={values.startDate}
                  format="MM/YYYY"
                  views={['year', 'month']}
                  onChange={(newValue) => handleStartDateChange(newValue)}
                />
                <Button
                  color="primary"
                  variant="contained"
                  onClick={exportExcel}
                  style={{ height: 56, marginTop: 6, width: 200, marginLeft: 0 }}
                  >
                  Xuất báo cáo
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onLookup}
                  style={{ height: 56, marginTop: 6, width: 200, marginLeft: 0 }}
                  >
                  Tra cứu
                </Button>
              </Box>
            </LocalizationProvider>
          </CardContent>
        </Card>
      </Box>
    </Box>
  )
};

export default TinhluongListToolbar;
