import {
  useEffect,
  useState,
} from 'react';
import {
  Edit3 as Edit3Icon,
  Lock,
  RefreshCcw,
  Trash2 as Trash2Icon,
  Unlock,
} from 'react-feather';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { MoigoihomsauAction } from 'src/actions/moigoihomsau.action';
import {
  CONFIRM_TYPES,
  DEFAULT_ROW_PER_PAGE,
  ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';
import { getMoigoihomsauStatus } from 'src/utils/utils';
import ConfirmDialog from '../ConfirmDialog';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
  Button,
  Select,
  MenuItem,
} from '@material-ui/core';
import CreateEditDialog from './CreateEditDialog';

import numeral from 'numeral';

const MoigoihomsauLookupDetail = ({ ...rest }) => {
  const dispatch = useDispatch()
  const [selectedMoigoihomsauIds, setSelectedMoigoihomsauIds] = useState([]);
  const [limit, setLimit] = useState(DEFAULT_ROW_PER_PAGE);
  const [page, setPage] = useState(0);
  const { role } = useSelector(state => state.profile);
  const { items, total, filter } = useSelector(state => state.moigoihomsau);
  const [confirm, setConfirm] = useState(false);
  const [confirmType, setConfirmType] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [selected, setSelected] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const payload = {
      ...filter,
      limit: limit,
      page: page,
    };
    dispatch(MoigoihomsauAction.updateFilter(payload));
    dispatch(MoigoihomsauAction.list(page, limit))
  }, [page, limit])

  const handleSelectAll = (event) => {
    let newSelectedMoigoihomsauIds;

    if (event.target.checked) {
      newSelectedMoigoihomsauIds = items.map((moigoihomsau) => moigoihomsau.id);
    } else {
      newSelectedMoigoihomsauIds = [];
    }

    setSelectedMoigoihomsauIds(newSelectedMoigoihomsauIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedMoigoihomsauIds.indexOf(id);
    let newSelectedMoigoihomsauIds = [];

    if (selectedIndex === -1) {
      newSelectedMoigoihomsauIds = newSelectedMoigoihomsauIds.concat(selectedMoigoihomsauIds, id);
    } else if (selectedIndex === 0) {
      newSelectedMoigoihomsauIds = newSelectedMoigoihomsauIds.concat(selectedMoigoihomsauIds.slice(1));
    } else if (selectedIndex === selectedMoigoihomsauIds.length - 1) {
      newSelectedMoigoihomsauIds = newSelectedMoigoihomsauIds.concat(selectedMoigoihomsauIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedMoigoihomsauIds = newSelectedMoigoihomsauIds.concat(
        selectedMoigoihomsauIds.slice(0, selectedIndex),
        selectedMoigoihomsauIds.slice(selectedIndex + 1)
      );
    }

    setSelectedMoigoihomsauIds(newSelectedMoigoihomsauIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleConfirmOpen = (type, moigoihomsau) => {
    setSelected(moigoihomsau);
    setConfirmType(type);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      setTitle("Xoá bản ghi")
      setContent(`Xác nhận xoá bản ghi `)
    }
    setConfirm(true);
  };

  const onConfirm = () => {
    setConfirm(false);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      dispatch(MoigoihomsauAction.del(selected.id))
      return;
    }
  }

  const handleConfirmClose = () => {
    setConfirm(false);
  };

  const onSelected = (id) => {
    dispatch(MoigoihomsauAction.findOne(id));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const approve = async (id, trang_thai_duyet) => {
    const question = trang_thai_duyet == 1 ? "Xác nhận duyệt đơn này?" : "Xác nhận từ chối đơn này?" ;
    if (window.confirm(question)) {
      try {
        const response = await dispatch(MoigoihomsauAction.approve(id, { trang_thai_duyet }));
        if (typeof response.channel !== 'undefined') alert("[Thông báo từ '"+response.channel+"']\n"+response.message+(response.status != "Unknown" ? (" ("+response.status+")") : ""));
        else if (typeof response.code !== 'undefined' && typeof response.msg !== 'undefined') alert("[Lỗi]\n"+response.msg)
      } catch (error) {
        if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
      }
    } else {
      
    }
  };

  const setTrangThai = async (id, trang_thai) => {
    const question = trang_thai == 'SUCCESS' ? "Xác nhận đặt trạng thái 'Thành công'?" : "Xác nhận đặt trạng thái 'Thất bại'?" ;
    if (window.confirm(question)) {
      try {
        const response = await dispatch(MoigoihomsauAction.setTrangThai(id, { trang_thai }));
        if (typeof response.channel !== 'undefined') alert("[Thông báo từ '"+response.channel+"']\n"+response.message+(response.status != "Unknown" ? (" ("+response.status+")") : ""));
        else if (typeof response.code !== 'undefined' && typeof response.msg !== 'undefined') alert("[Lỗi]\n"+response.msg)
      } catch (error) {
        if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
      }
    } else {
      
    }
  };

  return (
    <Card {...rest} style={{ width: "100%"}}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  #
                </TableCell>
                <TableCell>
                  Số điện thoại
                </TableCell>
                <TableCell>
                  Gói cước
                </TableCell>
                <TableCell>
                  TKC / Giá gói
                </TableCell>
                <TableCell>
                  Nhân viên
                </TableCell>
                <TableCell>
                  Trạng thái
                </TableCell>
                <TableCell>
                  Ngày tạo
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.slice(page * limit, page * limit + limit).map((moigoihomsau, index) => (
                <TableRow
                  hover
                  key={moigoihomsau.id}
                  selected={selectedMoigoihomsauIds.indexOf(moigoihomsau.id) !== -1}
                >
                  <TableCell>
                    {page * limit + index + 1}
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {moigoihomsau.phone}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {moigoihomsau.package}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color={moigoihomsau.tkc_chia_phan_tram_gia_goi < 50 ? 'red' : 'green'}
                      variant="body1"
                    >
                      {numeral(moigoihomsau.tkc).format('0,0')} / {numeral(moigoihomsau.gia_goi).format('0,0')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {moigoihomsau.username} - {moigoihomsau.ten_nv}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color={moigoihomsau.status == 1 ? 'green' : 'red'}
                      variant="body1"
                    >
                      {moigoihomsau.status == 1 ? `ĐÃ MỜI ${numeral(moigoihomsau.so_lan_moi).format('0,0')} LẦN` : 'CHƯA MỜI'}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {moment(moigoihomsau.createdAt).format('DD-MM-YYYY HH:mm:ss')}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      />
      <CreateEditDialog open={open} handleClose={handleClose} type={"EDIT"} />
      <ConfirmDialog open={confirm} title={title} content={content || `Xác nhận xoá bản ghi `} highlight={selected.ten_goi_cuoc} handleClose={handleConfirmClose} handleConfirm={onConfirm} />
    </Card>
  );
};

export default MoigoihomsauLookupDetail;
