import { Customermessage9084Service } from 'src/services/customermessage9084.service';
import { showToast } from 'src/utils/common';
import {
    DEFAULT_ROW_PER_PAGE,
  } from 'src/utils/constants';

import { AppAction } from './app.action';

export const CUSTOMERMESSAGE9084_UPDATE_FILTER = 'CUSTOMERMESSAGE9084:CUSTOMERMESSAGE9084_UPDATE_FILTER'

export const CUSTOMERMESSAGE9084_NEW_CARE_SUCCESS = 'CUSTOMERMESSAGE9084:NEW_CARE_SUCCESS'
export const CUSTOMERMESSAGE9084_LIST_SUCCESS = 'CUSTOMERMESSAGE9084:FIND_ALL_SUCCESS'
export const CUSTOMERMESSAGE9084_LIST_FAILD = 'CUSTOMERMESSAGE9084:FIND_ALL_FAILD'
export const CUSTOMERMESSAGE9084_COUNT_ASSIGN_SUCCESS = 'CUSTOMERMESSAGE9084:COUNT_ASSIGN_SUCCESS'
export const CUSTOMERMESSAGE9084_COUNT_ASSIGN_FAILD = 'CUSTOMERMESSAGE9084:COUNT_ASSIGN_FAILD'

export const CUSTOMERMESSAGE9084_CREATE_SUCCESS = 'CUSTOMERMESSAGE9084:CREATE_SUCCESS'
export const CUSTOMERMESSAGE9084_CREATE_FAILD = 'CUSTOMERMESSAGE9084:CREATE_FAILD'

export const CUSTOMERMESSAGE9084_IMPORT_SUCCESS = 'CUSTOMERMESSAGE9084:IMPORT_SUCCESS'
export const CUSTOMERMESSAGE9084_SAVE_SUCCESS = 'CUSTOMERMESSAGE9084:SAVE_SUCCESS'
export const CUSTOMERMESSAGE9084_SAVE_FAILD = 'CUSTOMERMESSAGE9084:SAVE_SUCCESS'

export const CUSTOMERMESSAGE9084_DEL_SUCCESS = 'CUSTOMERMESSAGE9084:DEL_SUCCESS'
export const CUSTOMERMESSAGE9084_DEL_FAILD = 'CUSTOMERMESSAGE9084:DEL_FAILD'


export const CUSTOMERMESSAGE9084_HISTORY_LIST_SUCCESS = 'CUSTOMERMESSAGE9084:HISTORY_FIND_ALL_SUCCESS'
export const CUSTOMERMESSAGE9084_HISTORY_LIST_FAILD = 'CUSTOMERMESSAGE9084:HISTORY_FIND_ALL_FAILD'

export const Customermessage9084Action = {
    list,
    findAssign,
    create,
    updateFilter,
    importData,
    saveData,
    update,
    del,
    assign,
    countAssign,
    history,
}


function countAssign() {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        Customermessage9084Service.countAssign().then((rs) => {
            dispatch(success(rs.data.total));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: CUSTOMERMESSAGE9084_COUNT_ASSIGN_SUCCESS, data } }
    function failure(error) { return { type: CUSTOMERMESSAGE9084_COUNT_ASSIGN_FAILD, error } }
}

function findAssign(page = 0, count = 20) {
    return (dispatch, getState) => {
        const { customermessage9084 } = getState()
        dispatch(AppAction.appLoading(true));
        Customermessage9084Service.findAssign(page, count).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...customermessage9084.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: CUSTOMERMESSAGE9084_LIST_SUCCESS, data } }
    function failure(error) { return { type: CUSTOMERMESSAGE9084_LIST_FAILD, error } }
}

function list() {
    return (dispatch, getState) => {
        const { customermessage9084 } = getState();
        dispatch(AppAction.appLoading(true));

        const { filter } = customermessage9084;

        Customermessage9084Service.findAll(filter.page, filter.limit, filter).then((rs) => {
            if (!!filter && filter.page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...customermessage9084.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: CUSTOMERMESSAGE9084_LIST_SUCCESS, data } }
    function failure(error) { return { type: CUSTOMERMESSAGE9084_LIST_FAILD, error } }
}

function updateFilter(payload) {
    return { type: CUSTOMERMESSAGE9084_UPDATE_FILTER, data: payload }
}

function importData(payload) {
    return { type: CUSTOMERMESSAGE9084_IMPORT_SUCCESS, data: payload }
}

function saveData(payload) {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        let count = 0
        for (let i = 0; i < payload.length; i += 200) {
            // eslint-disable-next-line no-loop-func
            Customermessage9084Service.save(payload.slice(i, i + 200)).then((rs) => {
                count += parseInt(rs.data.saved, 10)
                if (count === payload.length) {
                    dispatch(success(rs));
                    dispatch(Customermessage9084Action.list())
                    dispatch(AppAction.appLoading(false));
                }
            }).catch((error) => {
                dispatch(failure(error));
                dispatch(AppAction.appLoading(false));
            })
        }
    };

    function success(data) { return { type: CUSTOMERMESSAGE9084_SAVE_SUCCESS, data } }
    function failure(error) { return { type: CUSTOMERMESSAGE9084_SAVE_FAILD, error } }
}

function update(payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        Customermessage9084Service.create(payload).then((rs) => {
            dispatch(success(rs));
            dispatch(Customermessage9084Action.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: CUSTOMERMESSAGE9084_CREATE_SUCCESS, data } }
    function failure(error) { return { type: CUSTOMERMESSAGE9084_CREATE_FAILD, error } }
}


function create(payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        Customermessage9084Service.create(payload).then((rs) => {
            dispatch(success(rs));
            dispatch(Customermessage9084Action.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: CUSTOMERMESSAGE9084_CREATE_SUCCESS, data } }
    function failure(error) { return { type: CUSTOMERMESSAGE9084_CREATE_FAILD, error } }
}

function del(id) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        Customermessage9084Service.delete(id).then((rs) => {
            dispatch(success(rs));
            dispatch(Customermessage9084Action.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: CUSTOMERMESSAGE9084_DEL_SUCCESS, data } }
    function failure(error) { return { type: CUSTOMERMESSAGE9084_DEL_FAILD, error } }
}


function assign(payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        Customermessage9084Service.assign(payload).then((rs) => {
            showToast('success', "Phân chia dữ liệu thành công.")
            dispatch(Customermessage9084Action.list())
        }).catch((error) => {
            showToast('error', "Phân chia dữ liệu thất bại.\nVui lòng thử lại lần sau.")
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
}


function history(payload) {
    return (dispatch, getState) => {
        const { customermessage9084 } = getState()
        dispatch(AppAction.appLoading(true));
        Customermessage9084Service.history(payload).then((rs) => {
            if (payload.page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...customermessage9084.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: CUSTOMERMESSAGE9084_HISTORY_LIST_SUCCESS, data } }
    function failure(error) { return { type: CUSTOMERMESSAGE9084_HISTORY_LIST_FAILD, error } }
}