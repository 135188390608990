import { BankService } from 'src/services/bank.service';
import { showToast } from 'src/utils/common';
import {
    DEFAULT_ROW_PER_PAGE,
  } from 'src/utils/constants';

import { AppAction } from './app.action';

export const BANK_UPDATE_FILTER = 'BANK:BANK_UPDATE_FILTER'

export const BANK_NEW_CARE_SUCCESS = 'BANK:NEW_CARE_SUCCESS'
export const BANK_LIST_SUCCESS = 'BANK:FIND_ALL_SUCCESS'
export const BANK_LIST_FAILD = 'BANK:FIND_ALL_FAILD'
export const BANK_COUNT_ASSIGN_SUCCESS = 'BANK:COUNT_ASSIGN_SUCCESS'
export const BANK_COUNT_ASSIGN_FAILD = 'BANK:COUNT_ASSIGN_FAILD'

export const BANK_CREATE_SUCCESS = 'BANK:CREATE_SUCCESS'
export const BANK_CREATE_FAILD = 'BANK:CREATE_FAILD'

export const BANK_IMPORT_SUCCESS = 'BANK:IMPORT_SUCCESS'
export const BANK_SAVE_SUCCESS = 'BANK:SAVE_SUCCESS'
export const BANK_SAVE_FAILD = 'BANK:SAVE_SUCCESS'

export const BANK_DEL_SUCCESS = 'BANK:DEL_SUCCESS'
export const BANK_DEL_FAILD = 'BANK:DEL_FAILD'


export const BANK_HISTORY_LIST_SUCCESS = 'BANK:HISTORY_FIND_ALL_SUCCESS'
export const BANK_HISTORY_LIST_FAILD = 'BANK:HISTORY_FIND_ALL_FAILD'

export const BankAction = {
    list,
    findAssign,
    create,
    updateFilter,
    importData,
    saveData,
    update,
    del,
    assign,
    countAssign,
    history,
    approve,
    deny,
    setTrangThaiXuLy,
}

function countAssign() {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        BankService.countAssign().then((rs) => {
            dispatch(success(rs.data.total));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: BANK_COUNT_ASSIGN_SUCCESS, data } }
    function failure(error) { return { type: BANK_COUNT_ASSIGN_FAILD, error } }
}

function findAssign(page = 0, count = 20) {
    return (dispatch, getState) => {
        const { bank } = getState()
        dispatch(AppAction.appLoading(true));
        BankService.findAssign(page, count).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count,
                }));
            } else {
                dispatch(success({
                    items: [...bank.items, ...rs.data.values],
                    total: rs.data.count,
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: BANK_LIST_SUCCESS, data } }
    function failure(error) { return { type: BANK_LIST_FAILD, error } }
}

function list() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch(AppAction.appLoading(true));
            const { bank } = getState();
            const page = bank && typeof bank.filter !== 'undefined' && bank.filter && typeof bank.filter.page !== 'undefined' ? bank.filter.page : 0;
            const limit = bank && typeof bank.filter !== 'undefined' && bank.filter && typeof bank.filter.limit !== 'undefined' ? bank.filter.limit : 10;
            BankService.findAll(page, limit, bank.filter).then((rs) => {
                if (page === 0) {
                    dispatch(success({
                        items: rs.data.values,
                        total: rs.data.count,
                        tongChuyenKhoan: rs.data.tongChuyenKhoan,
                        tongDonChuyen: rs.data.tongDonChuyen,
                    }));
                    resolve(rs);
                } else {
                    const oldItems = bank.items;
                    const rsDataValues = rs.data.values;

                    var uniqueItems = oldItems;
                    for (var i = limit * page; i < limit * page + limit; i++) {
                        if (typeof rsDataValues[i - limit * page] !== 'undefined') {
                            uniqueItems[i] = rsDataValues[i - limit * page];
                        } else if (typeof uniqueItems[i] !== 'undefined') {
                            delete uniqueItems[i];
                        }
                    }
                    
                    dispatch(success({
                        items: uniqueItems,
                        total: rs.data.count,
                        tongChuyenKhoan: rs.data.tongChuyenKhoan,
                        tongDonChuyen: rs.data.tongDonChuyen,
                    }));
                    resolve(rs);
                }
            }).catch((error) => {
                dispatch(failure(error));
                reject(error);
            }).finally(() => {
                dispatch(AppAction.appLoading(false));
            })
        });
    };
    function success(data) { return { type: BANK_LIST_SUCCESS, data } }
    function failure(error) { return { type: BANK_LIST_FAILD, error } }
}

function updateFilter(payload) {
    return { type: BANK_UPDATE_FILTER, data: payload }
}

function importData(payload) {
    return { type: BANK_IMPORT_SUCCESS, data: payload }
}

function saveData(payload) {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        let count = 0
        for (let i = 0; i < payload.length; i += 200) {
            // eslint-disable-next-line no-loop-func
            BankService.save(payload.slice(i, i + 200)).then((rs) => {
                count += parseInt(rs.data.saved, 10)
                if (count === payload.length) {
                    dispatch(success(rs));
                    dispatch(BankAction.list())
                    dispatch(AppAction.appLoading(false));
                }
            }).catch((error) => {
                dispatch(failure(error));
                dispatch(AppAction.appLoading(false));
            })
        }
    };

    function success(data) { return { type: BANK_SAVE_SUCCESS, data } }
    function failure(error) { return { type: BANK_SAVE_FAILD, error } }
}

function setTrangThaiXuLy(id, payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            BankService.setTrangThaiXuLy(id, payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(BankAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: BANK_CREATE_SUCCESS, data } }
    function failure(error) { return { type: BANK_CREATE_FAILD, error } }
}

function approve(id) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            BankService.approve(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(BankAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: BANK_CREATE_SUCCESS, data } }
    function failure(error) { return { type: BANK_CREATE_FAILD, error } }
}

function deny(id) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            BankService.deny(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(BankAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: BANK_CREATE_SUCCESS, data } }
    function failure(error) { return { type: BANK_CREATE_FAILD, error } }
}

function update(id, payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        BankService.update(id, payload).then((rs) => {
            dispatch(success(rs));
            dispatch(BankAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: BANK_CREATE_SUCCESS, data } }
    function failure(error) { return { type: BANK_CREATE_FAILD, error } }
}


function create(payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        BankService.create(payload).then((rs) => {
            dispatch(success(rs));
            dispatch(BankAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: BANK_CREATE_SUCCESS, data } }
    function failure(error) { return { type: BANK_CREATE_FAILD, error } }
}

function del(id) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        BankService.delete(id).then((rs) => {
            dispatch(success(rs));
            dispatch(BankAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: BANK_DEL_SUCCESS, data } }
    function failure(error) { return { type: BANK_DEL_FAILD, error } }
}


function assign(payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        BankService.assign(payload).then((rs) => {
            showToast('success', "Phân chia dữ liệu thành công.")
            dispatch(BankAction.list())
        }).catch((error) => {
            showToast('error', "Phân chia dữ liệu thất bại.\nVui lòng thử lại lần sau.")
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
}


function history(payload) {
    return (dispatch, getState) => {
        const { bank } = getState()
        dispatch(AppAction.appLoading(true));
        BankService.history(payload).then((rs) => {
            if (payload.page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count,
                }));
            } else {
                dispatch(success({
                    items: [...bank.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: BANK_HISTORY_LIST_SUCCESS, data } }
    function failure(error) { return { type: BANK_HISTORY_LIST_FAILD, error } }
}