import {
    useEffect,
    useState,
} from 'react';

import {
    useDispatch,
    useSelector,
} from 'react-redux';
import { PermissionAction } from 'src/actions/permission.acction';
import { BaodoAction } from 'src/actions/baodo.action';

import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    TextField,
    Typography,
    Grid,
    Box,
    Select as Select1,
    MenuItem,
} from '@material-ui/core';

import {
    DatePicker,
    LocalizationProvider,
  } from '@mui/x-date-pickers';
  import {
    DEFAULT_ROW_PER_PAGE,
  } from 'src/utils/constants';
  import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';

const CreateEditDialog = (props) => {
    const dispatch = useDispatch()
    const [selectedPermissionIds, setSelectedPermissionIds] = useState([]);
    const { role } = useSelector(state => state.profile);
    const { items } = useSelector(state => state.permission)
    const { selected } = useSelector(state => state.baodo)
    const [so_dien_thoai, setSoDienThoai] = useState("")
    const [ngay_thanh_cong, setNgayThanhCong] = useState("")
    const [username_nv, setUsernamenv] = useState("")
    const [ly_do, setLyDo] = useState("")
    const [ten_goi_cuoc, setTenGoiCuoc] = useState("")
    const [kenh, setKenh] = useState("")

    useEffect(() => {
        if (items.length > 0) {
            return;
        }
        dispatch(PermissionAction.list(0, 100))
    }, [])

    useEffect(() => {
        if (props.type === 'EDIT') {
            if (selected) {
                setSoDienThoai(selected.so_dien_thoai)
                setNgayThanhCong(selected.ngay_thanh_cong)
                setUsernamenv(selected.username_nv)
                setLyDo(selected.ly_do)
                setTenGoiCuoc(selected.ten_goi_cuoc)
                setKenh(selected.kenh)
            }
        }
    }, [selected])

    const onPressPositive = async () => {
        if (so_dien_thoai === "") {
            return;
        }
        if (ngay_thanh_cong === "") {
            return;
        }
        if (role.priority < 5 && username_nv === "") {
            return;
        }
        if (ten_goi_cuoc === "") {
            return;
        }
        if (kenh === "") {
            return;
        }
        if (props.type === 'EDIT') {
            dispatch(BaodoAction.update(selected.id, { so_dien_thoai, ngay_thanh_cong, username_nv, ly_do, ten_goi_cuoc, kenh }))
            resetState();
            props.handleClose();
        } else {
            try {
                const response = await dispatch(BaodoAction.create({ so_dien_thoai, ngay_thanh_cong, username_nv, ly_do, ten_goi_cuoc, kenh }))
                resetState();
                props.handleClose();
            } catch (error) {
                if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
            }
        }
        
    }

    const resetState = () => {
        setSoDienThoai("")
        setNgayThanhCong("")
        setUsernamenv("")
        setLyDo("")
        setTenGoiCuoc("")
        setKenh("")
    }

    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedPermissionIds.indexOf(id);
        let newSelectedCustomerIds = [];
        if (selectedIndex === -1) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds, id);
        } else if (selectedIndex === 0) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds.slice(1));
        } else if (selectedIndex === selectedPermissionIds.length - 1) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(
                selectedPermissionIds.slice(0, selectedIndex),
                selectedPermissionIds.slice(selectedIndex + 1)
            );
        }
        setSelectedPermissionIds(newSelectedCustomerIds);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Dialog open={props.open} onClose={props.handleClose}>
                <DialogTitle>{props.type === 'EDIT' ? "Cập nhật bản ghi" : "Tạo bản ghi mới"}</DialogTitle>
                <DialogContent style={{ width: 600, paddingTop: 20 }}>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Số điện thoại"
                                name="so_dien_thoai"
                                onChange={(e) => setSoDienThoai(e.target.value)}
                                required
                                value={so_dien_thoai}
                                variant="outlined"
                            />
                        </Grid>
                        {role.priority < 5 && <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Nhân viên"
                                name="username_nv"
                                onChange={(e) => setUsernamenv(e.target.value)}
                                required
                                value={username_nv}
                                variant="outlined"
                            />
                        </Grid>}
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Tên gói cước"
                                name="ten_goi_cuoc"
                                onChange={(e) => setTenGoiCuoc(e.target.value)}
                                required
                                value={ten_goi_cuoc}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <Select1 fullWidth variant="outlined" name="kenh" value={kenh || -99} onChange={(e) => setKenh(e.target.value)}>
                                <MenuItem value="-99" selected>Chọn kênh...</MenuItem>
                                {1 < 0 && <><MenuItem value="9084 (1)">9084 (1)</MenuItem>
                                <MenuItem value="9084 (2)">9084 (2)</MenuItem></>}
                                <MenuItem value="9084 (3)">9084 (3)</MenuItem>
                                <MenuItem value="9084 (4)">9084 (4)</MenuItem>
                                {1 < 0 && <><MenuItem value="9332">9332</MenuItem>
                                <MenuItem value="9084 (CU_PHAP)">9084 (CU_PHAP)</MenuItem>
                                <MenuItem value="9084 (CU_PHAP_2)">9084 (CU_PHAP_2)</MenuItem></>}
                                <MenuItem value="9084 (CU_PHAP_3)">9084 (CU_PHAP_3)</MenuItem>
                            </Select1>
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Lý do"
                                name="ly_do"
                                onChange={(e) => setLyDo(e.target.value)}
                                value={ly_do}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <DatePicker
                                fullWidth
                                label="Ngày thành công"
                                name="ngay_thanh_cong"
                                format='DD/MM/YYYY'
                                value={ngay_thanh_cong}
                                onChange={(date) => setNgayThanhCong(date)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose}>Huỷ</Button>
                    <Button onClick={onPressPositive}>{props.type === 'EDIT' ? "Cập nhật" : "Tạo"}</Button>
                </DialogActions>
            </Dialog >
        </LocalizationProvider>
    );
}

export default CreateEditDialog;