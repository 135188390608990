import { Helmet } from 'react-helmet';
import AccountListResults from 'src/components/accounts/AccountListResults';
import AccountListToolbar from 'src/components/accounts/AccountListToolbar';

import {
  Box,
  Container,
} from '@material-ui/core';

const AccountList = () => (
  <>
    <Helmet>
      <title>Accounts</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
        <AccountListToolbar />
        <Box sx={{ pt: 3 }}>
          <AccountListResults />
        </Box>
      </Container>
    </Box>
  </>
);

export default AccountList;
