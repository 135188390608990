import {
    useEffect,
    useState,
} from 'react';

import {
    useDispatch,
    useSelector,
} from 'react-redux';
import { PermissionAction } from 'src/actions/permission.acction';
import { BlacklistAction } from 'src/actions/blacklist.action';

import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    TextField,
    Typography,
    Grid,
} from '@material-ui/core';

const CreateEditDialog = (props) => {
    const dispatch = useDispatch()
    const [selectedPermissionIds, setSelectedPermissionIds] = useState([]);
    const { items } = useSelector(state => state.permission)
    const { selected } = useSelector(state => state.blacklist)
    const [phone_number, setPhoneNumber] = useState("")
    const [description, setDescription] = useState("")

    useEffect(() => {
        if (items.length > 0) {
            return;
        }
    }, [])

    useEffect(() => {
        if (props.type === 'EDIT') {
            if (selected) {
                setPhoneNumber(selected.phone_number)
                setDescription(selected.description)
            }
        }
    }, [selected])

    const onPressPositive = () => {
        if (phone_number === "") {
            return;
        }
        if (props.type === 'EDIT') {
            dispatch(BlacklistAction.update(selected.id, { phone_number, description }))
        } else {
            dispatch(BlacklistAction.create({ phone_number, description }))
        }
        resetState();
        props.handleClose();
    }

    const resetState = () => {
        setPhoneNumber("")
        setDescription("")
    }

    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedPermissionIds.indexOf(id);
        let newSelectedCustomerIds = [];
        if (selectedIndex === -1) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds, id);
        } else if (selectedIndex === 0) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds.slice(1));
        } else if (selectedIndex === selectedPermissionIds.length - 1) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(
                selectedPermissionIds.slice(0, selectedIndex),
                selectedPermissionIds.slice(selectedIndex + 1)
            );
        }
        setSelectedPermissionIds(newSelectedCustomerIds);
    };

    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>{props.type === 'EDIT' ? "Cập nhật bản ghi" : "Tạo bản ghi mới"}</DialogTitle>
            <DialogContent style={{ width: 600, paddingTop: 20 }}>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Số điện thoại"
                            name="phone_number"
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            required
                            value={phone_number}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Mô tả"
                            name="description"
                            onChange={(e) => setDescription(e.target.value)}
                            value={description}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Huỷ</Button>
                <Button onClick={onPressPositive}>{props.type === 'EDIT' ? "Cập nhật" : "Tạo"}</Button>
            </DialogActions>
        </Dialog >
    );
}

export default CreateEditDialog;