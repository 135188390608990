import { useState } from 'react';

import { Search as SearchIcon } from 'react-feather';

import {
  Box,
  Button,
  Card,
  CardContent,
  InputAdornment,
  SvgIcon,
  TextField,
} from '@material-ui/core';

import CreateRoleDialog from './ApprovalConfirmDialog';

const ApprovalListToolbar = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={handleClickOpen}
        >
          Tạo vai trò
        </Button>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Box sx={{ maxWidth: 500 }}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Tìm kiếm vai trò"
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
      <CreateRoleDialog open={open} handleClose={handleClose} type={"CREATE"} />
    </Box>
  )
}

export default ApprovalListToolbar;
