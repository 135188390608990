import {
  UPLOAD_CREATE_SUCCESS,
  UPLOAD_DATA_CREATED,
  UPLOAD_FIND_ALL_SUCCESS,
  UPLOAD_TEMP_DATA,
} from 'src/actions/upload.acction';

const initState = {
  items: [],
  total: 0,
  created: undefined,
  temp_create: undefined,
}

const uploadReducer = (state = initState, action) => {
  switch (action.type) {
    case UPLOAD_FIND_ALL_SUCCESS: {
      return {
        ...state,
        items: action.data.items,
        total: action.data.total,
      }
    }
    case UPLOAD_TEMP_DATA: {
      return {
        ...state,
        temp_create: action.data
      }
    }
    case UPLOAD_CREATE_SUCCESS: {
      return {
        ...state,
        created: action.data,
        temp_create: undefined
      }
    }
    case UPLOAD_DATA_CREATED: {
      return {
        ...state,
        created: undefined,
      }
    }
    default: {
      return state;
    }
  }
}
export default uploadReducer