import { handleResponse } from '../utils/auth-header';
import axios, { axiosOption } from '../utils/axios';

export const AccountService = {

  saveProfile: (payload) => {
    return axios.post('/api/v1/auth/saveProfile', payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  findByUsername: (username) => {
    return axios.get(`/api/v1/users/findByUsername/${username}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  create: (payload) => {
    return axios.post('/api/v1/users', payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  resetPassword: (id, payload) => {
    return axios.post(`/api/v1/users/${id}/reset-password`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  lock: (id) => {
    return axios.post(`/api/v1/users/${id}/lock`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  unlock: (id) => {
    return axios.post(`/api/v1/users/${id}/unlock`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },


  update: (id, payload) => {
    return axios.put(`/api/v1/users/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  findAll: (page, count, payload) => {
    let url = `/api/v1/users?page=${page}&count=${count}`;

    if (payload.username) {
      url += `&username=${payload.username}`
    };
    
    return axios.get(url, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  findAllNoLimit: (page, count) => {
    return axios.get(`/api/v1/users/findAllNoLimit?page=${page}&count=${count}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  findAllBranchNoLimit: () => {
    return axios.get(`/api/v1/users/findAllBranchNoLimit`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  findAllLeaderNoLimit: () => {
    return axios.get(`/api/v1/users/findAllLeaderNoLimit`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  findAllSaleNoLimit: () => {
    return axios.get(`/api/v1/users/findAllSaleNoLimit`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  find: (id) => {
    return axios.get(`/api/v1/users/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  delete: (id) => {
    return axios.delete(`/api/v1/users/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  chonKenh: (payload) => {
    return axios.post(`/api/v1/userkenhmoigoi/chonKenh`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  huyChonKenh: (payload) => {
    return axios.post(`/api/v1/userkenhmoigoi/huyChonKenh`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  getdskenhmoigoi: () => {
    return axios.get(`/api/v1/userkenhmoigoi`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  findChannel: (payload) => {
    return axios.post(`/api/v1/userkenhmoigoi/findChannel`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },
}