import {
  useEffect,
  useState,
} from 'react';
import {
  Edit3 as Edit3Icon,
  Lock,
  RefreshCcw,
  Trash2 as Trash2Icon,
  Unlock,
  Search as SearchIcon,
} from 'react-feather';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { UploadchiadataAction } from 'src/actions/uploadchiadata.action';
import { AccountAction } from 'src/actions/account.acction';
import {
  CONFIRM_TYPES,
  DEFAULT_ROW_PER_PAGE,
  ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';
import { getUploadchiadataStatus } from 'src/utils/utils';
import ConfirmDialog from '../ConfirmDialog';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
  Button,
  FormGroup,
  FormControlLabel,
  Select,
  MenuItem,
  InputAdornment,
  SvgIcon,
  Grid,
} from '@material-ui/core';
import { AppAction } from 'src/actions/app.action';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { element } from 'prop-types';

const PhanquyendataDetail = ({ ...rest }) => {
  const dispatch = useDispatch()
  const [selectedUploadchiadataIds, setSelectedUploadchiadataIds] = useState([]);
  const [limit, setLimit] = useState(DEFAULT_ROW_PER_PAGE);
  const [page, setPage] = useState(0);
  const { items, total } = useSelector(state => state.uploadchiadata);
  const { role, info } = useSelector(state => state.profile);
  const { branch_no_limit_items, branch_no_limit_total } = useSelector(state => state.account);
  const { leader_no_limit_items, leader_no_limit_total } = useSelector(state => state.account);
  const { sale_no_limit_items, sale_no_limit_total } = useSelector(state => state.account);
  // const { option_data_branch } = useSelector(state => state.uploadchiadata);
  // const { option_data_leader } = useSelector(state => state.uploadchiadata);
  // const { option_data_sale } = useSelector(state => state.uploadchiadata);
  const [confirm, setConfirm] = useState(false);
  const [confirmType, setConfirmType] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [selected, setSelected] = useState({});
  const [open, setOpen] = useState(false);
  const { accessToken, expiresAt } = useSelector(state => state.auth)
  const [listDate, setListDate] = useState([]);
  const [branchFileCheckboxes, setBranchFileCheckboxes] = useState({});
  const [leaderFileCheckboxes, setLeaderFileCheckboxes] = useState({});
  const [saleFileCheckboxes, setSaleFileCheckboxes] = useState({});
  const [branchDateCheckboxes, setBranchDateCheckboxes] = useState({});
  const [leaderDateCheckboxes, setLeaderDateCheckboxes] = useState({});
  const [saleDateCheckboxes, setSaleDateCheckboxes] = useState({});
  const [branchUser, setBranch] = useState([]);
  const [leaderUser, setLeader] = useState([]);
  const [saleUser, setSale] = useState([]);

  const handleBranchChangeSelectFile = (event) => {
    if (event.target.name == "Tất cả") {
      if (event.target.checked) {
        let newOptionFile = {"Tất cả": true}
        Object.entries(items).forEach(([index, item]) => {
          newOptionFile[item.FileName] = true
        })
        setBranchFileCheckboxes(newOptionFile);
      } else {
        let newOptionFile = {"Tất cả": false}
        Object.entries(items).forEach(([index, item]) => {
          newOptionFile[item.FileName] = false
        })
        setBranchFileCheckboxes(newOptionFile);
      }
    } else {
      setBranchFileCheckboxes({
        ...branchFileCheckboxes,
        [event.target.name]: event.target.checked,
        "Tất cả": Object.entries(items).length - 1 == Object.values(branchFileCheckboxes).filter(value => value === true).length ? true : false,
      });
    }
  };

  const handleLeaderChangeSelectFile = (event) => {
    if (event.target.name == "Tất cả") {
      if (event.target.checked) {
        let newOptionFile = {"Tất cả": true}
        Object.entries(items).forEach(([index, item]) => {
          newOptionFile[item.FileName] = true
        })
        setLeaderFileCheckboxes(newOptionFile);
      } else {
        let newOptionFile = {"Tất cả": false}
        Object.entries(items).forEach(([index, item]) => {
          newOptionFile[item.FileName] = false
        })
        setLeaderFileCheckboxes(newOptionFile);
      }
    } else {
      setLeaderFileCheckboxes({
        ...leaderFileCheckboxes,
        [event.target.name]: event.target.checked,
        "Tất cả": Object.entries(items).length - 1 == Object.values(leaderFileCheckboxes).filter(value => value === true).length ? true : false,
      });
    }
  };

  const handleSaleChangeSelectFile = (event) => {
    if (event.target.name == "Tất cả") {
      if (event.target.checked) {
        let newOptionFile = {"Tất cả": true}
        Object.entries(items).forEach(([index, item]) => {
          newOptionFile[item.FileName] = true
        })
        setSaleFileCheckboxes(newOptionFile);
      } else {
        let newOptionFile = {"Tất cả": false}
        Object.entries(items).forEach(([index, item]) => {
          newOptionFile[item.FileName] = false
        })
        setSaleFileCheckboxes(newOptionFile);
      }
    } else {
      setSaleFileCheckboxes({
        ...saleFileCheckboxes,
        [event.target.name]: event.target.checked,
        "Tất cả": Object.entries(items).length - 1 == Object.values(saleFileCheckboxes).filter(value => value === true).length ? true : false,
      });
    }
  };

  const handleBranchChangeSelectDate = (event) => {
    if (event.target.name == "Tất cả") {
      if (event.target.checked) {
        let newOptionDate = {"Tất cả": true}
        listDate.forEach((item) => {
          newOptionDate[item] = true
        })
        setBranchDateCheckboxes(newOptionDate);
      } else {
        let newOptionDate = {"Tất cả": false}
        listDate.forEach((item) => {
          newOptionDate[item] = false
        })
        setBranchDateCheckboxes(newOptionDate);
      }
    } else {
      setBranchDateCheckboxes({
        ...branchDateCheckboxes,
        [event.target.name]: event.target.checked,
        "Tất cả": listDate.length - 1 == Object.values(branchDateCheckboxes).filter(value => value === true).length ? true : false,
      });
    }
  };

  const handleLeaderChangeSelectDate = (event) => {
    if (event.target.name == "Tất cả") {
      if (event.target.checked) {
        let newOptionDate = {"Tất cả": true}
        listDate.forEach((item) => {
          newOptionDate[item] = true
        })
        setLeaderDateCheckboxes(newOptionDate);
      } else {
        let newOptionDate = {"Tất cả": false}
        listDate.forEach((item) => {
          newOptionDate[item] = false
        })
        setLeaderDateCheckboxes(newOptionDate);
      }
    } else {
      setLeaderDateCheckboxes({
        ...leaderDateCheckboxes,
        [event.target.name]: event.target.checked,
        "Tất cả": listDate.length - 1 == Object.values(leaderDateCheckboxes).filter(value => value === true).length ? true : false,
      });
    }
  };

  const handleSaleChangeSelectDate = (event) => {
    if (event.target.name == "Tất cả") {
      if (event.target.checked) {
        let newOptionDate = {"Tất cả": true}
        listDate.forEach((item) => {
          newOptionDate[item] = true
        })
        setSaleDateCheckboxes(newOptionDate);
      } else {
        let newOptionDate = {"Tất cả": false}
        listDate.forEach((item) => {
          newOptionDate[item] = false
        })
        setSaleDateCheckboxes(newOptionDate);
      }
    } else {
      setSaleDateCheckboxes({
        ...saleDateCheckboxes,
        [event.target.name]: event.target.checked,
        "Tất cả": listDate.length - 1 == Object.values(saleDateCheckboxes).filter(value => value === true).length ? true : false,
      });
    }
  };

  const handleUsernameChange = (event) => {
    if (event.target.name == 'branch') {
      dispatch(AppAction.appLoading(true))

      setBranch({
        username: event.target.value
      });

      // dispatch(UploadchiadataAction.getOptionDataByUsernameBranch(event.target.value))
      // if (option_data_branch) {
      //   console.log(option_data_branch);
      // }

      setBranchFileCheckboxes({});
      setBranchDateCheckboxes({});

      const fetchDataBranchOption = async () => {
        const option_data_branch = await dispatch(UploadchiadataAction.getOptionDataByUsernameBranch(event.target.value));
        if (option_data_branch) {
          //điền option file có sẵn
          if (option_data_branch.file) {
            var newOptionFile = {}
            option_data_branch.file.forEach((item, index) => {
              newOptionFile[item.FileName] = true
            });
            if (Object.entries(items).length <= Object.keys(newOptionFile).length) newOptionFile["Tất cả"] = true;
            setBranchFileCheckboxes(newOptionFile);
          }
          //điền option ngày có sẵn
          if (option_data_branch.date) {
            var newOptionDate = {}
            option_data_branch.date.forEach((item, index) => {
              if (moment(item.ngay).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD')) newOptionDate[moment(item.ngay).format('DD-MM-YYYY')] = true
            });
            if (listDate.length <= Object.keys(newOptionDate).length) newOptionDate["Tất cả"] = true;
            setBranchDateCheckboxes(newOptionDate);
          }
        }
        dispatch(AppAction.appLoading(false))
      };
  
      fetchDataBranchOption();
    }
    else if (event.target.name == 'leader') {
      dispatch(AppAction.appLoading(true))

      setLeader({
        username: event.target.value
      });

      setLeaderFileCheckboxes({});
      setLeaderDateCheckboxes({});

      const fetchDataLeaderOption = async () => {
        const option_data_leader = await dispatch(UploadchiadataAction.getOptionDataByUsernameLeader(event.target.value));
        if (option_data_leader) {
          //điền option file có sẵn
          if (option_data_leader.file) {
            var newOptionFile = {}
            option_data_leader.file.forEach((item, index) => {
              newOptionFile[item.FileName] = true
            });
            if (Object.entries(items).length - 1 <= Object.keys(newOptionFile).length) newOptionFile["Tất cả"] = true;
            setLeaderFileCheckboxes(newOptionFile);
          }
          //điền option ngày có sẵn
          if (option_data_leader.date) {
            var newOptionDate = {}
            option_data_leader.date.forEach((item, index) => {
              if (moment(item.ngay).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD')) newOptionDate[moment(item.ngay).format('DD-MM-YYYY')] = true
            });
            if (listDate.length <= Object.keys(newOptionDate).length) newOptionDate["Tất cả"] = true;
            setLeaderDateCheckboxes(newOptionDate);
          }
        }
        dispatch(AppAction.appLoading(false))
      };
  
      fetchDataLeaderOption();
    }
    else if (event.target.name == 'sale') {
      dispatch(AppAction.appLoading(true))

      setSale({
        username: event.target.value
      });

      setSaleFileCheckboxes({});
      setSaleDateCheckboxes({});

      const fetchDataSaleOption = async () => {
        const option_data_sale = await dispatch(UploadchiadataAction.getOptionDataByUsernameSale(event.target.value));
        if (option_data_sale) {
          //điền option file có sẵn
          if (option_data_sale.file) {
            var newOptionFile = {}
            option_data_sale.file.forEach((item, index) => {
              newOptionFile[item.FileName] = true
            });
            if (Object.entries(items).length <= Object.keys(newOptionFile).length) newOptionFile["Tất cả"] = true;
            setSaleFileCheckboxes(newOptionFile);
          }
          //điền option ngày có sẵn
          if (option_data_sale.date) {
            var newOptionDate = {}
            option_data_sale.date.forEach((item, index) => {
              if (moment(item.ngay).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD')) newOptionDate[moment(item.ngay).format('DD-MM-YYYY')] = true
            });
            if (listDate.length <= Object.keys(newOptionDate).length) newOptionDate["Tất cả"] = true;
            setSaleDateCheckboxes(newOptionDate);
          }
        }
        dispatch(AppAction.appLoading(false))
      };
  
      fetchDataSaleOption();
    }
  };

  const saveOption = async (event) => {
    const payload = {
      branch: {
        username: branchUser.username || null,
        file: branchFileCheckboxes != null ? Object.keys(branchFileCheckboxes).filter(key => key != "Tất cả" && branchFileCheckboxes[key]) : null,
        date: branchDateCheckboxes != null ? Object.keys(branchDateCheckboxes).filter(key => key != "Tất cả" && branchDateCheckboxes[key]) : null,
      },
      leader: {
        username: leaderUser.username || null,
        file: leaderFileCheckboxes != null ? Object.keys(leaderFileCheckboxes).filter(key => key != "Tất cả" && leaderFileCheckboxes[key]) : null,
        date: leaderDateCheckboxes != null ? Object.keys(leaderDateCheckboxes).filter(key => key != "Tất cả" && leaderDateCheckboxes[key]) : null,
      },
      sale: {
        username: saleUser.username || null,
        file: saleFileCheckboxes != null ? Object.keys(saleFileCheckboxes).filter(key => key != "Tất cả" && saleFileCheckboxes[key]) : null,
        date: saleDateCheckboxes != null ? Object.keys(saleDateCheckboxes).filter(key => key != "Tất cả" && saleDateCheckboxes[key]) : null,
      },
    }
    const result = await dispatch(UploadchiadataAction.handleCustomDataOption(payload))
    if (result && typeof result.error !== 'undefined' && result.error) alert(result.error)
    else alert("Đã lưu cài đặt phân quyền!")
  };

  useEffect(() => {
    const updatedListDate = [];

    if (role.priority > 2 && role.priority != 7) {
      const fetchDataBranchOption = async () => {
        const option_data_branch = await dispatch(UploadchiadataAction.getOptionDataByUsernameBranch(info.username));
        if (option_data_branch.date) {
          option_data_branch.date.forEach((item, index) => {
            if (moment(item.ngay).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD')) updatedListDate.push(moment(item.ngay).format('DD-MM-YYYY'));
          })
          setListDate(updatedListDate);
        }
      }
      fetchDataBranchOption()
    } else {
      //add date to list
      for (let i = 0; i <= 30; i++) {
        updatedListDate.push(moment().add(i, 'days').format('DD-MM-YYYY'));
      }
      setListDate(updatedListDate);
    }

    dispatch(UploadchiadataAction.listNoLimit())
    dispatch(AccountAction.listBranchNoLimit())
    dispatch(AccountAction.listLeaderNoLimit())
    dispatch(AccountAction.listSaleNoLimit())
  }, [page, limit])

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleConfirmOpen = (type, uploadchiadata) => {
    setSelected(uploadchiadata);
    setConfirmType(type);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      setTitle("Xoá bản ghi")
      setContent(`Xác nhận xoá bản ghi `)
    }
    setConfirm(true);
  };

  const onConfirm = () => {
    setConfirm(false);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      dispatch(UploadchiadataAction.del(selected.id))
      return;
    }
  }

  const handleConfirmClose = () => {
    setConfirm(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card {...rest} style={{ width: "100%"}}>
      <PerfectScrollbar>
      {(role.priority < 3 || role.priority == 7) && <Box sx={{ minWidth: 1050, padding: '15px' }}>
          <Typography variant="h4">
            Phân quyền theo chi nhánh
          </Typography>
          <Select
            style={{ height: 56, marginTop: 6, width: "100%", marginLeft: 0 }}
            name='branch'
            value={branchUser.username || -99}
            onChange={(newValue) => handleUsernameChange(newValue)}
            variant="outlined"
            startAdornment={(
              <InputAdornment position="start">
                <SvgIcon fontSize="small" color="action">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            )}
          >
            <MenuItem value="-99">Chọn chi nhánh...</MenuItem>
            <MenuItem value="__ALL">Tất cả</MenuItem>
            {branch_no_limit_items && branch_no_limit_items.map((account, idx) => (
              <MenuItem value={account.username}>{account.username} - {account.fullname}</MenuItem>
            ))}
          </Select>

          <Button
            color="primary"
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
            onClick={saveOption}
          >
            Lưu
          </Button>

          <Grid
              container
              spacing={3}
          >
            <Grid
                item
                md={6}
                xs={12}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={branchFileCheckboxes["Tất cả"] || false}
                      onChange={handleBranchChangeSelectFile}
                      name="Tất cả"
                    />
                  }
                  label="Tất cả"
                />
                {items && items.length > 0 && items.map((uploadchiadata) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={branchFileCheckboxes[uploadchiadata.FileName] || false}
                        onChange={handleBranchChangeSelectFile}
                        name={uploadchiadata.FileName}
                      />
                    }
                    label={uploadchiadata.FileName + (role.priority < 3 || role.priority == 7 ? (' (' + (parseInt(uploadchiadata.tong) - parseInt(uploadchiadata.tong_da_dung)) + ')') : '')}
                  />
                ))}
              </FormGroup>
            </Grid>

            <Grid
                item
                md={6}
                xs={12}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={branchDateCheckboxes["Tất cả"] || false}
                      onChange={handleBranchChangeSelectDate}
                      name="Tất cả"
                    />
                  }
                  label="Tất cả"
                />
                {listDate && listDate.map((date) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={branchDateCheckboxes[date] || false}
                        onChange={handleBranchChangeSelectDate}
                        name={date}
                      />
                    }
                    label={date}
                  />
                ))}
              </FormGroup>
            </Grid>
          </Grid>
        </Box>}
        {role.priority < 4 && <Box sx={{ minWidth: 1050, padding: '15px' }}>
          <Typography variant="h4">
            Phân quyền theo leader
          </Typography>
          <Select
            style={{ height: 56, marginTop: 6, width: "100%", marginLeft: 0 }}
            name='leader'
            value={leaderUser.username || -99}
            onChange={(newValue) => handleUsernameChange(newValue)}
            variant="outlined"
            startAdornment={(
              <InputAdornment position="start">
                <SvgIcon fontSize="small" color="action">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            )}
          >
            <MenuItem value="-99">Chọn leader...</MenuItem>
            <MenuItem value="__ALL">Tất cả</MenuItem>
            {leader_no_limit_items && leader_no_limit_items.map((account, idx) => (
              <MenuItem value={account.username}>{account.username} - {account.fullname}</MenuItem>
            ))}
          </Select>

          <Button
            color="primary"
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
            onClick={saveOption}
          >
            Lưu
          </Button>

          <Grid
              container
              spacing={3}
          >
            <Grid
                item
                md={6}
                xs={12}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={leaderFileCheckboxes["Tất cả"] || false}
                      onChange={handleLeaderChangeSelectFile}
                      name="Tất cả"
                    />
                  }
                  label="Tất cả"
                />
                {items && items.length > 0 && items.map((uploadchiadata) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={leaderFileCheckboxes[uploadchiadata.FileName] || false}
                        onChange={handleLeaderChangeSelectFile}
                        name={uploadchiadata.FileName}
                      />
                    }
                    label={uploadchiadata.FileName + (role.priority < 5 ? (' (' + (parseInt(uploadchiadata.tong) - parseInt(uploadchiadata.tong_da_dung)) + ')') : '')}
                  />
                ))}
              </FormGroup>
            </Grid>

            <Grid
                item
                md={6}
                xs={12}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={leaderDateCheckboxes["Tất cả"] || false}
                      onChange={handleLeaderChangeSelectDate}
                      name="Tất cả"
                    />
                  }
                  label="Tất cả"
                />
                {listDate && listDate.map((date) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={leaderDateCheckboxes[date] || false}
                        onChange={handleLeaderChangeSelectDate}
                        name={date}
                      />
                    }
                    label={date}
                  />
                ))}
              </FormGroup>
            </Grid>
          </Grid>
        </Box>}
        <Box sx={{ minWidth: 1050, padding: '15px' }}>
          <Typography variant="h4">
            Phân quyền theo nhân viên
          </Typography>
          <Select
            style={{ height: 56, marginTop: 6, width: "100%", marginLeft: 0 }}
            name='sale'
            value={saleUser.username || -99}
            onChange={(newValue) => handleUsernameChange(newValue)}
            variant="outlined"
            startAdornment={(
              <InputAdornment position="start">
                <SvgIcon fontSize="small" color="action">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            )}
          >
            <MenuItem value="-99">Chọn nhân viên...</MenuItem>
            <MenuItem value="__ALL">Tất cả</MenuItem>
            {sale_no_limit_items && sale_no_limit_items.map((account, idx) => (
              <MenuItem value={account.username}>{account.username} - {account.fullname}</MenuItem>
            ))}
          </Select>

          <Button
            color="primary"
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
            onClick={saveOption}
          >
            Lưu
          </Button>

          <Grid
              container
              spacing={3}
          >
            <Grid
                item
                md={6}
                xs={12}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={saleFileCheckboxes["Tất cả"] || false}
                      onChange={handleSaleChangeSelectFile}
                      name="Tất cả"
                    />
                  }
                  label="Tất cả"
                />
                {items && items.length > 0 && items.map((uploadchiadata) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={saleFileCheckboxes[uploadchiadata.FileName] || false}
                        onChange={handleSaleChangeSelectFile}
                        name={uploadchiadata.FileName}
                      />
                    }
                    label={uploadchiadata.FileName + (role.priority < 3 || role.priority == 7 ? (' (' + (parseInt(uploadchiadata.tong) - parseInt(uploadchiadata.tong_da_dung)) + ')') : '')}
                  />
                ))}
              </FormGroup>
            </Grid>

            <Grid
                item
                md={6}
                xs={12}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={saleDateCheckboxes["Tất cả"] || false}
                      onChange={handleSaleChangeSelectDate}
                      name="Tất cả"
                    />
                  }
                  label="Tất cả"
                />
                {listDate && listDate.map((date) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={saleDateCheckboxes[date] || false}
                        onChange={handleSaleChangeSelectDate}
                        name={date}
                      />
                    }
                    label={date}
                  />
                ))}
              </FormGroup>
            </Grid>
          </Grid>
        </Box>
        <Button
          color="primary"
          variant="contained"
          sx={{ m: 2 }}
          onClick={saveOption}
        >
          Lưu
        </Button>
      </PerfectScrollbar>
      <ConfirmDialog open={confirm} title={title} content={content || `Xác nhận xoá bản ghi `} highlight={selected.ten_goi_cuoc} handleClose={handleConfirmClose} handleConfirm={onConfirm} />
    </Card>
  );
};

export default PhanquyendataDetail;
