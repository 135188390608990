import {
  useEffect,
  useState,
} from 'react';

import {
  Edit3 as Edit3Icon,
  Lock,
  RefreshCcw,
  Trash2 as Trash2Icon,
  Unlock,
  Shield,
} from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { AccountAction } from 'src/actions/account.acction';
import {
  CONFIRM_TYPES,
  DEFAULT_ROW_PER_PAGE,
  ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';

import {
  Box,
  Card,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';

import ConfirmDialog from '../ConfirmDialog';
import AccountEditDialog from './AccountEditDialog';
import AccountResetPasswordDialog from './AccountResetPasswordDialog';
import CreateRoleDialog from './CreateRoleDialog';

const AccountListResults = ({ ...rest }) => {
  const dispatch = useDispatch()
  const { role } = useSelector(state => state.profile)
  const { items, total, filter } = useSelector(state => state.account)
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(DEFAULT_ROW_PER_PAGE);
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState({});
  const [confirm, setConfirm] = useState(false);
  const [confirmType, setConfirmType] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [resetPassword, setResetPassword] = useState(false);
  const [edit, setEdit] = useState(false);
  const [setKenhHoanGoi, setKenhHoanGoiChoNv] = useState([]);
  const [open, setOpen] = useState(false);

  const onSelected = (id) => {
    dispatch(AccountAction.findOne(id));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleResetPasswordOpen = (account) => {
    setSelected(account);
    setResetPassword(true);
  };

  const handleEditOpen = (account) => {
    dispatch(AccountAction.selectedAccount(account));
    setEdit(true);
  };

  const handleResetPasswordClose = () => {
    setResetPassword(false);
  };

  const handleConfirmOpen = (type, account) => {
    setSelected(account);
    setConfirmType(type)
    if (confirmType === CONFIRM_TYPES.DELETE) {
      // setTitle("Xoá tài khoản")
      // setContent(`Xác nhận xoá tài khoản `)
      setTitle("Khoá / Mở khóa tài khoản")
      setContent(`Xác nhận khoá / mở khóa tài khoản `)
    }
    if (confirmType === CONFIRM_TYPES.LOCK) {
      setTitle("Khoá tài khoản")
      setContent(`Xác nhận khoá tài khoản `)
    }
    if (confirmType === CONFIRM_TYPES.UNLOCK) {
      setTitle("Mở khoá tài khoản")
      setContent(`Xác nhận mở khoá tài khoản `)
    }
    setConfirm(true);
  };

  const handleConfirmClose = () => {
    setConfirm(false);
  };

  const onConfirm = () => {
    setConfirm(false);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      dispatch(AccountAction.del(selected.id))
      return;
    }

    if (confirmType === CONFIRM_TYPES.LOCK) {
      dispatch(AccountAction.lock(selected.id))
      return;
    }

    if (confirmType === CONFIRM_TYPES.UNLOCK) {
      dispatch(AccountAction.unlock(selected.id))
      return;
    }
  }

  useEffect(() => {
    const payload = {
      ...filter,
      limit: limit,
      page: page,
    };
    dispatch(AccountAction.updateFilter(payload));
    dispatch(AccountAction.list(page, limit));

    if (role.priority < 2) {
      const syncdskenhmoigoi = async () => {
        const getdskenhmoigoi = await dispatch(AccountAction.getdskenhmoigoi());

        console.log(getdskenhmoigoi);

        if (getdskenhmoigoi && typeof getdskenhmoigoi.data !== 'undefined' && getdskenhmoigoi.data && typeof getdskenhmoigoi.data.values !== 'undefined' && getdskenhmoigoi.data.values) {
          console.log(getdskenhmoigoi.data.values)
          getdskenhmoigoi.data.values.forEach((item, index) => {
            let setKenh = setKenhHoanGoi;

            if (typeof setKenh[item.staff_username] === 'undefined') {
              setKenh[item.staff_username] = {}
            }

            if (item.active == true) {
              setKenh[item.staff_username][item.kenh] = true
            } else {
              setKenh[item.staff_username][item.kenh] = false
            }

            setKenhHoanGoiChoNv(setKenh);
          })
        }
      }
      
      syncdskenhmoigoi()
    }
  }, [page, limit])

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0)
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const chonKenhHoanGoi = async (accountUsername, channelName, channelText) => {
    setKenhHoanGoiChoNv({
      ...setKenhHoanGoi,
      [accountUsername]: {
        ...setKenhHoanGoi[accountUsername],
        [channelName]: typeof setKenhHoanGoi[accountUsername] !== 'undefined' && typeof setKenhHoanGoi[accountUsername][channelName] !== 'undefined' && setKenhHoanGoi[accountUsername][channelName] == true ? false : true,
      },
    });

    //Khi hủy chọn kênh
    if (typeof setKenhHoanGoi[accountUsername] !== 'undefined' && typeof setKenhHoanGoi[accountUsername][channelName] !== 'undefined' && setKenhHoanGoi[accountUsername][channelName] == true) {
      await dispatch(AccountAction.huyChonKenh({accountUsername, channelName, channelText}));
    }
    //Khi chọn kênh
    else {
      await dispatch(AccountAction.chonKenh({accountUsername, channelName, channelText}));
    }
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ pl: 4 }}>
                  #
                </TableCell>
                <TableCell>
                  Tên
                </TableCell>
                <TableCell>
                  Username
                </TableCell>
                <TableCell>
                  Email
                </TableCell>
                <TableCell>
                  Vai trò
                </TableCell>
                <TableCell>
                  Trạng thái
                </TableCell>
                <TableCell style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  Hành động
                </TableCell>
                {role.priority < 2 && <TableCell>
                  Kênh mời gói
                </TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {items.slice(page * limit, page * limit + limit).map((account, idx) => (
                <TableRow
                  hover
                  key={account.username}
                  selected={selectedCustomerIds.indexOf(account.username) !== -1}
                >
                  <TableCell sx={{ pl: 4 }}>
                    {page * limit + idx + 1}
                  </TableCell>
                  <TableCell>
                    {account.full_name}
                  </TableCell>
                  <TableCell>
                    {account.username}
                  </TableCell>
                  <TableCell>
                    {account.email}
                  </TableCell>
                  <TableCell>
                    <Chip label={account.role_name} color='primary' />
                  </TableCell>

                  <TableCell>
                    {!account.is_deleted ? <Chip label="Đang hoạt động" color='success' />
                      : <Chip label="Ngừng hoạt động" color='error' />}
                  </TableCell>

                  {1 < 0 && <TableCell>
                    {account.active ? <Chip label="Đang hoạt động" color='success' />
                      : <Chip label="Ngừng hoạt động" color='error' />}
                  </TableCell>}

                  <TableCell style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                    {1 < 0 && account.role_priority > 1 && <IconButton aria-label="edit" onClick={() => handleConfirmOpen(account.active ? CONFIRM_TYPES.LOCK : CONFIRM_TYPES.UNLOCK, account)}>
                      {account.active ? <Lock color='#9500ae' /> : <Unlock color='#2196f3' />}
                    </IconButton>}

                    {account.role_priority > 1 && <IconButton aria-label="edit" onClick={() => handleConfirmOpen(CONFIRM_TYPES.DELETE, account)}>
                      {!account.is_deleted ? <Lock color='#9500ae' /> : <Unlock color='#2196f3' />}
                    </IconButton>}

                    <IconButton onClick={() => handleResetPasswordOpen(account)}>
                      <RefreshCcw color='blue' />
                    </IconButton>

                    <IconButton aria-label="edit" onClick={() => handleEditOpen(account)}>
                      <Edit3Icon color='green' />
                    </IconButton>

                    {1 < 0 && account.role_priority > 1 && <IconButton aria-label="delete" onClick={() => handleConfirmOpen(CONFIRM_TYPES.DELETE, account)}>
                      <Trash2Icon color='red' />
                    </IconButton>}

                    {role.priority < 3 && account.role_priority > 1 && <IconButton aria-label="edit_permission" onClick={() => onSelected(account.id)}>
                      <Shield color='blue' />
                    </IconButton>}

                  </TableCell>
                  {role.priority < 2 && account.role_priority == 3 && <TableCell>
                    <label>
                      <input
                        type="checkbox"
                        checked={typeof setKenhHoanGoi[account.username] !== 'undefined' && typeof setKenhHoanGoi[account.username]['ma_xac_nhan_1'] !== 'undefined' && setKenhHoanGoi[account.username]['ma_xac_nhan_1'] == true ? true : false}
                        onChange={() => chonKenhHoanGoi(account.username, 'ma_xac_nhan_1', 'Mã xác nhận 1')}
                      />
                      <span style={{marginLeft: '5px'}}>Mã xác nhận 1</span>
                    </label>

                    <br></br>

                    <label>
                      <input
                        type="checkbox"
                        checked={typeof setKenhHoanGoi[account.username] !== 'undefined' && typeof setKenhHoanGoi[account.username]['ma_xac_nhan_2'] !== 'undefined' && setKenhHoanGoi[account.username]['ma_xac_nhan_2'] == true ? true : false}
                        onChange={() => chonKenhHoanGoi(account.username, 'ma_xac_nhan_2', 'Mã xác nhận 2')}
                      />
                      <span style={{marginLeft: '5px'}}>Mã xác nhận 2</span>
                    </label>

                    <br></br>

                    <label>
                      <input
                        type="checkbox"
                        checked={typeof setKenhHoanGoi[account.username] !== 'undefined' && typeof setKenhHoanGoi[account.username]['ma_xac_nhan_3'] !== 'undefined' && setKenhHoanGoi[account.username]['ma_xac_nhan_3'] == true ? true : false}
                        onChange={() => chonKenhHoanGoi(account.username, 'ma_xac_nhan_3', 'Mã xác nhận 3')}
                      />
                      <span style={{marginLeft: '5px'}}>Mã xác nhận 3</span>
                    </label>

                    <br></br>

                    <label>
                      <input
                        type="checkbox"
                        checked={typeof setKenhHoanGoi[account.username] !== 'undefined' && typeof setKenhHoanGoi[account.username]['ma_xac_nhan_4'] !== 'undefined' && setKenhHoanGoi[account.username]['ma_xac_nhan_4'] == true ? true : false}
                        onChange={() => chonKenhHoanGoi(account.username, 'ma_xac_nhan_4', 'Mã xác nhận 4')}
                      />
                      <span style={{marginLeft: '5px'}}>Mã xác nhận 4</span>
                    </label>
                  </TableCell>}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      />
      <AccountResetPasswordDialog open={resetPassword} account={selected} handleClose={handleResetPasswordClose} />
      <ConfirmDialog open={confirm} title={title || "Khoá / Mở khóa tài khoản"} content={content || `Xác nhận khoá / mở khóa tài khoản `} highlight={selected.username} handleClose={handleConfirmClose} handleConfirm={onConfirm} />
      <AccountEditDialog open={edit} account={selected} handleClose={() => setEdit(false)} />
      <CreateRoleDialog open={open} handleClose={handleClose} type={"EDIT"} />
    </Card >
  );
};

export default AccountListResults;
