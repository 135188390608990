import { PermissionService } from 'src/services/permission.service';
import {
  DEFAULT_PAGE,
  DEFAULT_ROW_PER_PAGE,
} from 'src/utils/constants';

import { AppAction } from './app.action';

export const PERMISSION_LIST_SUCCESS = 'PERMISSION:FIND_ALL_SUCCESS'
export const PERMISSION_LIST_FAILD = 'PERMISSION:FIND_ALL_FAILD'

export const PERMISSION_CREATE_SUCCESS = 'PERMISSION:CREATE_SUCCESS'
export const PERMISSION_CREATE_FAILD = 'PERMISSION:CREATE_FAILD'

export const PERMISSION_DEL_SUCCESS = 'PERMISSION:DEL_SUCCESS'
export const PERMISSION_DEL_FAILD = 'PERMISSION:DEL_FAILD'

export const PermissionAction = {
  list,
}

function list(page = DEFAULT_PAGE, count = DEFAULT_ROW_PER_PAGE,) {
  return (dispatch, getState) => {
    dispatch(AppAction.appLoading(true));
    PermissionService.findAll(page, count).then((rs) => {
      dispatch(success({
        items: rs.data.values,
        total: rs.data.count
      }));
    }).catch((error) => {
      dispatch(failure(error));
    }).finally(() => {
      dispatch(AppAction.appLoading(false));
    })
  };
  function success(data) { return { type: PERMISSION_LIST_SUCCESS, data } }
  function failure(error) { return { type: PERMISSION_LIST_FAILD, error } }
}