import {
  PERMISSION_CREATE_SUCCESS,
  PERMISSION_LIST_SUCCESS,
} from 'src/actions/permission.acction';

const initState = {
  items: [],
  total: 0,
}

const permissionReducer = (state = initState, action) => {
  switch (action.type) {
    case PERMISSION_LIST_SUCCESS: {
      return {
        ...state,
        items: action.data.items,
        total: action.data.total,
      }
    }

    case PERMISSION_CREATE_SUCCESS: {
      return {
        ...state,
        list: {
          data: action.data
        }
      }
    }

    default: {
      return state;
    }

  }
}
export default permissionReducer