import {
  PROFILE_INFO_SUCCESS,
  PROFILE_LIST_BRAND_SUCCESS,
  PROFILE_LIST_LEADER_SUCCESS,
  PROFILE_LIST_SALEMAN_SUCCESS,
  PROFILE_PERMISSION_SUCCESS,
  PROFILE_ROLE_SUCCESS,
} from 'src/actions/profile.action';

const initialState = {
  info: {},
  permissions: [],
  role: {},
  saleman: [],
  leaders: [],
  brands: []
}

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_INFO_SUCCESS: {
      return {
        ...state,
        info: action.data,
      };
    }
    case PROFILE_PERMISSION_SUCCESS: {
      return {
        ...state,
        permissions: action.data,
      };
    }
    case PROFILE_ROLE_SUCCESS: {
      return {
        ...state,
        role: action.data,
      };
    }
    case PROFILE_LIST_SALEMAN_SUCCESS: {
      return {
        ...state,
        saleman: action.data
      };
    }
    case PROFILE_LIST_LEADER_SUCCESS: {
      return {
        ...state,
        leaders: action.data
      };
    }
    case PROFILE_LIST_BRAND_SUCCESS: {
      return {
        ...state,
        brands: action.data
      };
    }
    default: {
      return state;
    }
  }
};
export default profileReducer;