import { toQuery } from 'src/utils/utils';

import { handleResponse } from '../utils/auth-header';
import axios, { axiosOption } from '../utils/axios';

export const UploadchiadataService = {

  getInfoByFileName: (payload) => {
    return axios.post(`/api/v1/uploadchiadata/getInfoByFileName`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  deleteFile: (FileName) => {
    return axios.post('/api/v1/uploadchiadata/deleteFile', {FileName: FileName}, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  getOptionDataByFile: (payload) => {
    return axios.post('/api/v1/uploadchiadata/getOptionDataByFile', payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  findAllByFileName: (payload) => {
    return axios.post('/api/v1/uploadchiadata/findAllByFileName', payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  findAll: (page, count) => {
    return axios.get(`/api/v1/uploadchiadata?page=${page}&count=${count}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  findAllNoLimit: () => {
    return axios.get(`/api/v1/uploadchiadata/findAllNoLimit`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  save: (payload) => {
    return axios.post('/api/v1/uploadchiadata/import', payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  activeOrDeactive: (payload) => {
    return axios.post(`/api/v1/uploadchiadata/activeOrDeactive`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  handleCustomDataOption: (payload) => {
    return axios.post(`/api/v1/uploadchiadata/handleCustomDataOption`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  handleFileUserQuantityOption: (payload) => {
    return axios.post(`/api/v1/uploadchiadata/handleFileUserQuantityOption`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  getOptionDataByUsername: (username) => {
    return axios.get(`/api/v1/uploadchiadata/getOptionDataByUsername/${username}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },
}