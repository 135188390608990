import { TilebatsoService } from 'src/services/tilebatso.service';
import { showToast } from 'src/utils/common';
import {
    DEFAULT_ROW_PER_PAGE,
  } from 'src/utils/constants';

import { AppAction } from './app.action';

export const TILEBATSO_UPDATE_FILTER = 'TILEBATSO:TILEBATSO_UPDATE_FILTER'

export const TILEBATSO_NEW_CARE_SUCCESS = 'TILEBATSO:NEW_CARE_SUCCESS'
export const TILEBATSO_LIST_SUCCESS = 'TILEBATSO:FIND_ALL_SUCCESS'
export const TILEBATSO_LIST_FAILD = 'TILEBATSO:FIND_ALL_FAILD'
export const TILEBATSO_COUNT_ASSIGN_SUCCESS = 'TILEBATSO:COUNT_ASSIGN_SUCCESS'
export const TILEBATSO_COUNT_ASSIGN_FAILD = 'TILEBATSO:COUNT_ASSIGN_FAILD'

export const TILEBATSO_CREATE_SUCCESS = 'TILEBATSO:CREATE_SUCCESS'
export const TILEBATSO_CREATE_FAILD = 'TILEBATSO:CREATE_FAILD'

export const TILEBATSO_IMPORT_SUCCESS = 'TILEBATSO:IMPORT_SUCCESS'
export const TILEBATSO_SAVE_SUCCESS = 'TILEBATSO:SAVE_SUCCESS'
export const TILEBATSO_SAVE_FAILD = 'TILEBATSO:SAVE_SUCCESS'

export const TILEBATSO_DEL_SUCCESS = 'TILEBATSO:DEL_SUCCESS'
export const TILEBATSO_DEL_FAILD = 'TILEBATSO:DEL_FAILD'


export const TILEBATSO_HISTORY_LIST_SUCCESS = 'TILEBATSO:HISTORY_FIND_ALL_SUCCESS'
export const TILEBATSO_HISTORY_LIST_FAILD = 'TILEBATSO:HISTORY_FIND_ALL_FAILD'

export const TILEBATSO_LIST_EXPORT_SUCCESS = 'TILEBATSO:FIND_ALL_EXPORT_SUCCESS'
export const TILEBATSO_LIST_EXPORT_FAILD = 'TILEBATSO:FIND_ALL_EXPORT_FAILD'

export const TilebatsoAction = {
    list,
    listExport,
    findAssign,
    create,
    updateFilter,
    importData,
    saveData,
    update,
    del,
    assign,
    countAssign,
    history,
    updateUser,
}


function countAssign() {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        TilebatsoService.countAssign().then((rs) => {
            dispatch(success(rs.data.total));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: TILEBATSO_COUNT_ASSIGN_SUCCESS, data } }
    function failure(error) { return { type: TILEBATSO_COUNT_ASSIGN_FAILD, error } }
}

function findAssign(page = 0, count = 20) {
    return (dispatch, getState) => {
        const { tilebatso } = getState()
        dispatch(AppAction.appLoading(true));
        TilebatsoService.findAssign(page, count).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...tilebatso.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: TILEBATSO_LIST_SUCCESS, data } }
    function failure(error) { return { type: TILEBATSO_LIST_FAILD, error } }
}

function list() {
    return (dispatch, getState) => {
        const { tilebatso } = getState();
        dispatch(AppAction.appLoading(true));

        const { filter } = tilebatso;
        
        TilebatsoService.findAll(filter.page, filter.limit, filter).then((rs) => {
            if (!!filter && filter.page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count,
                }));
            } else {
                dispatch(success({
                    items: [...tilebatso.items, ...rs.data.values],
                    total: rs.data.count,
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: TILEBATSO_LIST_SUCCESS, data } }
    function failure(error) { return { type: TILEBATSO_LIST_FAILD, error } }
}

function listExport() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const { tilebatso } = getState();

            const { filter } = tilebatso;

            // Perform your asynchronous operation (e.g., API call)
            TilebatsoService.listExport(filter)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    }
    function success(data) { return { type: TILEBATSO_LIST_EXPORT_SUCCESS, data } }
    function failure(error) { return { type: TILEBATSO_LIST_EXPORT_FAILD, error } }
}

function updateFilter(payload) {
    return { type: TILEBATSO_UPDATE_FILTER, data: payload }
}

function importData(payload) {
    return { type: TILEBATSO_IMPORT_SUCCESS, data: payload }
}

function saveData(payload) {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        let count = 0
        for (let i = 0; i < payload.length; i += 200) {
            // eslint-disable-next-line no-loop-func
            TilebatsoService.save(payload.slice(i, i + 200)).then((rs) => {
                count += parseInt(rs.data.saved, 10)
                if (count === payload.length) {
                    dispatch(success(rs));
                    dispatch(TilebatsoAction.list())
                    dispatch(AppAction.appLoading(false));
                }
            }).catch((error) => {
                dispatch(failure(error));
                dispatch(AppAction.appLoading(false));
            })
        }
    };

    function success(data) { return { type: TILEBATSO_SAVE_SUCCESS, data } }
    function failure(error) { return { type: TILEBATSO_SAVE_FAILD, error } }
}

function update(payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        TilebatsoService.create(payload).then((rs) => {
            dispatch(success(rs));
            dispatch(TilebatsoAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: TILEBATSO_CREATE_SUCCESS, data } }
    function failure(error) { return { type: TILEBATSO_CREATE_FAILD, error } }
}

function updateUser(username, track0, track1) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        TilebatsoService.updateUser(username, track0, track1).then((rs) => {
            
        }).catch((error) => {
            
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
}

function create(payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        TilebatsoService.create(payload).then((rs) => {
            dispatch(success(rs));
            dispatch(TilebatsoAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: TILEBATSO_CREATE_SUCCESS, data } }
    function failure(error) { return { type: TILEBATSO_CREATE_FAILD, error } }
}

function del(id) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        TilebatsoService.delete(id).then((rs) => {
            dispatch(success(rs));
            dispatch(TilebatsoAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: TILEBATSO_DEL_SUCCESS, data } }
    function failure(error) { return { type: TILEBATSO_DEL_FAILD, error } }
}


function assign(payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        TilebatsoService.assign(payload).then((rs) => {
            showToast('success', "Phân chia dữ liệu thành công.")
            dispatch(TilebatsoAction.list())
        }).catch((error) => {
            showToast('error', "Phân chia dữ liệu thất bại.\nVui lòng thử lại lần sau.")
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
}


function history(payload) {
    return (dispatch, getState) => {
        const { tilebatso } = getState()
        dispatch(AppAction.appLoading(true));
        TilebatsoService.history(payload).then((rs) => {
            if (payload.page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...tilebatso.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: TILEBATSO_HISTORY_LIST_SUCCESS, data } }
    function failure(error) { return { type: TILEBATSO_HISTORY_LIST_FAILD, error } }
}