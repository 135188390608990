import { handleResponse } from '../utils/auth-header';
import axios from '../utils/axios';

export const AuthService = {
  login: (payload) => {
    return axios.post(`/api/v1/auth/login`, payload)
      .then((rs) => {
        return handleResponse(rs)
      }).catch((error) => {
        return handleResponse(error.response)
      })
  },

  logout: (payload) => {
    return axios.post(`/api/v1/auth/logout`, payload)
      .then((rs) => {
        return handleResponse(rs)
      }).catch((error) => {
        return handleResponse(error.response)
      })
  }
}