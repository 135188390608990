import {
  useEffect,
  useState,
} from 'react';

import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { TilebatsoAction } from 'src/actions/tilebatso.action';
import {
  DEFAULT_ROW_PER_PAGE,
  ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';

import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TextField,
} from '@material-ui/core';

import numeral from 'numeral';

const TilebatsoDetail = ({ ...rest }) => {
  const dispatch = useDispatch()
  const { items, total, filter } = useSelector(state => state.tilebatso);
  const { role } = useSelector(state => state.profile);

  const [limit, setLimit] = useState(filter.limit || DEFAULT_ROW_PER_PAGE);
  const [page, setPage] = useState(filter.page || 0);
  const [updateUserValue, setUpdateUserValue] = useState({});


  useEffect(() => {
    const payload = {
      ...filter,
      limit: limit,
      page: page,
    };
    
    dispatch(TilebatsoAction.updateFilter(payload));

    dispatch(TilebatsoAction.list());
  }, [page, limit])

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // const handleUserChange = (event, username, track0, track1) => {
  //   if (event.key === 'Enter') {
  //     username = username.trim();
  //     if (username != '') {
  //       setUpdateUserValue({
  //         ...updateUserValue,
  //         [track0 + '_' + track1]: username,
  //       });
  //       dispatch(TilebatsoAction.updateUser(username, track0, track1));
  //     }
  //   }
  // };

  const handleUserChange = (event, username, track0, track1) => {
    username = username.trim();
    if (username != '') {
      setUpdateUserValue({
        ...updateUserValue,
        [track0 + '_' + track1]: username,
      });
      dispatch(TilebatsoAction.updateUser(username, track0, track1));
    }
  };

  return (
    <Card {...rest} style={{ width: "100%"}}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  #
                </TableCell>
                <TableCell>
                  Username NV
                </TableCell>
                <TableCell>
                  Tên NV
                </TableCell>
                <TableCell>
                  Đã nhận số
                </TableCell>
                <TableCell>
                  Số đã trả KQ
                </TableCell>
                <TableCell>
                  Hẹn
                </TableCell>
                <TableCell>
                  Chấp nhận
                </TableCell>
                <TableCell>
                  Từ chối
                </TableCell>
                <TableCell>
                  Không nghe máy
                </TableCell>
                <TableCell>
                  Không liên lạc được
                </TableCell>
                <TableCell>
                  Dùng lại gói cũ
                </TableCell>
                <TableCell>
                  Đã đăng ký lại
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.slice(page * limit, (page + 1) * limit).map((tilebatso, index) => (
                <TableRow
                  hover
                  key={page * limit + index + 1}
                >
                  <TableCell>
                    {page * limit + index + 1}
                  </TableCell>
                  <TableCell>
                    {tilebatso.username_nv}
                  </TableCell>
                  <TableCell>
                    {tilebatso.ten_nv}
                  </TableCell>
                  <TableCell>
                    {numeral(tilebatso.da_nhan_so).format('0,0')}
                  </TableCell>
                  <TableCell>
                    {numeral(tilebatso.so_tra_ket_qua).format('0,0')}
                  </TableCell>
                  <TableCell>
                    {tilebatso.so_tra_ket_qua == 0 ? '0 (0%)' : numeral(tilebatso.hen).format('0,0') + " (" + parseInt(parseInt(tilebatso.hen) / parseInt(tilebatso.so_tra_ket_qua) * 100) + "%)"}
                  </TableCell>
                  <TableCell>
                    {tilebatso.so_tra_ket_qua == 0 ? '0 (0%)' : numeral(tilebatso.chap_nhan).format('0,0') + " (" + parseInt(parseInt(tilebatso.chap_nhan) / parseInt(tilebatso.so_tra_ket_qua) * 100) + "%)"}
                  </TableCell>
                  <TableCell>
                    {tilebatso.so_tra_ket_qua == 0 ? '0 (0%)' : numeral(tilebatso.tu_choi).format('0,0') + " (" + parseInt(parseInt(tilebatso.tu_choi) / parseInt(tilebatso.so_tra_ket_qua) * 100) + "%)"}
                  </TableCell>
                  <TableCell>
                    {tilebatso.so_tra_ket_qua == 0 ? '0 (0%)' : numeral(tilebatso.khong_nghe_may).format('0,0') + " (" + parseInt(parseInt(tilebatso.khong_nghe_may) / parseInt(tilebatso.so_tra_ket_qua) * 100) + "%)"}
                  </TableCell>
                  <TableCell>
                    {tilebatso.so_tra_ket_qua == 0 ? '0 (0%)' : numeral(tilebatso.khong_lien_lac_duoc).format('0,0') + " (" + parseInt(parseInt(tilebatso.khong_lien_lac_duoc) / parseInt(tilebatso.so_tra_ket_qua) * 100) + "%)"}
                  </TableCell>
                  <TableCell>
                    {tilebatso.so_tra_ket_qua == 0 ? '0 (0%)' : numeral(tilebatso.dung_lai_goi_cu).format('0,0') + " (" + parseInt(parseInt(tilebatso.dung_lai_goi_cu) / parseInt(tilebatso.so_tra_ket_qua) * 100) + "%)"}
                  </TableCell>
                  <TableCell>
                    {tilebatso.so_tra_ket_qua == 0 ? '0 (0%)' : numeral(tilebatso.da_dang_ky_lai).format('0,0') + " (" + parseInt(parseInt(tilebatso.da_dang_ky_lai) / parseInt(tilebatso.so_tra_ket_qua) * 100) + "%)"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      />
    </Card>
  );
};

export default TilebatsoDetail;
