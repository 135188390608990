import {
    useEffect,
    useState,
} from 'react';

import {
    useDispatch,
    useSelector,
} from 'react-redux';
import { PermissionAction } from 'src/actions/permission.acction';
import { GiahoantienAction } from 'src/actions/giahoantien.action';

import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    TextField,
    Typography,
    Grid,
} from '@material-ui/core';

const CreateEditDialog = (props) => {
    const dispatch = useDispatch()
    const [selectedPermissionIds, setSelectedPermissionIds] = useState([]);
    const { items } = useSelector(state => state.permission)
    const { selected } = useSelector(state => state.giahoantien)
    const [ten_goi_cuoc, setTenGoiCuoc] = useState("")
    const [gia_gia_han, setGiaGiaHan] = useState("0")
    const [gia_nang_cap, setGiaNangCap] = useState("0")
    const [gia_nang_cap_t2, setGiaNangCapT2] = useState("0")
    const [gia_nang_cap_t3, setGiaNangCapT3] = useState("0")
    const [gia_nang_cap_ht0, setGiaNangCapHT0] = useState("0")
    const [gia_nang_cap_ht1, setGiaNangCapHT1] = useState("0")
    const [gia_nang_cap_ht2, setGiaNangCapHT2] = useState("0")
    const [gia_nang_cap_ht3, setGiaNangCapHT3] = useState("0")
    const [gia_nang_cap_ht4, setGiaNangCapHT4] = useState("0")
    const [gia_nang_cap_g1, setGiaNangCapG1] = useState("0")
    const [gia_nang_cap_g2, setGiaNangCapG2] = useState("0")
    const [gia_nang_cap_g3, setGiaNangCapG3] = useState("0")
    const [gia_goi, setGiaGoi] = useState("0")

    useEffect(() => {
        if (items.length > 0) {
            return;
        }
        dispatch(PermissionAction.list(0, 100))
    }, [])

    useEffect(() => {
        if (props.type === 'EDIT') {
            if (selected) {
                setTenGoiCuoc(selected.ten_goi_cuoc)
                setGiaGiaHan(selected.gia_gia_han)
                setGiaNangCap(selected.gia_nang_cap)
                setGiaNangCapT2(selected.gia_nang_cap_t2)
                setGiaNangCapT3(selected.gia_nang_cap_t3)
                setGiaNangCapHT0(selected.gia_nang_cap_ht0)
                setGiaNangCapHT1(selected.gia_nang_cap_ht1)
                setGiaNangCapHT2(selected.gia_nang_cap_ht2)
                setGiaNangCapHT3(selected.gia_nang_cap_ht3)
                setGiaNangCapHT4(selected.gia_nang_cap_ht4)
                setGiaNangCapG1(selected.gia_nang_cap_g1)
                setGiaNangCapG2(selected.gia_nang_cap_g2)
                setGiaNangCapG3(selected.gia_nang_cap_g3)
                setGiaGoi(selected.gia_goi)
            }
        }
    }, [selected])

    const onPressPositive = () => {
        if (ten_goi_cuoc === "") {
            return;
        }
        if (gia_gia_han === "") {
            return;
        }
        if (gia_nang_cap === "") {
            return;
        }
        if (gia_nang_cap_t2 === "") {
            return;
        }
        if (gia_nang_cap_t3 === "") {
            return;
        }
        if (gia_nang_cap_ht0 === "") {
            return;
        }
        if (gia_nang_cap_ht1 === "") {
            return;
        }
        if (gia_nang_cap_ht2 === "") {
            return;
        }
        if (gia_nang_cap_ht3 === "") {
            return;
        }
        if (gia_nang_cap_ht4 === "") {
            return;
        }
        if (gia_nang_cap_g1 === "") {
            return;
        }
        if (gia_nang_cap_g2 === "") {
            return;
        }
        if (gia_nang_cap_g3 === "") {
            return;
        }
        if (gia_goi === "") {
            return;
        }
        if (props.type === 'EDIT') {
            dispatch(GiahoantienAction.update(selected.id, { 
                ten_goi_cuoc, 
                gia_gia_han, 
                gia_nang_cap, 
                gia_nang_cap_t2, 
                gia_nang_cap_t3, 
                gia_nang_cap_ht0, 
                gia_nang_cap_ht1, 
                gia_nang_cap_ht2, 
                gia_nang_cap_ht3, 
                gia_nang_cap_ht4, 
                gia_nang_cap_g1, 
                gia_nang_cap_g2, 
                gia_nang_cap_g3, 
                gia_goi 
            }))
        } else {
            dispatch(GiahoantienAction.create({ 
                ten_goi_cuoc, 
                gia_gia_han, 
                gia_nang_cap, 
                gia_nang_cap_t2, 
                gia_nang_cap_t3, 
                gia_nang_cap_ht0, 
                gia_nang_cap_ht1, 
                gia_nang_cap_ht2, 
                gia_nang_cap_ht3, 
                gia_nang_cap_ht4, 
                gia_nang_cap_g1, 
                gia_nang_cap_g2, 
                gia_nang_cap_g3, 
                gia_goi 
            }))
        }
        resetState();
        props.handleClose();
    }

    const resetState = () => {
        setTenGoiCuoc("")
        setGiaGiaHan("0")
        setGiaNangCap("0")
        setGiaNangCapT2("0")
        setGiaNangCapT3("0")
        setGiaNangCapHT0("0")
        setGiaNangCapHT1("0")
        setGiaNangCapHT2("0")
        setGiaNangCapHT3("0")
        setGiaNangCapHT4("0")
        setGiaNangCapG1("0")
        setGiaNangCapG2("0")
        setGiaNangCapG3("0")
        setGiaGoi("0")
    }

    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedPermissionIds.indexOf(id);
        let newSelectedCustomerIds = [];
        if (selectedIndex === -1) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds, id);
        } else if (selectedIndex === 0) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds.slice(1));
        } else if (selectedIndex === selectedPermissionIds.length - 1) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(
                selectedPermissionIds.slice(0, selectedIndex),
                selectedPermissionIds.slice(selectedIndex + 1)
            );
        }
        setSelectedPermissionIds(newSelectedCustomerIds);
    };

    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>{props.type === 'EDIT' ? "Cập nhật bản ghi" : "Tạo bản ghi mới"}</DialogTitle>
            <DialogContent style={{ width: 600, paddingTop: 20 }}>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Tên gói cước"
                            name="ten_goi_cuoc"
                            onChange={(e) => setTenGoiCuoc(e.target.value)}
                            required
                            value={ten_goi_cuoc}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Giá gia hạn"
                            name="gia_gia_han"
                            onChange={(e) => setGiaGiaHan(e.target.value)}
                            value={gia_gia_han}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Giá nâng cấp T1"
                            name="gia_nang_cap"
                            onChange={(e) => setGiaNangCap(e.target.value)}
                            value={gia_nang_cap}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Giá nâng cấp T2"
                            name="gia_nang_cap_t2"
                            onChange={(e) => setGiaNangCapT2(e.target.value)}
                            value={gia_nang_cap_t2}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Giá nâng cấp T3"
                            name="gia_nang_cap_t3"
                            onChange={(e) => setGiaNangCapT3(e.target.value)}
                            value={gia_nang_cap_t3}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Giá nâng cấp HT0"
                            name="gia_nang_cap_ht0"
                            onChange={(e) => setGiaNangCapHT0(e.target.value)}
                            value={gia_nang_cap_ht0}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Giá nâng cấp HT1"
                            name="gia_nang_cap_ht1"
                            onChange={(e) => setGiaNangCapHT1(e.target.value)}
                            value={gia_nang_cap_ht1}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Giá nâng cấp HT2"
                            name="gia_nang_cap_ht2"
                            onChange={(e) => setGiaNangCapHT2(e.target.value)}
                            value={gia_nang_cap_ht2}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Giá nâng cấp HT3"
                            name="gia_nang_cap_ht3"
                            onChange={(e) => setGiaNangCapHT3(e.target.value)}
                            value={gia_nang_cap_ht3}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Giá nâng cấp HT4"
                            name="gia_nang_cap_ht4"
                            onChange={(e) => setGiaNangCapHT4(e.target.value)}
                            value={gia_nang_cap_ht4}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Giá nâng cấp G1"
                            name="gia_nang_cap_g1"
                            onChange={(e) => setGiaNangCapG1(e.target.value)}
                            value={gia_nang_cap_g1}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Giá nâng cấp G2"
                            name="gia_nang_cap_g2"
                            onChange={(e) => setGiaNangCapG2(e.target.value)}
                            value={gia_nang_cap_g2}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Giá nâng cấp G3"
                            name="gia_nang_cap_g3"
                            onChange={(e) => setGiaNangCapG3(e.target.value)}
                            value={gia_nang_cap_g3}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Giá gói"
                            name="gia_goi"
                            onChange={(e) => setGiaGoi(e.target.value)}
                            value={gia_goi}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Huỷ</Button>
                <Button onClick={onPressPositive}>{props.type === 'EDIT' ? "Cập nhật" : "Tạo"}</Button>
            </DialogActions>
        </Dialog >
    );
}

export default CreateEditDialog;