import { Helmet } from 'react-helmet';
import Report90849332Detail
  from 'src/components/report-9084-9332-detail/Report90849332Detail';

import {
  Box,
  Container,
} from '@material-ui/core';

import Report90849332ListToolbar from '../components/report-9084-9332-detail/Report90849332ListToolbar';

const Report90849332List = () => (
  <>
    <Helmet>
      <title>Báo cáo doanh thu chi tiết</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container style={{maxWidth: "100%"}}>
        <Report90849332ListToolbar />
        <Box sx={{ pt: 3 }}>
          <Report90849332Detail />
        </Box>
      </Container>
    </Box>
  </>
);

export default Report90849332List;
