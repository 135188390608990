import React, { useState } from 'react';

import { Search as SearchIcon } from 'react-feather';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { CustomerAction } from 'src/actions/customer.action';

import {
  Box,
  Button,
  Card,
  CardContent,
  InputAdornment,
  SvgIcon,
  TextField,
} from '@material-ui/core';

import CustomerCareCreateDialog from './CustomerCareCreateDialog';
import CustomerCareNewInfo from './CustomerCareNewInfo';

const CustomerCareListToolbar = (props) => {
  const { newCare } = useSelector(state => state.customer)
  const dispatch = useDispatch()

  const handleNewCarePress = () => {
    dispatch(CustomerAction.newCare())
  }

  const [openResult, setOpenResult] = useState(false);

  const [isCaring, setIsCaring] = useState(false);

  const handleCarePress = () => {
    setOpenResult(true);
    setIsCaring(true);
  };

  const handleAlertPress = () => {

  }
  const handleClose = () => {
    setOpenResult(false);
    setIsCaring(false);
  };

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button
          color="primary"
          disabled={isCaring || newCare ? true : false}
          variant="contained"
          onClick={handleNewCarePress}
        >
          Chăm sóc khách hàng mới
        </Button>
      </Box>
      <Box sx={{ mt: 3 }}>
        <CustomerCareNewInfo onCare={handleCarePress} onAlert={handleAlertPress} />
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card className="filter_card">
          <CardContent>
            <Box sx={{ maxWidth: 500 }}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search customer"
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
      <CustomerCareCreateDialog open={openResult} handleClose={handleClose} customer={newCare} />
    </Box>
  )
};

export default CustomerCareListToolbar;
