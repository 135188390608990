import { Helmet } from 'react-helmet';
import UploadListResults from 'src/components/upload/UploadListResults';
import UploadListToolbar from 'src/components/upload/UploadListToolbar';

import {
  Box,
  Container,
} from '@material-ui/core';

const UploadList = () => (
  <>
    <Helmet>
      <title>Upload</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
        <UploadListToolbar />
        <Box sx={{ pt: 3 }}>
          <UploadListResults />
        </Box>
      </Container>
    </Box>
  </>
);

export default UploadList;
