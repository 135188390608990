import {
  useEffect,
  useState,
} from 'react';

import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { TinhluongAction } from 'src/actions/tinhluong.action';
import {
  DEFAULT_ROW_PER_PAGE,
  ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';

import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TextField,
} from '@material-ui/core';

import numeral from 'numeral';

import TinhluongEditDialog from './TinhluongEditDialog';

const TinhluongDetail = ({ ...rest }) => {
  const dispatch = useDispatch()
  const { items, total, filter, tongDoanhThuTamTinh, tongDoanhThuThucTe } = useSelector(state => state.tinhluong);
  const { role } = useSelector(state => state.profile);

  const [limit, setLimit] = useState(filter.limit || DEFAULT_ROW_PER_PAGE);
  const [page, setPage] = useState(filter.page || 0);
  const [updateUserValue, setUpdateUserValue] = useState({});
  const [edit, setEdit] = useState(false);
  const [selected, setSelected] = useState({});

  useEffect(() => {
    const payload = {
      ...filter,
      limit: limit,
      page: page,
    };
    
    dispatch(TinhluongAction.updateFilter(payload));

    dispatch(TinhluongAction.list());
  }, [page, limit])

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // const handleUserChange = (event, username, track0, track1) => {
  //   if (event.key === 'Enter') {
  //     username = username.trim();
  //     if (username != '') {
  //       setUpdateUserValue({
  //         ...updateUserValue,
  //         [track0 + '_' + track1]: username,
  //       });
  //       dispatch(TinhluongAction.updateUser(username, track0, track1));
  //     }
  //   }
  // };

  const handleUserChange = (event, username, track0, track1) => {
    username = username.trim();
    if (username != '') {
      setUpdateUserValue({
        ...updateUserValue,
        [track0 + '_' + track1]: username,
      });
      dispatch(TinhluongAction.updateUser(username, track0, track1));
    }
  };

  const handleEditOpen = (tinhluong) => {
    dispatch(TinhluongAction.selectedTinhLuong(tinhluong));
    setEdit(true);
  };

  return (
    <Card {...rest} style={{ width: "100%"}}>
      <PerfectScrollbar>
        <div style={{ overflowX: 'auto' }}>
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    #
                  </TableCell>
                  <TableCell>
                    Username NV
                  </TableCell>
                  <TableCell>
                    Tên NV
                  </TableCell>
                  <TableCell>
                    Ngày công thực tế
                  </TableCell>
                  <TableCell>
                    Mức lương CB
                  </TableCell>
                  <TableCell>
                    Lương theo ngày công
                  </TableCell>
                  <TableCell>
                    Lương học việc 
                  </TableCell>
                  <TableCell>
                    Chuyên cần 
                  </TableCell>
                  <TableCell>
                    Tổng lương cơ bản (1)
                  </TableCell>
                  <TableCell>
                    Tổng doanh thu VAS tính lương 
                  </TableCell>
                  <TableCell>
                    Tổng doanh thu giá gốc
                  </TableCell>
                  <TableCell>
                    BQ doanh thu /ngày 
                  </TableCell>
                  <TableCell>
                    Mức % hoa hồng
                  </TableCell>
                  <TableCell>
                    Hoa hồng thực tính (2)
                  </TableCell>
                  <TableCell>
                    HH dịch vụ 
                  </TableCell>
                  <TableCell>
                    HH sim 
                  </TableCell>
                  <TableCell>
                    Thi đua tháng
                  </TableCell>
                  <TableCell>
                    Thưởng KK 
                  </TableCell>
                  <TableCell>
                    Thưởng lễ/ Tết
                  </TableCell>
                  <TableCell>
                    PCTN/ Hỗ trợ BHXH 
                  </TableCell>
                  <TableCell>
                    Nhận khác 
                  </TableCell>
                  <TableCell>
                    Tổng thưởng/ PC khác (3)
                  </TableCell>
                  <TableCell>
                    Tổng lương tháng
                  </TableCell>
                  <TableCell>
                    BHXH
                  </TableCell>
                  <TableCell>
                    Phí công đoàn (1% - Max150k)
                  </TableCell>
                  <TableCell>
                    Chế tài 
                  </TableCell>
                  <TableCell>
                    Truy thu đã trừ ck
                  </TableCell>
                  <TableCell>
                    Ký quỹ/ Tạm ứng
                  </TableCell>
                  <TableCell>
                    Tổng khấu trừ (4)
                  </TableCell>
                  <TableCell>
                    Lương thực nhận
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.slice(page * limit, (page + 1) * limit).map((tinhluong, index) => (
                  <TableRow
                    hover
                    key={tinhluong.username}
                  >
                    <TableCell>
                      {page * limit + index + 1}
                    </TableCell>
                    <TableCell>
                      {role.priority < 4 && <span style={{color: 'blue', cursor: 'pointer'}} onClick={() => handleEditOpen(tinhluong)}>
                        {tinhluong.username}
                      </span>}
                      {role.priority > 3 && tinhluong.username}
                    </TableCell>
                    <TableCell>
                      {tinhluong.fullname}
                    </TableCell>
                    <TableCell>
                      {numeral(tinhluong.ngayCongThucTe).format('0,0.0')}
                    </TableCell>
                    <TableCell>
                      {numeral(tinhluong.luongCoBan).format('0,0')}
                    </TableCell>
                    <TableCell>
                      {numeral(tinhluong.luongTheoNgayCong).format('0,0')}
                    </TableCell>
                    <TableCell>
                      {numeral(tinhluong.luongHocViec).format('0,0')}
                    </TableCell>
                    <TableCell>
                      {numeral(tinhluong.chuyenCan).format('0,0')}
                    </TableCell>
                    <TableCell>
                      {numeral(tinhluong.tongLuongCoBan).format('0,0')}
                    </TableCell>
                    <TableCell>
                      {numeral(tinhluong.tongDoanhThuVasTinhLuong).format('0,0')}
                    </TableCell>
                    <TableCell>
                      {numeral(tinhluong.tongDoanhThuGiaGoc).format('0,0')}
                    </TableCell>
                    <TableCell>
                      {numeral(tinhluong.binhQuanDoanhThuTheoNgay).format('0,0')}
                    </TableCell>
                    <TableCell>
                      {numeral(tinhluong.hoaHongPhanTram).format('0,0.0')}%
                    </TableCell>
                    <TableCell>
                      {numeral(tinhluong.hoaHongThucTinh).format('0,0')}
                    </TableCell>
                    <TableCell>
                      {numeral(tinhluong.hoaHongDichVu).format('0,0')}
                    </TableCell>
                    <TableCell>
                      {numeral(tinhluong.hoaHongSim).format('0,0')}
                    </TableCell>
                    <TableCell>
                      {numeral(tinhluong.thiDuaThang).format('0,0')}
                    </TableCell>
                    <TableCell>
                      {numeral(tinhluong.thuongKhuyenKhich).format('0,0')}
                    </TableCell>
                    <TableCell>
                      {numeral(tinhluong.thuongLeTet).format('0,0')}
                    </TableCell>
                    <TableCell>
                      {numeral(tinhluong.hoTroBaoHiemXaHoi).format('0,0')}
                    </TableCell>
                    <TableCell>
                      {numeral(tinhluong.nhanKhac).format('0,0')}
                    </TableCell>
                    <TableCell>
                      {numeral(tinhluong.tongThuongPhuCap).format('0,0')}
                    </TableCell>
                    <TableCell>
                      {numeral(tinhluong.tongLuongThang).format('0,0')}
                    </TableCell>
                    <TableCell>
                      {numeral(tinhluong.baoHiemXaHoi).format('0,0')}
                    </TableCell>
                    <TableCell>
                      {numeral(tinhluong.phiCongDoan).format('0,0')}
                    </TableCell>
                    <TableCell>
                      {numeral(tinhluong.tongCheTai).format('0,0')}
                    </TableCell>
                    <TableCell>
                      {numeral(tinhluong.truyThuSauChietKhau).format('0,0')}
                    </TableCell>
                    <TableCell>
                      {numeral(tinhluong.kyQuyTamUng).format('0,0')}
                    </TableCell>
                    <TableCell>
                      {numeral(tinhluong.tongKhauTru).format('0,0')}
                    </TableCell>
                    <TableCell>
                      {numeral(tinhluong.luongThucNhan).format('0,0')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </div>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      />
      <TinhluongEditDialog open={edit} tinhluong={selected} handleClose={() => setEdit(false)} />
    </Card>
  );
};

export default TinhluongDetail;
