export const CustomerStatus = [
    {
        name: "Bình thường",
        code: "NORMAL"
    },
    {
        name: "Đã giao",
        code: "ASSIGNED"
    },
    {
        name: "Đã chặn",
        code: "BLOCKED"
    },
    {
        name: "Chăm sóc",
        code: "TAKECARE"
    },
    {
        name: "Xác nhận",
        code: "APPROVED"
    },
    {
        name: "Đã đăng ký",
        code: "REGISTERED"
    },
]

export const SubscriberType = [
    {
        name: "Trả trước",
        code: "TRATRUOC"
    }, {
        name: "Trả sau",
        code: "TRASAU"
    },
]

export const RequestType = [
    {
        name: "Hoàn trước",
        code: "HOANTRUOC"
    }, {
        name: "Hoàn sau",
        code: "HOANSAU"
    },
    {
        name: "Không hoàn",
        code: "KHONGHOAN"
    },
    {
        name: "Hoàn thêm",
        code: "HOANTHEM"
    },
]

export const RequestMethodType = [
    {
        name: "Nâng cấp",
        code: "NANGCAP"
    }, {
        name: "Gia hạn",
        code: "GIAHAN"
    }
]


export const InvitationStatus = [
    {
        name: "Thành công",
        code: "SUCCESS"
    }, {
        name: "Thất bại",
        code: "FAILD"
    }
]

export const CustomerCareStatus = [
    {
        name: "",
        code: ""
    },
    {
        name: "Đồng ý",
        code: "APPROVED"
    },
    {
        name: "Không đồng ý",
        code: "REJECTED"
    },
    {
        name: "Hẹn lại",
        code: "APPOINTMENT"
    },
    {
        name: "Không bắt máy",
        code: "KBM"
    },
    {
        name: "Không liên lạc được",
        code: "KLLĐ"
    },
    {
        name: "Dùng lại gói cũ",
        code: "KEEP"
    },
    {
        name: "Đã đăng ký lại",
        code: "RE-REGISTER"
    },
    // {
    //     name: "Mời lại hôm sau",
    //     code: "MOI_LAI_HOM_SAU"
    // },
]

export const SellingStates = [
    {
        name: "Không",
        code: "NONE"
    },
    {
        name: "Thành công",
        code: "SUCCESS"
    },
    {
        name: "Thất bại",
        code: "FAILD"
    },
]

export const CustomerTypes = [
    {
        name: "Trả trước",
        code: "NORMAL"
    },
    {
        name: "Trả sau",
        code: "ASSIGNED"
    },
]

export const EMPTY_ID = "00000000-0000-0000-0000-000000000000";

export const InviteTypes = {
    GET_OTP: 'GET_OTP',
    CONFIRM_OTP: 'CONFIRM_OTP',
    GET_OTPnew: 'GET_OTPnew',
    CONFIRM_OTPnew: 'CONFIRM_OTPnew',
    GET_OTPnew1: 'GET_OTPnew1',
    CONFIRM_OTPnew1: 'CONFIRM_OTPnew1',
    GET_OTPnew2: 'GET_OTPnew2',
    CONFIRM_OTPnew2: 'CONFIRM_OTPnew2',
    RENEW: 'RENEW',
    IMMEDIATE: 'IMMEDIATE',
    SELLING: 'SELLING',
}

export const CONFIRM_TYPES = {
    DELETE: 'delete',
    LOCK: 'lock',
    UNLOCK: 'unlock'
}

export const NOTI_TYPES = {
    ERROR: 'error',
    SUCCESS: 'success'
}
export const ROW_PER_PAGE_OPTIONS = [20, 50, 100];

export const DEFAULT_ROW_PER_PAGE = 20;

export const DEFAULT_PAGE = 1