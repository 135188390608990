import { Report90849332Service } from 'src/services/report90849332.service';
import { showToast } from 'src/utils/common';
import {
    DEFAULT_ROW_PER_PAGE,
  } from 'src/utils/constants';

import { AppAction } from './app.action';

export const REPORT90849332_UPDATE_FILTER = 'REPORT90849332:REPORT90849332_UPDATE_FILTER'

export const REPORT90849332_NEW_CARE_SUCCESS = 'REPORT90849332:NEW_CARE_SUCCESS'
export const REPORT90849332_LIST_SUCCESS = 'REPORT90849332:FIND_ALL_SUCCESS'
export const REPORT90849332_LIST_FAILD = 'REPORT90849332:FIND_ALL_FAILD'
export const REPORT90849332_COUNT_ASSIGN_SUCCESS = 'REPORT90849332:COUNT_ASSIGN_SUCCESS'
export const REPORT90849332_COUNT_ASSIGN_FAILD = 'REPORT90849332:COUNT_ASSIGN_FAILD'

export const REPORT90849332_CREATE_SUCCESS = 'REPORT90849332:CREATE_SUCCESS'
export const REPORT90849332_CREATE_FAILD = 'REPORT90849332:CREATE_FAILD'

export const REPORT90849332_IMPORT_SUCCESS = 'REPORT90849332:IMPORT_SUCCESS'
export const REPORT90849332_SAVE_SUCCESS = 'REPORT90849332:SAVE_SUCCESS'
export const REPORT90849332_SAVE_FAILD = 'REPORT90849332:SAVE_SUCCESS'

export const REPORT90849332_DEL_SUCCESS = 'REPORT90849332:DEL_SUCCESS'
export const REPORT90849332_DEL_FAILD = 'REPORT90849332:DEL_FAILD'


export const REPORT90849332_HISTORY_LIST_SUCCESS = 'REPORT90849332:HISTORY_FIND_ALL_SUCCESS'
export const REPORT90849332_HISTORY_LIST_FAILD = 'REPORT90849332:HISTORY_FIND_ALL_FAILD'

export const REPORT90849332_LIST_EXPORT_SUCCESS = 'REPORT90849332:FIND_ALL_EXPORT_SUCCESS'
export const REPORT90849332_LIST_EXPORT_FAILD = 'REPORT90849332:FIND_ALL_EXPORT_FAILD'

export const Report90849332Action = {
    list,
    listExport,
    findAssign,
    create,
    updateFilter,
    importData,
    saveData,
    update,
    del,
    assign,
    countAssign,
    history,
    updateUser,
}


function countAssign() {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        Report90849332Service.countAssign().then((rs) => {
            dispatch(success(rs.data.total));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: REPORT90849332_COUNT_ASSIGN_SUCCESS, data } }
    function failure(error) { return { type: REPORT90849332_COUNT_ASSIGN_FAILD, error } }
}

function findAssign(page = 0, count = 20) {
    return (dispatch, getState) => {
        const { report90849332 } = getState()
        dispatch(AppAction.appLoading(true));
        Report90849332Service.findAssign(page, count).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...report90849332.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: REPORT90849332_LIST_SUCCESS, data } }
    function failure(error) { return { type: REPORT90849332_LIST_FAILD, error } }
}

function list() {
    return (dispatch, getState) => {
        const { report90849332 } = getState();
        dispatch(AppAction.appLoading(true));

        const { filter } = report90849332;
        
        Report90849332Service.findAll(filter.page, filter.limit, filter).then((rs) => {
            if (!!filter && filter.page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count,
                    tongDoanhThu: rs.data.tongDoanhThu,
                    tongGoi: rs.data.tongGoi
                }));
            } else {
                dispatch(success({
                    items: [...report90849332.items, ...rs.data.values],
                    total: rs.data.count,
                    tongDoanhThu: rs.data.tongDoanhThu,
                    tongGoi: rs.data.tongGoi
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: REPORT90849332_LIST_SUCCESS, data } }
    function failure(error) { return { type: REPORT90849332_LIST_FAILD, error } }
}

function listExport() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const { report90849332 } = getState();

            const { filter } = report90849332;

            // Perform your asynchronous operation (e.g., API call)
            Report90849332Service.listExport(filter)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    }
    function success(data) { return { type: REPORT90849332_LIST_EXPORT_SUCCESS, data } }
    function failure(error) { return { type: REPORT90849332_LIST_EXPORT_FAILD, error } }
}

function updateFilter(payload) {
    return { type: REPORT90849332_UPDATE_FILTER, data: payload }
}

function importData(payload) {
    return { type: REPORT90849332_IMPORT_SUCCESS, data: payload }
}

function saveData(payload) {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        let count = 0
        for (let i = 0; i < payload.length; i += 200) {
            // eslint-disable-next-line no-loop-func
            Report90849332Service.save(payload.slice(i, i + 200)).then((rs) => {
                count += parseInt(rs.data.saved, 10)
                if (count === payload.length) {
                    dispatch(success(rs));
                    dispatch(Report90849332Action.list())
                    dispatch(AppAction.appLoading(false));
                }
            }).catch((error) => {
                dispatch(failure(error));
                dispatch(AppAction.appLoading(false));
            })
        }
    };

    function success(data) { return { type: REPORT90849332_SAVE_SUCCESS, data } }
    function failure(error) { return { type: REPORT90849332_SAVE_FAILD, error } }
}

function update(payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        Report90849332Service.create(payload).then((rs) => {
            dispatch(success(rs));
            dispatch(Report90849332Action.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: REPORT90849332_CREATE_SUCCESS, data } }
    function failure(error) { return { type: REPORT90849332_CREATE_FAILD, error } }
}

function updateUser(username, track0, track1) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        Report90849332Service.updateUser(username, track0, track1).then((rs) => {
            dispatch(success(rs));
            dispatch(Report90849332Action.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: REPORT90849332_CREATE_SUCCESS, data } }
    function failure(error) { return { type: REPORT90849332_CREATE_FAILD, error } }
}

function create(payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        Report90849332Service.create(payload).then((rs) => {
            dispatch(success(rs));
            dispatch(Report90849332Action.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: REPORT90849332_CREATE_SUCCESS, data } }
    function failure(error) { return { type: REPORT90849332_CREATE_FAILD, error } }
}

function del(id) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        Report90849332Service.delete(id).then((rs) => {
            dispatch(success(rs));
            dispatch(Report90849332Action.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: REPORT90849332_DEL_SUCCESS, data } }
    function failure(error) { return { type: REPORT90849332_DEL_FAILD, error } }
}


function assign(payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        Report90849332Service.assign(payload).then((rs) => {
            showToast('success', "Phân chia dữ liệu thành công.")
            dispatch(Report90849332Action.list())
        }).catch((error) => {
            showToast('error', "Phân chia dữ liệu thất bại.\nVui lòng thử lại lần sau.")
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
}


function history(payload) {
    return (dispatch, getState) => {
        const { report90849332 } = getState()
        dispatch(AppAction.appLoading(true));
        Report90849332Service.history(payload).then((rs) => {
            if (payload.page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...report90849332.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: REPORT90849332_HISTORY_LIST_SUCCESS, data } }
    function failure(error) { return { type: REPORT90849332_HISTORY_LIST_FAILD, error } }
}