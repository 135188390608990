import { SettingService } from 'src/services/setting.service';
import { showToast } from 'src/utils/common';
import { NOTI_TYPES } from 'src/utils/constants';

import { AppAction } from './app.action';

export const SETTING_NEW_CARE_SUCCESS = 'SETTING:NEW_CARE_SUCCESS'
export const SETTING_LIST_SUCCESS = 'SETTING:FIND_ALL_SUCCESS'
export const SETTING_LIST_FAILD = 'SETTING:FIND_ALL_FAILD'
export const SETTING_COUNT_ASSIGN_SUCCESS = 'SETTING:COUNT_ASSIGN_SUCCESS'
export const SETTING_COUNT_ASSIGN_FAILD = 'SETTING:COUNT_ASSIGN_FAILD'

export const SETTING_CREATE_SUCCESS = 'SETTING:CREATE_SUCCESS'
export const SETTING_CREATE_FAILD = 'SETTING:CREATE_FAILD'

export const SETTING_IMPORT_SUCCESS = 'SETTING:IMPORT_SUCCESS'
export const SETTING_SAVE_SUCCESS = 'SETTING:SAVE_SUCCESS'
export const SETTING_SAVE_FAILD = 'SETTING:SAVE_SUCCESS'

export const SETTING_DEL_SUCCESS = 'SETTING:DEL_SUCCESS'
export const SETTING_DEL_FAILD = 'SETTING:DEL_FAILD'

export const SETTING_ONE_SUCCESS = 'SETTING:FIND_ONE_SUCCESS'
export const SETTING_ONE_FAILD = 'SETTING:FIND_ONE_FAILD'

export const SETTING_HISTORY_LIST_SUCCESS = 'SETTING:HISTORY_FIND_ALL_SUCCESS'
export const SETTING_HISTORY_LIST_FAILD = 'SETTING:HISTORY_FIND_ALL_FAILD'

export const SettingAction = {
    create,
    update,
    findByKey,
}

function create(payload) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(AppAction.appLoading(true));
            SettingService.create(payload).then((response) => {
                dispatch(success(response));
                resolve(response);
            }).catch((error) => {
                dispatch(failure(error));
                reject(error);
            }).finally(() => {
                dispatch(AppAction.appLoading(false));
            })
        })
    };
    function success(data) { return { type: SETTING_CREATE_SUCCESS, data } }
    function failure(error) { return { type: SETTING_CREATE_FAILD, error } }
}

function update(key, payload) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(AppAction.appLoading(true));
            SettingService.update(key, payload).then((response) => {
                dispatch(success(response));
                resolve(response);
            }).catch((error) => {
                dispatch(failure(error));
                reject(error);
            }).finally(() => {
                dispatch(AppAction.appLoading(false));
            })
        })
    };
    function success(data) { return { type: SETTING_CREATE_SUCCESS, data } }
    function failure(error) { return { type: SETTING_CREATE_FAILD, error } }
}

function findByKey(key) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(AppAction.appLoading(true));
            SettingService.findByKey(key).then((response) => {
                dispatch(success(response.data));
                resolve(response);
            }).catch((error) => {
                dispatch(failure(error));
                reject(error);
            }).finally(() => {
                dispatch(AppAction.appLoading(false));
            })
        })
    };
    function success(data) { return { type: SETTING_ONE_SUCCESS, data } }
    function failure(error) { return { type: SETTING_ONE_FAILD, error } }
}