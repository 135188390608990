import { toQuery } from 'src/utils/utils';

import { handleResponse } from '../utils/auth-header';
import axios, { axiosOption } from '../utils/axios';

export const RequestrefundreportService = {

  createHoanTienGoiCuoc: (payload) => {
    return axios.post('/api/v1/requestrefundreport/createHoanTienGoiCuoc', payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  createNapTienChietKhau: (payload) => {
    return axios.post('/api/v1/requestrefundreport/createNapTienChietKhau', payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  create24hOrder: (payload) => {
    return axios.post('/api/v1/requestrefundreport/create24hOrder', payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  save: (payload) => {
    return axios.post('/api/v1/requestrefundreport/import', payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  update: (id, payload) => {
    return axios.put(`/api/v1/requestrefundreport/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  approve: (id, payload) => {
    return axios.put(`/api/v1/requestrefundreport/approve/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  setTrangThaiTruyThuHoanTheoGoi: (id, payload) => {
    return axios.put(`/api/v1/requestrefundreport/setTrangThaiTruyThuHoanTheoGoi/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  setTrangThaiTruyThuHoanThem: (id, payload) => {
    return axios.put(`/api/v1/requestrefundreport/setTrangThaiTruyThuHoanThem/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  setTransferStatus: (id, payload) => {
    return axios.put(`/api/v1/requestrefundreport/setTransferStatus/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  setTrangThaiGiaoDich: (id, payload) => {
    return axios.put(`/api/v1/requestrefundreport/setTrangThaiGiaoDich/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  xuatdstruythu: (page, count, payload) => {
    let url = `/api/v1/requestrefundreport/xuatdstruythu?page=${page}&count=${count}`;

    if (payload.is_transfer) {
      url += `&is_transfer=${payload.is_transfer}`
    };

    if (payload.trang_thai_giao_dich_doi_tac) {
      url += `&trang_thai_giao_dich_doi_tac=${payload.trang_thai_giao_dich_doi_tac}`
    };

    if (payload.trang_thai_truy_thu_hoan_theo_goi) {
      url += `&trang_thai_truy_thu_hoan_theo_goi=${payload.trang_thai_truy_thu_hoan_theo_goi}`
    };

    if (payload.trang_thai_truy_thu_hoan_them) {
      url += `&trang_thai_truy_thu_hoan_them=${payload.trang_thai_truy_thu_hoan_them}`
    };

    if (payload.don_hoan_hoac_nap) {
      url += `&don_hoan_hoac_nap=${payload.don_hoan_hoac_nap}`
    };

    if (payload.message) {
      url += `&message=${payload.message}`
    };

    if (payload.username) {
      url += `&username=${payload.username}`
    };

    if (payload.startDate && payload.endDate) {
      url += `&startDate=${payload.startDate}&endDate=${payload.endDate}`
    }
    
    return axios.get(url, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  findAll: (page, count, payload) => {
    let url = `/api/v1/requestrefundreport?page=${page}&count=${count}`;

    if (payload.is_transfer) {
      url += `&is_transfer=${payload.is_transfer}`
    };

    if (payload.trang_thai_giao_dich_doi_tac) {
      url += `&trang_thai_giao_dich_doi_tac=${payload.trang_thai_giao_dich_doi_tac}`
    };

    if (payload.trang_thai_truy_thu_hoan_theo_goi) {
      url += `&trang_thai_truy_thu_hoan_theo_goi=${payload.trang_thai_truy_thu_hoan_theo_goi}`
    };

    if (payload.trang_thai_truy_thu_hoan_them) {
      url += `&trang_thai_truy_thu_hoan_them=${payload.trang_thai_truy_thu_hoan_them}`
    };

    if (payload.don_hoan_hoac_nap) {
      url += `&don_hoan_hoac_nap=${payload.don_hoan_hoac_nap}`
    };

    if (payload.message) {
      url += `&message=${payload.message}`
    };

    if (payload.username) {
      url += `&username=${payload.username}`
    };

    if (payload.startDate && payload.endDate) {
      url += `&startDate=${payload.startDate}&endDate=${payload.endDate}`
    }
    
    return axios.get(url, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  findAssign: (page, count) => {
    return axios.get(`/api/v1/requestrefundreport/assign?page=${page}&count=${count}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  find: (id) => {
    return axios.get(`/api/v1/requestrefundreport/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  findPackage: (ten_goi_cuoc) => {
    return axios.get(`/api/v1/requestrefundreport/findPackage/${ten_goi_cuoc}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  countAssign: () => {
    return axios.get(`/api/v1/requestrefundreport/count-assign`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  delete: (id) => {
    return axios.delete(`/api/v1/requestrefundreport/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  assign: (payload) => {
    return axios.post(`/api/v1/requestrefundreport/assign`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  history: (payload) => {
    return axios.get(`/api/v1/care/history?${toQuery(payload)}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  }
}