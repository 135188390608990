import {
  useEffect,
  useState,
} from 'react';

import PropTypes from 'prop-types';
import {
  BarChart2 as BarChart2Icon,
  Briefcase as BriefcaseIcon,
  CheckSquare as CheckSquareIcon,
  Command as CommandIcon,
  DollarSign as DollarSignIcon,
  Home as HomeIcon,
  List as ListIcon,
  MessageSquare as MessageSquareIcon,
  PhoneCall as PhoneCallIcon,
  PieChart as PieChartIcon,
  Search as SearchIcon,
  Send as SendIcon,
  Settings as SettingsIcon,
  Shield as ShieldIcon,
  Upload as UploadIcon,
  User as UserIcon,
  UserPlus as UserPlusIcon,
} from 'react-feather';
import { useSelector } from 'react-redux';
import {
  Link as RouterLink,
  useLocation,
} from 'react-router-dom';
import { isGrantPermission } from 'src/utils/utils';

import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
} from '@material-ui/core';

import NavItem from './NavItem';

import { emitter } from 'src/utils/emitter';

const workspaceItems = [
  {
    href: '/app/customer-care',
    icon: UserPlusIcon,
    title: 'Khách hàng mới',
    component: "CustomerCareList",
    permissions: ['customer.get', 'customer.care']
  },
  {
    href: '/app/khr',
    icon: UserPlusIcon,
    title: 'Khách hàng riêng',
    component: "Khr",
    permissions: ['khr']
  },
  {
    href: '/app/mfy',
    icon: UserPlusIcon,
    title: 'Mfy',
    component: "Mfy",
    permissions: ['mfy']
  },
  {
    href: '/app/blacklist',
    icon: UserPlusIcon,
    title: 'Blacklist',
    component: "Blacklist",
    permissions: ['blacklist']
  },
  {
    href: '/app/baodo',
    icon: UserPlusIcon,
    title: 'Báo đỏ',
    component: "Baodo",
    permissions: ['baodo']
  },
  // {
  //   href: '/app/moigoihomsau',
  //   icon: UserPlusIcon,
  //   title: 'Mời gói hôm sau',
  //   permissions: ['moigoihomsau']
  // },
  {
    href: '/app/care-history',
    icon: PhoneCallIcon,
    title: 'Nhật ký CSKH',
    component: "CustomerCareHistoryList",
    permissions: ['customer.care']
  },
  {
    href: '/app/tilebatso',
    icon: PhoneCallIcon,
    title: 'Tỉ lệ bắt số',
    component: "TilebatsoList",
    permissions: ['tilebatso']
  },
  {
    href: '/app/customer-lookup',
    icon: SearchIcon,
    title: 'Tra cứu thuê bao',
    component: "CustomerLookup",
    permissions: ['lookup.balance', 'lookup.package', 'lookup.info']
  },
  {
    icon: MessageSquareIcon,
    title: 'Tra cứu tin nhắn',
    permissions: ['history.999', 'history.909', 'history.9084', 'history.9332'],
    children: [
      // {
      //   href: '/app/customer-message-909',
      //   icon: MessageSquareIcon,
      //   title: 'LS 909',
      //   permissions: ['history.909'],
      // },
      {
        href: '/app/customer-message-999',
        icon: MessageSquareIcon,
        title: 'Trung tâm tin nhắn',
        component: "CustomerMessage999",
        permissions: ['history.999'],
      },
      {
        href: '/app/customer-message-9084',
        icon: MessageSquareIcon,
        title: 'Kết quả đăng ký',
        component: "CustomerMessage9084",
        permissions: ['history.9084'],
      },
      // {
      //   href: '/app/customer-message-9332',
      //   icon: MessageSquareIcon,
      //   title: 'LS 9332',
      //   permissions: ['history.9332'],
      // },
    ]
  },
  {
    href: '/app/bank-lookup',
    icon: DollarSignIcon,
    title: 'Tra cứu ngân hàng',
    component: "BankLookup",
    permissions: ['lookup.bank']
  },
  {
    href: '/app/requestrefund',
    icon: DollarSignIcon,
    title: 'Yêu cầu hoàn tiền',
    component: "Requestrefund",
    permissions: ['requestrefund']
  },
  {
    href: '/app/invite',
    icon: SendIcon,
    title: 'Mời gói cước',
    component: "Invitation",
    permissions: ['care.otp', 'care.otpnew', 'care.otpnew1', 'care.9332', 'care.selling', 'care.dk']
  },
  // {
  //   href: '/app/approval',
  //   icon: CheckSquareIcon,
  //   title: 'Quản lý hoàn tiền',
  //   permissions: ['requet.approve.package-refund', 'requet.approve.charge-discount', 'requet.approve.charge-package']
  // },
  {
    href: '/app/tinhluong',
    icon: DollarSignIcon,
    title: 'Tính lương',
    component: "TinhluongList",
    permissions: ['tinhluong']
  },
  {
    icon: BarChart2Icon,
    title: 'Báo cáo',
    permissions: ['reportbranchrevenue', 'reportstaffrevenue', 'report90849332', 'requestrefundreport', 'dsdttinhluong', 'tinhluong'],
    children: [
      {
        href: '/app/reportbranchrevenue',
        icon: BarChart2Icon,
        title: 'Báo cáo doanh thu theo chi nhánh',
        component: "ReportbranchrevenueList",
        permissions: ['reportbranchrevenue']
      },
      {
        href: '/app/reportstaffrevenue',
        icon: BarChart2Icon,
        title: 'Báo cáo doanh thu theo nhân viên',
        component: "ReportstaffrevenueList",
        permissions: ['reportstaffrevenue']
      },
      {
        href: '/app/report90849332',
        icon: BarChart2Icon,
        title: 'Báo cáo doanh thu chi tiết',
        component: "Report90849332List", component: "Report90849332List",
        permissions: ['report90849332']
      },
      {
        href: '/app/requestrefundreport',
        icon: BarChart2Icon,
        title: 'Báo cáo yêu cầu hoàn tiền',
        component: "Requestrefundreport",
        permissions: ['requestrefundreport']
      },
      {
        href: '/app/dsdttinhluong',
        icon: BarChart2Icon,
        title: 'Đối soát doanh thu tính lương',
        component: "DsdttinhluongList",
        permissions: ['dsdttinhluong']
      },
    ]
  },
];

const systemItems = [
  {
    href: '/sys/accounts',
    icon: UserIcon,
    title: 'Quản lý nhân viên',
    component: "AccountList",
    permissions: ['user.get', 'user.edit', 'user.add', 'user.delete']
  },
  {
    href: '/sys/lstracuu',
    icon: UserIcon,
    title: 'Lịch sử tra cứu',
    component: "LstracuuLookup",
    permissions: ['lstracuu']
  },
  // {
  //   icon: ListIcon,
  //   title: 'QL khách hàng',
  //   permissions: ['customer.add', 'customer.scan'],
  //   children: [
  //     {
  //       href: '/sys/customers',
  //       icon: BriefcaseIcon,
  //       title: 'Khách hàng',
  //       permissions: ['customer.add', 'customer.scan', 'customer.edit'],
  //     },
  //     {
  //       href: '/sys/upload',
  //       icon: UploadIcon,
  //       title: 'Upload',
  //       permissions: ['customer.add'],
  //     },
  //     {
  //       href: '/sys/division',
  //       icon: PieChartIcon,
  //       title: 'Chia data',
  //       permissions: ['customer.edit'],
  //     },
  //   ]
  // },
  // {
  //   href: '/sys/permissions',
  //   icon: CommandIcon,
  //   title: 'Danh sách quyền',
  //   permissions: ['permission.get']
  // },
  {
    href: '/sys/roles',
    icon: ShieldIcon,
    title: 'Phân quyền theo vai trò',
    component: "RoleList",
    permissions: ['role.get', 'role.edit', 'role.add', 'role.delete']
  },
  {
    href: '/sys/giahoantien',
    icon: DollarSignIcon,
    title: 'Giá hoàn tiền',
    component: "Giahoantien",
    permissions: ['giahoantien',]
  },
  {
    href: '/sys/giagoimfy',
    icon: DollarSignIcon,
    title: 'Giá gói mfy',
    component: "Giagoimfy",
    permissions: ['giagoimfy',]
  },
  {
    href: '/sys/systemaccount',
    icon: UserIcon,
    title: 'Tài khoản hệ thống',
    component: "Systemaccount",
    permissions: ['systemaccount',]
  },
  {
    href: '/sys/quetdata',
    icon: CommandIcon,
    title: 'Quét data',
    component: "Quetdata",
    permissions: ['quetdata',]
  },
  {
    href: '/sys/quetdatakhonggoi',
    icon: CommandIcon,
    title: 'Quét data không gói',
    component: "Quetdatakhonggoi",
    permissions: ['quetdatakhonggoi',]
  },
  {
    href: '/sys/quetdtct',
    icon: CommandIcon,
    title: 'Quét đối tượng chương trình',
    component: "Quetdtct",
    permissions: ['quetdtct',]
  },
  {
    href: '/sys/uploadchiadata',
    icon: CommandIcon,
    title: 'Upload chia data',
    component: "Uploadchiadata",
    permissions: ['uploadchiadata',]
  },
  {
    href: '/sys/phanquyendata',
    icon: CommandIcon,
    title: 'Phân quyền data',
    component: "Phanquyendata",
    permissions: ['phanquyendata',]
  },
  {
    href: '/sys/chiadata',
    icon: CommandIcon,
    title: 'Chia data',
    component: "Chiadata",
    permissions: ['chiadata',]
  },
  {
    href: '/sys/settings',
    icon: SettingsIcon,
    title: 'Cài đặt',
    permissions: ['settings']
  },
];

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();

  const [upload, setUpload] = useState(false);

  const handleUploadClick = () => {
    handleUploadClick(!upload);
  };

  const { info, permissions, role } = useSelector(state => state.profile)
  useEffect(() => {
    //MENU_CUSTOM
    if (openMobile && onMobileClose) {
      //onMobileClose();
    }
  }, [location.pathname]);

  const addTab = (component = null) => {
    if (component != null) {
      emitter.emit("add_tab", component)
    }
  }

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        <Avatar
          component={RouterLink}
          src={info.avatar || '/static/images/avatars/avatar_default.png'}
          sx={{
            cursor: 'pointer',
            width: 64,
            height: 64
          }}
          to="/sys/profile"
        />
        <Typography
          color="textPrimary"
          variant="h5"
        >
          {info.fullname}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {role.name}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <NavItem
          //href={"/app/dashboard"}
          onClick={() => addTab('Dashboard')}
          key={"Trang chủ"}
          title={"Trang chủ"}
          icon={HomeIcon}
        />
        <Typography
          color="textPrimary"
          variant="h6"
        >
          Không gian làm việc
        </Typography>
        <List>
          {workspaceItems.map((item) => (!info.username.trim().startsWith('HNIMB') || info.username.trim().startsWith('HNIMB') && item.href != '/app/requestrefund' && item.href != '/app/requestrefundreport') && isGrantPermission(permissions, item.permissions) && (
            <NavItem
              href={typeof item.component !== 'undefined' ? '#' : item.href}
              onClick={() => addTab(typeof item.component !== 'undefined' ? item.component : null)}
              key={item.title}
              title={item.title}
              icon={item.icon}
              children={item.children}
            />
          ))}
        </List>
      </Box>
      {role.priority < 5 && <>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="h6"
          >
            Quản trị
          </Typography>
          <List>
            {systemItems.map((item) => isGrantPermission(permissions, item.permissions) && (
              <NavItem
                href={typeof item.component !== 'undefined' ? '#' : item.href}
                onClick={() => addTab(typeof item.component !== 'undefined' ? item.component : null)}
                key={item.title}
                title={item.title}
                icon={item.icon}
                children={item.children}
              />
            ))}
          </List>
        </Box>
      </>}

    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      {/* MENU_CUSTOM */}
      <Hidden xlDown>
        <Drawer
          anchor="left"
          onClose={onMobileClose} //đồng bộ với lgUp
          open={openMobile} //đồng bộ với lgUp
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {
  },
  openMobile: false
};

export default DashboardSidebar;
