import React from 'react';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { AliveScope } from 'react-activation';

import App from './App';
import {
  persistor,
  store,
} from './store/configureStore';

const root = createRoot(document.getElementById("root"));

root.render(
  <AliveScope>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </AliveScope>
);