import {
  useEffect,
  useState,
} from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { CustomerAction } from 'src/actions/customer.action';
import { ProfileAction } from 'src/actions/profile.action';
import { showToast } from 'src/utils/common';
import { NOTI_TYPES } from 'src/utils/constants';

import {
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';

const DivisionListResults = (props) => {

  const [assigns, setAssigns] = useState({});
  const dispatch = useDispatch()
  const [members, setMember] = useState([])
  const [children, setChildren] = useState([])
  const { leaders, brands, saleman } = useSelector(state => state.profile)
  const { selected } = useSelector(state => state.division)

  const handleChangeAssign = (event) => {
    setAssigns({
      ...assigns,
      [event.target.name]: event.target.value
    })
    console.log("assigns:", assigns)
  }

  const handleAssign = () => {
    if (!selected) {
      showToast(NOTI_TYPES.ERROR, "Chưa chọn data chia")
      return;
    }
    let dividedAssign = Object.keys(assigns).map((key) => ({
      user: key,
      count: assigns[key],
      upload: selected.id
    }));
    dispatch(CustomerAction.assign(dividedAssign))
  }

  useEffect(() => {
    if (brands && brands.length > 0) {
      setMember(brands)
    }
  }, [brands])

  useEffect(() => {
    if (saleman && saleman.length > 0) {
      setChildren(saleman)
    }
  }, [saleman])

  useEffect(() => {
    if (leaders && leaders.length > 0) {
      setChildren(leaders)
    }
  }, [leaders])

  const handleGetLeader = (item) => {
    dispatch(ProfileAction.listLeader(item.id))
  }

  const handleGetSaleman = (item) => {
    dispatch(ProfileAction.listSaleman({ brand: item.id }))
  }

  useEffect(() => {
    dispatch(ProfileAction.listBrand())
  }, [])


  const renderChildren = (parent) => {
    return (
      children && children.filter((child) => child.brand.id === parent.id).map((item) => (
        <TableRow
          hover
          key={item.id}
        >
          <TableCell sx={{ pl: 8 }}>
            {item.fullname}
          </TableCell>
          <TableCell sx={{ pl: 8 }}>
            <TextField
              label="Số lượng"
              name={item.id}
              onChange={handleChangeAssign}
              type="number"
              defaultValue={0}
              value={assigns[item.id]}
              variant="outlined"
            />
          </TableCell>
          <TableCell >
          </TableCell>
        </TableRow>
      ))
    )
  }

  return (
    <Card>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Tên chi nhánh
                </TableCell>
                <TableCell>
                  Số lượng
                </TableCell>
                <TableCell>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {members && members.map((item) => [(
                <TableRow
                  hover
                  key={item.id}
                >
                  <TableCell>
                    {item.fullname}
                  </TableCell>
                  <TableCell>
                    <TextField
                      label="Số lượng"
                      name={item.id}
                      onChange={handleChangeAssign}
                      type="number"
                      defaultValue={0}
                      value={assigns[item.id]}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell >
                    <Button onClick={() => handleGetLeader(item)}>
                      DS Leader
                    </Button>|
                    <Button onClick={() => handleGetSaleman(item)}>
                      DS Saleman
                    </Button>
                  </TableCell>
                </TableRow>
              ), renderChildren(item)])}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Box sx={{
        display: 'flex',
        justifyContent: 'flex-end'
      }}>
        <Button
          color="primary"
          variant="contained"
          sx={{ m: 2 }}
          onClick={handleAssign}
        >
          Lưu
        </Button>
      </Box>

    </Card>
  );
};

export default DivisionListResults;
