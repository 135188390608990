import {
  useEffect,
  useState,
} from 'react';

import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { CustomerAction } from 'src/actions/customer.action';
import {
  CustomerCareStatus,
  DEFAULT_ROW_PER_PAGE,
  ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';

import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Button,
  MenuItem,
  TextField,
  Select as Select1,
} from '@material-ui/core';

import { showToast } from 'src/utils/common';

import {
  DateTimePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const CustomerCareHistoryListResults = ({ ...rest }) => {
  const dispatch = useDispatch()
  const { info, permissions, role } = useSelector(state => state.profile)
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const { history, totalHistory, filter } = useSelector(state => state.customer)
  const [limit, setLimit] = useState(filter.limit || DEFAULT_ROW_PER_PAGE);
  const [page, setPage] = useState(filter.page || 0);
  const [careUpdate, setCareUpdate] = useState([]);
  const [moiLai, setMoiLaiValue] = useState({});

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const payload = {
      ...filter,
      page: page,
      limit: limit,
    };

    dispatch(CustomerAction.updateFilter(payload));

    const getHistoryList = async () => {
      const list = await dispatch(CustomerAction.history());
      if (list && typeof list.data !== 'undefined' && list.data && typeof list.data.values !== 'undefined' && list.data.values) {
        let newItems = {}
        list.data.values.forEach((item, index) => {
          newItems[item.id] = 0;
        });
        setMoiLaiValue(newItems);
      }
    };

    getHistoryList()
  }, [page, limit])

  const chonMoiLai = (event) => {
    if (moiLai && Object.entries(moiLai).length > 0) {
      let newItems = {}
      Object.entries(moiLai).forEach(([key, value]) => {
        newItems[key] = 1;
      });
      setMoiLaiValue(newItems);
    }
  };

  const boChonMoiLai = (event) => {
    if (moiLai && Object.entries(moiLai).length > 0) {
      let newItems = {}
      Object.entries(moiLai).forEach(([key, value]) => {
        newItems[key] = 0;
      });
      setMoiLaiValue(newItems);
    }
  };

  const xacNhanMoiLai = async (event) => {
    let question = `Xác nhận chọn các số dưới đây để mời lại vào hôm sau?`;
    if (window.confirm(question)) {
      try {
        console.log(moiLai);
        const response = await dispatch(CustomerAction.xacNhanMoiLai(moiLai));
        if (typeof response.channel !== 'undefined') alert("[Thông báo từ '"+response.channel+"']\n"+response.message+(response.status != "Unknown" ? (" ("+response.status+")") : ""));
        else if (typeof response.code !== 'undefined' && typeof response.msg !== 'undefined') alert("[Lỗi]\n"+response.msg)
      } catch (error) {
        if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
      }
    } else {
      
    }
  };

  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = history.map((customer) => customer.id);
    } else {
      newSelectedCustomerIds = [];
    }
    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(1));
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }
    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content)
      .then(() => {
        console.log('String copied to clipboard:', content);
        showToast('success', `Đã copy: ${content}`)
      })
      .catch((error) => {
        console.error('Unable to copy string:', error);
        showToast('error', `Lỗi không thể copy: ${content}`)
      });
  }

  const setStatus = async (id, status) => {
    console.log(id, status)

    const result = await dispatch(CustomerAction.setStatus(id, {status}));
  };

  const setAppointmentAt = async (id, appointment_at) => {
    console.log(id, appointment_at)

    const result = await dispatch(CustomerAction.setAppointmentAt(id, {appointment_at}));
  };

  const setNote = async (id, note) => {
    if (note.trim() == '') return;

    console.log(id, note)

    const result = await dispatch(CustomerAction.setNote(id, {note}));
  };

  const handleUpdate = (id, fieldName, value) => {
    setCareUpdate({
      ...careUpdate,
      [id]: {
        ...careUpdate[id],
        [fieldName]: typeof careUpdate[id] !== 'undefined' && typeof careUpdate[id][fieldName] !== 'undefined' ? value : '',
      },
    });
  };
  
  const henHomNayTextColor = (hen_hom_nay) => {
    return 'initial';
    return hen_hom_nay == '1' ? 'red' : 'initial'
  };

  const setMoiLai = (e, value) => {
    setMoiLaiValue({
      ...moiLai,
      [value]: e.target.value == 1 ? 0 : 1
    });
  }

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  #
                </TableCell>
                {role.priority < 5 && <TableCell>
                  Username NV - Tên NV
                </TableCell>}
                <TableCell>
                  Thuê bao
                </TableCell>
                <TableCell>
                  Gói cước
                </TableCell>
                <TableCell>
                  Kết quả
                </TableCell>
                <TableCell>
                  Thời gian hẹn lại (nếu có)
                </TableCell>
                <TableCell>
                  Ghi chú
                </TableCell>
                <TableCell>
                  Thời điểm chăm sóc
                </TableCell>
                {1 < 0 && <>
                  <TableCell>
                    Mời lại hôm sau<br></br>
                    <Button style={{margin: 0, padding: 0}} onClick={() => chonMoiLai()}>
                      Chọn tất
                    </Button><br></br>
                    <Button style={{margin: 0, padding: 0, color: 'red'}} onClick={() => boChonMoiLai()}>
                      Bỏ chọn
                    </Button><br></br>
                    <Button style={{margin: 0, padding: 0, color: 'green'}} onClick={() => xacNhanMoiLai()}>
                      Xác nhận
                    </Button>
                  </TableCell>
                  <TableCell>
                    Thời điểm yêu cầu mời lại
                  </TableCell>
                  <TableCell>
                    Thời điểm mời lại
                  </TableCell>
                </>}
              </TableRow>
            </TableHead>
            <TableBody>
              {history.slice(page * limit, (page + 1) * limit).map((care, index) => (
                <TableRow
                  hover
                  key={care.id}
                  selected={selectedCustomerIds.indexOf(care.id) !== -1}
                >
                  <TableCell style={{color: henHomNayTextColor(care.hen_hom_nay)}}>
                    {page * limit + index + 1}
                  </TableCell>
                  {role.priority < 5 && <TableCell style={{color: henHomNayTextColor(care.hen_hom_nay)}}>
                    {care.username} - {care.fullname}
                  </TableCell>}
                  <TableCell style={{color: henHomNayTextColor(care.hen_hom_nay)}}>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      <Button style={{padding: 0, color: henHomNayTextColor(care.hen_hom_nay)}} onClick={() => copyToClipboard(care.phone ? care.phone : '')}>
                        {care.phone ? (role.priority < 3 ? care.phone.slice(-9) : ('****' + care.phone.slice(-5))) : ''}
                      </Button>
                    </Typography>
                  </TableCell>
                  <TableCell style={{color: henHomNayTextColor(care.hen_hom_nay)}}>
                    {care.package}
                  </TableCell>
                  <TableCell style={{color: henHomNayTextColor(care.hen_hom_nay)}}>
                    {(role.priority < 3 || role.priority > 2 && info.username == (care.username ? care.username : null)) && <Select1 style={{ fontSize: "14px", width: "100%", color: henHomNayTextColor(care.hen_hom_nay)}} variant="outlined" value={care.status} onChange={(e) => setStatus(care.id, e.target.value)}>
                      <MenuItem value="-99">Kết quả...</MenuItem>
                      <MenuItem value="APPROVED">Đồng ý</MenuItem>
                      <MenuItem value="REJECTED">Không đồng ý</MenuItem>
                      <MenuItem value="APPOINTMENT">Hẹn lại</MenuItem>
                      <MenuItem value="KBM">Không bắt máy</MenuItem>
                      <MenuItem value="KLLĐ">Không liên lạc được</MenuItem>
                      <MenuItem value="KEEP">Dùng lại gói cũ</MenuItem>
                      <MenuItem value="RE-REGISTER">Đã đăng ký lại</MenuItem>
                    </Select1>}
                    {!(role.priority < 3 || role.priority > 2 && info.username == (care.username ? care.username : null)) && CustomerCareStatus.find((c) => c.code === care.status) ? CustomerCareStatus.find((c) => c.code === care.status).name : ""}
                  </TableCell>
                  <TableCell style={{color: henHomNayTextColor(care.hen_hom_nay)}}>
                    {(role.priority < 3 || role.priority > 2 && info.username == (care.username ? care.username : null)) && care.status == 'APPOINTMENT' && <LocalizationProvider dateAdapter={AdapterDayjs} style={{ width: '100%' }}>
                        <DateTimePicker
                            style={{ width: '200px' }}
                            value={care.appointmentAt ? dayjs(new Date(care.appointmentAt)) : null}
                            onChange={(newValue) => setAppointmentAt(care.id, newValue)}
                        />
                    </LocalizationProvider>}
                    {!((role.priority < 3 || role.priority > 2 && info.username == (care.username ? care.username : null)) && care.status == 'APPOINTMENT') && care.appointmentAt && moment(care.appointmentAt).format('DD-MM-YYYY HH:mm:ss')}
                  </TableCell>
                  <TableCell style={{color: henHomNayTextColor(care.hen_hom_nay)}}>
                    {(role.priority < 3 || role.priority > 2 && info.username == (care.username ? care.username : null)) && <TextField
                      name='note'
                      onChange={(e) => handleUpdate(care.id, 'note', e.target.value)}
                      onBlur={(e) => setNote(care.id, e.target.value)}
                      value={typeof careUpdate[care.id] !== 'undefined' && careUpdate[care.id]['note'] !== 'undefined' && careUpdate[care.id]['note'] || care.note}
                      placeholder="Lý do"
                      variant="outlined"
                      style={{ height: 56, width: 150, marginLeft: 0 }}
                    />}
                    {!(role.priority < 3 || role.priority > 2 && info.username == (care.username ? care.username : null)) && care.note}
                  </TableCell>
                  <TableCell style={{color: henHomNayTextColor(care.hen_hom_nay)}}>
                    {moment(care.careAt).format('DD-MM-YYYY HH:mm:ss')}
                  </TableCell>
                  {1 < 0 && <>
                    <TableCell>
                      {care.yeu_cau_moi_lai_hom_nay != 1 && <Checkbox
                        checked={moiLai[care.id] == 1 ? true : false}
                        onChange={(event) => setMoiLai(event, care.id)}
                        value={moiLai[care.id] == 1 ? 1 : 0}
                      />}
                    </TableCell>
                    <TableCell>
                      {care.thoi_diem_yeu_cau_moi_lai ? moment(care.thoi_diem_yeu_cau_moi_lai).format('DD-MM-YYYY HH:mm:ss') : ''}
                    </TableCell>
                    <TableCell>
                      {care.thoi_diem_moi_lai ? moment(care.thoi_diem_moi_lai).format('DD-MM-YYYY HH:mm:ss') : ''}
                    </TableCell>
                  </>}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalHistory}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      />
    </Card>
  );
};

export default CustomerCareHistoryListResults;
