export const APP_LOADDING = 'APP:LOADDING'
export const APP_SHOW_CONFIRM = 'APP:SHOW_CONFIRM'
export const APP_HIDE_CONFIRM = 'APP:HIDE_CONFIRM'
export const APP_RESET = 'APP:RESET'
export const AppAction = {
  appLoading,
  showConfirm,
  hideConfirm
}

function appLoading(isLoading) {
  return dispatch => {
    dispatch(loading(isLoading));
  };
  function loading(isLoading) { return { type: APP_LOADDING, isLoading } }
}

function showConfirm(title, content) {
  return dispatch => {
    dispatch(show({ title, content }));
  };
  function show(payload) { return { type: APP_SHOW_CONFIRM, data: payload } }
}

function hideConfirm() {
  return dispatch => {
    dispatch(hide());
  };
  function hide() { return { type: APP_HIDE_CONFIRM } }
}


