import { UploadchiadataService } from 'src/services/uploadchiadata.service';
import { showToast } from 'src/utils/common';
import { NOTI_TYPES } from 'src/utils/constants';

import { AppAction } from './app.action';

export const UPLOADCHIADATA_NEW_CARE_SUCCESS = 'UPLOADCHIADATA:NEW_CARE_SUCCESS'
export const UPLOADCHIADATA_LIST_SUCCESS = 'UPLOADCHIADATA:FIND_ALL_SUCCESS'
export const UPLOADCHIADATA_LIST_FAILD = 'UPLOADCHIADATA:FIND_ALL_FAILD'
export const UPLOADCHIADATA_COUNT_ASSIGN_SUCCESS = 'UPLOADCHIADATA:COUNT_ASSIGN_SUCCESS'
export const UPLOADCHIADATA_COUNT_ASSIGN_FAILD = 'UPLOADCHIADATA:COUNT_ASSIGN_FAILD'

export const UPLOADCHIADATA_CREATE_SUCCESS = 'UPLOADCHIADATA:CREATE_SUCCESS'
export const UPLOADCHIADATA_CREATE_FAILD = 'UPLOADCHIADATA:CREATE_FAILD'

export const UPLOADCHIADATA_IMPORT_SUCCESS = 'UPLOADCHIADATA:IMPORT_SUCCESS'
export const UPLOADCHIADATA_SAVE_SUCCESS = 'UPLOADCHIADATA:SAVE_SUCCESS'
export const UPLOADCHIADATA_SAVE_FAILD = 'UPLOADCHIADATA:SAVE_SUCCESS'

export const UPLOADCHIADATA_ONE_SUCCESS = 'UPLOADCHIADATA:FIND_ONE_SUCCESS'
export const UPLOADCHIADATA_ONE_FAILD = 'UPLOADCHIADATA:FIND_ONE_FAILD'

export const UPLOADCHIADATA_HISTORY_LIST_SUCCESS = 'UPLOADCHIADATA:HISTORY_FIND_ALL_SUCCESS'
export const UPLOADCHIADATA_HISTORY_LIST_FAILD = 'UPLOADCHIADATA:HISTORY_FIND_ALL_FAILD'

export const UPLOADCHIADATA_LIST_BY_FILENAME_SUCCESS = 'UPLOADCHIADATA:FIND_ALL_BY_FILENAME_SUCCESS'
export const UPLOADCHIADATA_LIST_BY_FILENAME_FAILD = 'UPLOADCHIADATA:FIND_ALL_BY_FILENAME_FAILD'

export const UPLOADCHIADATA_ACTIVE_OR_DEACTIVE_SUCCESS = 'UPLOADCHIADATA:ACTIVE_OR_DEACTIVE_SUCCESS'
export const UPLOADCHIADATA_ACTIVE_OR_DEACTIVE_FAILD = 'UPLOADCHIADATA:ACTIVE_OR_DEACTIVE_FAILD'

export const UPLOADCHIADATA_HANDLE_CUSTOM_DATA_OPTION_SUCCESS = 'UPLOADCHIADATA:HANDLE_CUSTOM_DATA_OPTION_SUCCESS'
export const UPLOADCHIADATA_HANDLE_CUSTOM_DATA_OPTION_FAILD = 'UPLOADCHIADATA:HANDLE_CUSTOM_DATA_OPTION_FAILD'

export const UPLOADCHIADATA_HANDLE_FILE_USER_QUANTITY_OPTION_SUCCESS = 'UPLOADCHIADATA:HANDLE_FILE_USER_QUANTITY_OPTION_SUCCESS'
export const UPLOADCHIADATA_HANDLE_FILE_USER_QUANTITY_OPTION_FAILD = 'UPLOADCHIADATA:HANDLE_FILE_USER_QUANTITY_OPTION_FAILD'

export const UPLOADCHIADATA_OPTION_DATA_BRANCH_SUCCESS = 'UPLOADCHIADATA:OPTION_DATA_BRANCH_SUCCESS'
export const UPLOADCHIADATA_OPTION_DATA_BRANCH_FAILD = 'UPLOADCHIADATA:OPTION_DATA_BRANCH_FAILD'

export const UPLOADCHIADATA_OPTION_DATA_LEADER_SUCCESS = 'UPLOADCHIADATA:OPTION_DATA_LEADER_SUCCESS'
export const UPLOADCHIADATA_OPTION_DATA_LEADER_FAILD = 'UPLOADCHIADATA:OPTION_DATA_LEADER_FAILD'

export const UPLOADCHIADATA_OPTION_DATA_SALE_SUCCESS = 'UPLOADCHIADATA:OPTION_DATA_SALE_SUCCESS'
export const UPLOADCHIADATA_OPTION_DATA_SALE_FAILD = 'UPLOADCHIADATA:OPTION_DATA_SALE_FAILD'

export const UPLOADCHIADATA_DEL_SUCCESS = 'UPLOADCHIADATA:DEL_SUCCESS'
export const UPLOADCHIADATA_DEL_FAILD = 'UPLOADCHIADATA:DEL_FAILD'

export const UPLOADCHIADATA_INFO_BY_FILENAME_SUCCESS = 'UPLOADCHIADATA:INFO_BY_FILENAME_SUCCESS'
export const UPLOADCHIADATA_INFO_BY_FILENAME_FAILD = 'UPLOADCHIADATA:INFO_BY_FILENAME_FAILD'

export const UPLOADCHIADATA_UPDATE_FILTER = 'UPLOADCHIADATA:UPDATE_FILTER'

export const UploadchiadataAction = {
    list,
    saveDataUpload,
    findAllByFileName,
    activeOrDeactive,
    listNoLimit,
    handleCustomDataOption,
    getOptionDataByUsernameBranch,
    getOptionDataByUsernameLeader,
    getOptionDataByUsernameSale,
    handleFileUserQuantityOption,
    getOptionDataByFile,
    getInfoByFileName,
    deleteFile,
    updateFilter,
}

function updateFilter(payload) {
    return { type: UPLOADCHIADATA_UPDATE_FILTER, data: payload }
}

function getInfoByFileName(payload) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            UploadchiadataService.getInfoByFileName(payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    }
    function success(data) { return { type: UPLOADCHIADATA_INFO_BY_FILENAME_SUCCESS, data } }
    function failure(error) { return { type: UPLOADCHIADATA_INFO_BY_FILENAME_FAILD, error } }
}

function deleteFile(FileName) {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        UploadchiadataService.deleteFile(FileName).then((rs) => {
            showToast(NOTI_TYPES.SUCCESS, "Xóa file '" + FileName + "' thành công!");
        }).catch((error) => {
            showToast(NOTI_TYPES.ERROR, "Xóa file '" + FileName + "' thất bại!");
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
}

function list() {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        const { uploadchiadata } = getState();
        const page = uploadchiadata && typeof uploadchiadata.filter !== 'undefined' && uploadchiadata.filter && typeof uploadchiadata.filter.page !== 'undefined' ? uploadchiadata.filter.page : 0;
        const limit = uploadchiadata && typeof uploadchiadata.filter !== 'undefined' && uploadchiadata.filter && typeof uploadchiadata.filter.limit !== 'undefined' ? uploadchiadata.filter.limit : 10;
        UploadchiadataService.findAll(page, limit).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                const oldItems = uploadchiadata.items;
                const rsDataValues = rs.data.values;

                var uniqueItems = oldItems;
                for (var i = limit * page; i < limit * page + limit; i++) {
                    if (typeof rsDataValues[i - limit * page] !== 'undefined') {
                        uniqueItems[i] = rsDataValues[i - limit * page];
                    } else if (typeof uniqueItems[i] !== 'undefined') {
                        delete uniqueItems[i];
                    }
                }
                
                dispatch(success({
                    items: uniqueItems,
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: UPLOADCHIADATA_LIST_SUCCESS, data } }
    function failure(error) { return { type: UPLOADCHIADATA_LIST_FAILD, error } }
}

function listNoLimit() {
    return (dispatch, getState) => {
        const { uploadchiadata } = getState()
        dispatch(AppAction.appLoading(true));
        UploadchiadataService.findAllNoLimit().then((rs) => {
            dispatch(success({
                items: rs.data.values,
                total: rs.data.count
            }));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: UPLOADCHIADATA_LIST_SUCCESS, data } }
    function failure(error) { return { type: UPLOADCHIADATA_LIST_FAILD, error } }
}

function findAllByFileName(payload) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            UploadchiadataService.findAllByFileName(payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    }
    function success(data) { return { type: UPLOADCHIADATA_LIST_BY_FILENAME_SUCCESS, data } }
    function failure(error) { return { type: UPLOADCHIADATA_LIST_BY_FILENAME_FAILD, error } }
}

function saveDataUpload(payload) {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        let count = 0
        for (let i = 0; i < payload.length; i += 200) {
            // eslint-disable-next-line no-loop-func
            UploadchiadataService.save(payload.slice(i, i + 200)).then((rs) => {
                count += parseInt(rs.data.saved, 10)
                if (count === payload.length) {
                    dispatch(success(rs));
                    dispatch(AppAction.appLoading(false));
                }
            }).catch((error) => {
                dispatch(failure(error));
                dispatch(AppAction.appLoading(false));
            })
        }
    };

    function success(data) { return { type: UPLOADCHIADATA_SAVE_SUCCESS, data } }
    function failure(error) { return { type: UPLOADCHIADATA_SAVE_FAILD, error } }
}

function activeOrDeactive(payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            UploadchiadataService.activeOrDeactive(payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(UploadchiadataAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: UPLOADCHIADATA_ACTIVE_OR_DEACTIVE_SUCCESS, data } }
    function failure(error) { return { type: UPLOADCHIADATA_ACTIVE_OR_DEACTIVE_FAILD, error } }
}

function handleFileUserQuantityOption(payload) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            UploadchiadataService.handleFileUserQuantityOption(payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: UPLOADCHIADATA_HANDLE_FILE_USER_QUANTITY_OPTION_SUCCESS, data } }
    function failure(error) { return { type: UPLOADCHIADATA_HANDLE_FILE_USER_QUANTITY_OPTION_FAILD, error } }
}

function handleCustomDataOption(payload) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            UploadchiadataService.handleCustomDataOption(payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: UPLOADCHIADATA_HANDLE_CUSTOM_DATA_OPTION_SUCCESS, data } }
    function failure(error) { return { type: UPLOADCHIADATA_HANDLE_CUSTOM_DATA_OPTION_FAILD, error } }
}

function getOptionDataByUsernameBranch(username) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            UploadchiadataService.getOptionDataByUsername(username)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: UPLOADCHIADATA_OPTION_DATA_BRANCH_SUCCESS, data } }
    function failure(error) { return { type: UPLOADCHIADATA_OPTION_DATA_BRANCH_FAILD, error } }
}

function getOptionDataByFile(payload) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            UploadchiadataService.getOptionDataByFile(payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: UPLOADCHIADATA_OPTION_DATA_BRANCH_SUCCESS, data } }
    function failure(error) { return { type: UPLOADCHIADATA_OPTION_DATA_BRANCH_FAILD, error } }
}

function getOptionDataByUsernameLeader(username) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            UploadchiadataService.getOptionDataByUsername(username)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: UPLOADCHIADATA_OPTION_DATA_LEADER_SUCCESS, data } }
    function failure(error) { return { type: UPLOADCHIADATA_OPTION_DATA_LEADER_FAILD, error } }
}

function getOptionDataByUsernameSale(username) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            UploadchiadataService.getOptionDataByUsername(username)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: UPLOADCHIADATA_OPTION_DATA_SALE_SUCCESS, data } }
    function failure(error) { return { type: UPLOADCHIADATA_OPTION_DATA_SALE_FAILD, error } }
}