import {
  useEffect,
  useState,
} from 'react';

import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { Report90849332Action } from 'src/actions/report90849332.action';
import {
  DEFAULT_ROW_PER_PAGE,
  ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';

import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TextField,
} from '@material-ui/core';

import numeral from 'numeral';

const Report90849332Detail = ({ ...rest }) => {
  const dispatch = useDispatch()
  const { items, total, filter, tongDoanhThu, tongGoi } = useSelector(state => state.report90849332);
  const { role } = useSelector(state => state.profile);

  const [limit, setLimit] = useState(filter.limit || DEFAULT_ROW_PER_PAGE);
  const [page, setPage] = useState(filter.page || 0);
  const [updateUserValue, setUpdateUserValue] = useState({});


  useEffect(() => {
    const payload = {
      ...filter,
      limit: limit,
      page: page,
    };
    
    dispatch(Report90849332Action.updateFilter(payload));

    dispatch(Report90849332Action.list());
  }, [page, limit])

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // const handleUserChange = (event, username, track0, track1) => {
  //   if (event.key === 'Enter') {
  //     username = username.trim();
  //     if (username != '') {
  //       setUpdateUserValue({
  //         ...updateUserValue,
  //         [track0 + '_' + track1]: username,
  //       });
  //       dispatch(Report90849332Action.updateUser(username, track0, track1));
  //     }
  //   }
  // };

  const handleUserChange = (event, username, track0, track1) => {
    username = username.trim();
    if (username != '') {
      setUpdateUserValue({
        ...updateUserValue,
        [track0 + '_' + track1]: username,
      });
    }
  }

  const handleUserChangeConfirm = (event, username, track0, track1) => {
    if (event.key === 'Enter') {
      username = username.trim();
      if (username != '') {
        handleUserChange(event, username, track0, track1)
        dispatch(Report90849332Action.updateUser(username, track0, track1));
      }
    }
  };

  return (
    <Card {...rest} style={{ width: "100%"}}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell style={{color: "green"}}>
                  TỔNG GÓI
                </TableCell>
                <TableCell style={{color: "green"}}>
                  TỔNG DOANH THU
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell style={{color: "green"}}>
                  {numeral(tongGoi).format('0,0')}
                </TableCell>
                <TableCell style={{color: "green"}}>
                  {numeral(tongDoanhThu).format('0,0')}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  #
                </TableCell>
                <TableCell>
                  Kênh
                </TableCell>
                <TableCell>
                  Username NV
                </TableCell>
                <TableCell>
                  Tên NV
                </TableCell>
                <TableCell>
                  SĐT chăm sóc
                </TableCell>
                <TableCell>
                  Gói cước
                </TableCell>
                <TableCell>
                  Doanh thu
                </TableCell>
                <TableCell>
                  Ngày thành công
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.slice(page * limit, (page + 1) * limit).map((report90849332, index) => (
                <TableRow
                  hover
                  key={report90849332.track0 + '_' + report90849332.track1}
                >
                  <TableCell>
                    {page * limit + index + 1}
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {report90849332.kenh}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {role.priority < 3 && <TextField
                    name={report90849332.track0 + '_' + report90849332.track1}
                    onChange={(e) => handleUserChange(e, e.target.value, report90849332.track0, report90849332.track1)}
                    onKeyDown={(e) => handleUserChangeConfirm(e, e.target.value, report90849332.track0, report90849332.track1)}
                    value={updateUserValue[report90849332.track0 + '_' + report90849332.track1] || report90849332.username_nv}
                    placeholder=""
                    variant="outlined"
                    style={{ height: 56, width: 150, marginLeft: 0 }}
                    />}
                    {role.priority > 2 && report90849332.username_nv}
                  </TableCell>
                  <TableCell>
                    {report90849332.ten_nv}
                  </TableCell>
                  <TableCell>
                    {role.priority < 3 ? report90849332.so_dien_thoai_cs.slice(-9) : ('****' + report90849332.so_dien_thoai_cs.slice(-5))}
                  </TableCell>
                  <TableCell>
                    {report90849332.goi_cuoc}
                  </TableCell>
                  <TableCell>
                    {numeral(report90849332.doanh_thu).format('0,0')}
                  </TableCell>
                  <TableCell>
                    {moment(report90849332.ngay_thanh_cong).format('DD-MM-YYYY HH:mm:ss')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      />
    </Card>
  );
};

export default Report90849332Detail;
