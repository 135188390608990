import {
  useEffect,
  useState,
} from 'react';
import {
  Edit3 as Edit3Icon,
  Lock,
  RefreshCcw,
  Trash2 as Trash2Icon,
  Unlock,
} from 'react-feather';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { UploadchiadataAction } from 'src/actions/uploadchiadata.action';
import {
  CONFIRM_TYPES,
  DEFAULT_ROW_PER_PAGE,
  ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';
import { getUploadchiadataStatus } from 'src/utils/utils';
import ConfirmDialog from '../ConfirmDialog';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
  Button,
} from '@material-ui/core';
import { AppAction } from 'src/actions/app.action';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { element } from 'prop-types';

const UploadchiadataLookupDetail = ({ ...rest }) => {
  const dispatch = useDispatch()
  const [selectedUploadchiadataIds, setSelectedUploadchiadataIds] = useState([]);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);
  const { items, total, filter } = useSelector(state => state.uploadchiadata);
  const [confirm, setConfirm] = useState(false);
  const [confirmType, setConfirmType] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [selected, setSelected] = useState({});
  const [open, setOpen] = useState(false);
  const { accessToken, expiresAt } = useSelector(state => state.auth)
  const [recordInfos, setRecordInfos] = useState({});

  useEffect(() => {
    const payload = {
      ...filter,
      limit: limit,
      page: page,
    };
    dispatch(UploadchiadataAction.updateFilter(payload));
    dispatch(UploadchiadataAction.list(page, limit))
  }, [page, limit])

  const handleSelectAll = (event) => {
    let newSelectedUploadchiadataIds;

    if (event.target.checked) {
      newSelectedUploadchiadataIds = items.map((uploadchiadata) => uploadchiadata.id);
    } else {
      newSelectedUploadchiadataIds = [];
    }

    setSelectedUploadchiadataIds(newSelectedUploadchiadataIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedUploadchiadataIds.indexOf(id);
    let newSelectedUploadchiadataIds = [];

    if (selectedIndex === -1) {
      newSelectedUploadchiadataIds = newSelectedUploadchiadataIds.concat(selectedUploadchiadataIds, id);
    } else if (selectedIndex === 0) {
      newSelectedUploadchiadataIds = newSelectedUploadchiadataIds.concat(selectedUploadchiadataIds.slice(1));
    } else if (selectedIndex === selectedUploadchiadataIds.length - 1) {
      newSelectedUploadchiadataIds = newSelectedUploadchiadataIds.concat(selectedUploadchiadataIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedUploadchiadataIds = newSelectedUploadchiadataIds.concat(
        selectedUploadchiadataIds.slice(0, selectedIndex),
        selectedUploadchiadataIds.slice(selectedIndex + 1)
      );
    }

    setSelectedUploadchiadataIds(newSelectedUploadchiadataIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleConfirmOpen = (type, uploadchiadata) => {
    setSelected(uploadchiadata);
    setConfirmType(type);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      setTitle("Xoá bản ghi")
      setContent(`Xác nhận xoá bản ghi `)
    }
    setConfirm(true);
  };

  const onConfirm = () => {
    setConfirm(false);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      dispatch(UploadchiadataAction.del(selected.id))
      return;
    }
  }

  const handleConfirmClose = () => {
    setConfirm(false);
  };

  const onSelected = (id) => {
    dispatch(UploadchiadataAction.findOne(id));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const kichHoatSo = async (FileName) => {
    console.log(FileName)
    dispatch(AppAction.appLoading(true))
    try {
      dispatch(UploadchiadataAction.activeOrDeactive({
        FileName: FileName,
        is_activated: 1,
      }))
      dispatch(AppAction.appLoading(false))
      alert("Đã kích hoạt lấy số")
    } catch (error) {
      if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
    }
  }

  const dungLaySo = async (FileName) => {
    console.log(FileName)
    dispatch(AppAction.appLoading(true))
    try {
      dispatch(UploadchiadataAction.activeOrDeactive({
        FileName: FileName,
        is_activated: 0,
      }))
      dispatch(AppAction.appLoading(false))
      alert("Đã dừng lấy số")
    } catch (error) {
      if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
    }
  }

  const xoaFile = async (FileName) => {
    const question = "Xin lưu ý, khi xóa file này thì data từ file này không thể lấy được nữa. Bạn có chắc muốn xóa file này?";
    if (window.confirm(question)) {
      dispatch(UploadchiadataAction.deleteFile(FileName));
    } else {

    }
  }

  const getInfoByFileName = async (FileName) => {
    const info = await dispatch(UploadchiadataAction.getInfoByFileName({FileName: FileName}));
    if (info && typeof info.data !== 'undefined' && info.data) {
      setRecordInfos({
        ...recordInfos,
        [FileName]: info.data,
      });
    }
    console.log(recordInfos);
  }

  return (
    <Card {...rest} style={{ width: "100%"}}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  #
                </TableCell>
                <TableCell>
                  Tên file
                </TableCell>
                <TableCell>
                  Đã dùng/Tổng
                </TableCell>
                <TableCell>
                  Ngày tải lên
                </TableCell>
                <TableCell>
                  Người tải lên
                </TableCell>
                <TableCell>
                  Hành động
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items && items.slice(page * limit, page * limit + limit).map((uploadchiadata, index) => (
                <TableRow
                  hover
                  key={uploadchiadata.id}
                  selected={selectedUploadchiadataIds.indexOf(uploadchiadata.id) !== -1}
                >
                  <TableCell>
                    {page * limit + index + 1}
                  </TableCell>
                  <TableCell>
                    {uploadchiadata.FileName}
                  </TableCell>
                  <TableCell>
                    {(typeof recordInfos[uploadchiadata.FileName] !== 'undefined' ? recordInfos[uploadchiadata.FileName].tong_da_dung : '-') + "/" + (typeof recordInfos[uploadchiadata.FileName] !== 'undefined' ? recordInfos[uploadchiadata.FileName].tong : '-')}
                  </TableCell>
                  <TableCell>
                    {moment(uploadchiadata.ngay_upload).format('DD-MM-YYYY HH:mm:ss')}
                  </TableCell>
                  <TableCell>
                    {(typeof recordInfos[uploadchiadata.FileName] !== 'undefined' ? recordInfos[uploadchiadata.FileName].nguoi_upload : '-')}
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => getInfoByFileName(uploadchiadata.FileName)}>
                      Info
                    </Button>/
                    {uploadchiadata.is_activated == 0 && <Button onClick={() => kichHoatSo(uploadchiadata.FileName)}>
                      Kích hoạt lấy số
                    </Button>}
                    {uploadchiadata.is_activated == 1 && <Button onClick={() => dungLaySo(uploadchiadata.FileName)}>
                      Dừng lấy số
                    </Button>}/
                    <Button onClick={() => xoaFile(uploadchiadata.FileName)}>
                      Xóa file
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      />
      <ConfirmDialog open={confirm} title={title} content={content || `Xác nhận xoá bản ghi `} highlight={selected.ten_goi_cuoc} handleClose={handleConfirmClose} handleConfirm={onConfirm} />
    </Card>
  );
};

export default UploadchiadataLookupDetail;
