import { Helmet } from 'react-helmet';
import PermissionListResults
  from 'src/components/permission/PermissionListResults';
import PermissionListToolbar
  from 'src/components/permission/PermissionListToolbar';

import {
  Box,
  Container,
} from '@material-ui/core';

const PermissionList = () => (
  <>
    <Helmet>
      <title>Quyền</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
        <PermissionListToolbar />
        <Box sx={{ pt: 3 }}>
          <PermissionListResults />
        </Box>
      </Container>
    </Box>
  </>
);

export default PermissionList;
