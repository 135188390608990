import { MfyService } from 'src/services/mfy.service';
import { showToast } from 'src/utils/common';
import { NOTI_TYPES } from 'src/utils/constants';

import { AppAction } from './app.action';

export const MFY_NEW_CARE_SUCCESS = 'MFY:NEW_CARE_SUCCESS'
export const MFY_LIST_SUCCESS = 'MFY:FIND_ALL_SUCCESS'
export const MFY_LIST_FAILD = 'MFY:FIND_ALL_FAILD'
export const MFY_COUNT_ASSIGN_SUCCESS = 'MFY:COUNT_ASSIGN_SUCCESS'
export const MFY_COUNT_ASSIGN_FAILD = 'MFY:COUNT_ASSIGN_FAILD'

export const MFY_CREATE_SUCCESS = 'MFY:CREATE_SUCCESS'
export const MFY_CREATE_FAILD = 'MFY:CREATE_FAILD'

export const MFY_IMPORT_SUCCESS = 'MFY:IMPORT_SUCCESS'
export const MFY_SAVE_SUCCESS = 'MFY:SAVE_SUCCESS'
export const MFY_SAVE_FAILD = 'MFY:SAVE_SUCCESS'

export const MFY_DEL_SUCCESS = 'MFY:DEL_SUCCESS'
export const MFY_DEL_FAILD = 'MFY:DEL_FAILD'

export const MFY_ONE_SUCCESS = 'MFY:FIND_ONE_SUCCESS'
export const MFY_ONE_FAILD = 'MFY:FIND_ONE_FAILD'

export const MFY_HISTORY_LIST_SUCCESS = 'MFY:HISTORY_FIND_ALL_SUCCESS'
export const MFY_HISTORY_LIST_FAILD = 'MFY:HISTORY_FIND_ALL_FAILD'

export const MFY_UPDATE_FILTER = 'MFY:UPDATE_FILTER'

export const MfyAction = {
    list,
    findAssign,
    create,
    importData,
    saveData,
    update,
    del,
    assign,
    countAssign,
    history,
    findOne,
    setTrangThai,
    setTrangThaiThuTien,
    setTrangThaiDuyetThongTin,
    updateFilter,
    updateSoDienThoaiChu,
    updatePackageCode,
    updateGiaBanGoi,
    xoaTruyThu,
    moiTuDong,
    traCuuDoiTuong,
}

function updateFilter(payload) {
    return { type: MFY_UPDATE_FILTER, data: payload }
}

function moiTuDong(id) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            MfyService.moiTuDong(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(MfyAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: MFY_CREATE_SUCCESS, data } }
    function failure(error) { return { type: MFY_CREATE_FAILD, error } }
}

function traCuuDoiTuong(payload) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            MfyService.traCuuDoiTuong(payload)
            .then(response => {
                // Handle the successful response
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                reject(error); // Reject the promise with the error
            });
        });
    };
}

function updatePackageCode(package_code, id) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        MfyService.updatePackageCode(package_code, id).then((rs) => {
            dispatch(success(rs));
            dispatch(MfyAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: MFY_CREATE_SUCCESS, data } }
    function failure(error) { return { type: MFY_CREATE_FAILD, error } }
}

function updateGiaBanGoi(gia_ban_goi, id) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        MfyService.updateGiaBanGoi(gia_ban_goi, id).then((rs) => {
            dispatch(success(rs));
            dispatch(MfyAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: MFY_CREATE_SUCCESS, data } }
    function failure(error) { return { type: MFY_CREATE_FAILD, error } }
}

function updateSoDienThoaiChu(so_dien_thoai_chu, id) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            MfyService.updateSoDienThoaiChu(so_dien_thoai_chu, id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(MfyAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: MFY_CREATE_SUCCESS, data } }
    function failure(error) { return { type: MFY_CREATE_FAILD, error } }
}

function setTrangThai(id, payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            MfyService.setTrangThai(id, payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(MfyAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: MFY_CREATE_SUCCESS, data } }
    function failure(error) { return { type: MFY_CREATE_FAILD, error } }
}

function setTrangThaiThuTien(id, payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            MfyService.setTrangThaiThuTien(id, payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(MfyAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: MFY_CREATE_SUCCESS, data } }
    function failure(error) { return { type: MFY_CREATE_FAILD, error } }
}

function xoaTruyThu(id) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            MfyService.xoaTruyThu(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(MfyAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: MFY_CREATE_SUCCESS, data } }
    function failure(error) { return { type: MFY_CREATE_FAILD, error } }
}

function setTrangThaiDuyetThongTin(id, payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            MfyService.setTrangThaiDuyetThongTin(id, payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(MfyAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: MFY_CREATE_SUCCESS, data } }
    function failure(error) { return { type: MFY_CREATE_FAILD, error } }
}

function countAssign() {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        MfyService.countAssign().then((rs) => {
            dispatch(success(rs.data.total));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: MFY_COUNT_ASSIGN_SUCCESS, data } }
    function failure(error) { return { type: MFY_COUNT_ASSIGN_FAILD, error } }
}

function findAssign(page = 0, count = 20) {
    return (dispatch, getState) => {
        const { mfy } = getState()
        dispatch(AppAction.appLoading(true));
        MfyService.findAssign(page, count).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...mfy.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: MFY_LIST_SUCCESS, data } }
    function failure(error) { return { type: MFY_LIST_FAILD, error } }
}

function list() {
    return (dispatch, getState) => {
        //dispatch(AppAction.appLoading(true));
        const { mfy } = getState();
        const page = mfy && typeof mfy.filter !== 'undefined' && mfy.filter && typeof mfy.filter.page !== 'undefined' ? mfy.filter.page : 0;
        const limit = mfy && typeof mfy.filter !== 'undefined' && mfy.filter && typeof mfy.filter.limit !== 'undefined' ? mfy.filter.limit : 10;
        
        return new Promise((resolve, reject) => {
            MfyService.findAll(page, limit, mfy.filter).then((rs) => {
                if (page === 0) {
                    dispatch(success({
                        items: rs.data.values,
                        total: rs.data.count
                    }));

                    resolve(rs);
                } else {
                    const oldItems = mfy.items;
                    const rsDataValues = rs.data.values;
    
                    var uniqueItems = oldItems;
                    for (var i = limit * page; i < limit * page + limit; i++) {
                        if (typeof rsDataValues[i - limit * page] !== 'undefined') {
                            uniqueItems[i] = rsDataValues[i - limit * page];
                        } else if (typeof uniqueItems[i] !== 'undefined') {
                            delete uniqueItems[i];
                        }
                    }
                    
                    dispatch(success({
                        items: uniqueItems,
                        total: rs.data.count
                    }));
                    
                    resolve(rs);
                }
            }).catch((error) => {
                dispatch(failure(error));
                reject(error);
            }).finally(() => {
                //dispatch(AppAction.appLoading(false));
            })
        });
    };
    function success(data) { return { type: MFY_LIST_SUCCESS, data } }
    function failure(error) { return { type: MFY_LIST_FAILD, error } }
}

function importData(payload) {
    return { type: MFY_IMPORT_SUCCESS, data: payload }
}

function saveData(payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        let count = 0
        for (let i = 0; i < payload.length; i += 200) {
            // eslint-disable-next-line no-loop-func
            MfyService.save(payload.slice(i, i + 200)).then((rs) => {
                count += parseInt(rs.data.saved, 10)
                if (count === payload.length) {
                    dispatch(success(rs));
                    dispatch(MfyAction.list())
                    dispatch(AppAction.appLoading(false));
                }
            }).catch((error) => {
                dispatch(failure(error));
                dispatch(AppAction.appLoading(false));
            })
        }
    };

    function success(data) { return { type: MFY_SAVE_SUCCESS, data } }
    function failure(error) { return { type: MFY_SAVE_FAILD, error } }
}

function create(payload) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch(AppAction.appLoading(true));
            // Perform your asynchronous operation (e.g., API call)
            MfyService.create(payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(MfyAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            }).finally(() => {
                dispatch(AppAction.appLoading(false));
            });
        });
    };
    function success(data) { return { type: MFY_CREATE_SUCCESS, data } }
    function failure(error) { return { type: MFY_CREATE_FAILD, error } }
}

function update(id, payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        MfyService.update(id, payload).then((rs) => {
            dispatch(success(rs));
            dispatch(MfyAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: MFY_CREATE_SUCCESS, data } }
    function failure(error) { return { type: MFY_CREATE_FAILD, error } }
}

function del(id) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        MfyService.delete(id).then((rs) => {
            dispatch(success(rs));
            dispatch(MfyAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: MFY_DEL_SUCCESS, data } }
    function failure(error) { return { type: MFY_DEL_FAILD, error } }
}

function findOne(id) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        MfyService.find(id).then((rs) => {
            dispatch(success(rs.data));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: MFY_ONE_SUCCESS, data } }
    function failure(error) { return { type: MFY_ONE_FAILD, error } }
}

function assign(payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        MfyService.assign(payload).then((rs) => {
            showToast('success', "Phân chia dữ liệu thành công.")
            dispatch(MfyAction.list())
        }).catch((error) => {
            showToast('error', "Phân chia dữ liệu thất bại.\nVui lòng thử lại lần sau.")
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
}


function history(payload) {
    return (dispatch, getState) => {
        const { mfy } = getState()
        dispatch(AppAction.appLoading(true));
        MfyService.history(payload).then((rs) => {
            if (payload.page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...mfy.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: MFY_HISTORY_LIST_SUCCESS, data } }
    function failure(error) { return { type: MFY_HISTORY_LIST_FAILD, error } }
}