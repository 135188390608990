import { toQuery } from 'src/utils/utils';

import { handleResponse } from '../utils/auth-header';
import axios, { axiosOption } from '../utils/axios';

export const RequestrefundService = {

  createHoanTienGoiCuoc: (payload) => {
    return axios.post('/api/v1/requestrefund/createHoanTienGoiCuoc', payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  createNapTienChietKhau: (payload) => {
    return axios.post('/api/v1/requestrefund/createNapTienChietKhau', payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  create24hOrder: (payload) => {
    return axios.post('/api/v1/requestrefund/create24hOrder', payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  save: (payload) => {
    return axios.post('/api/v1/requestrefund/import', payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  update: (id, payload) => {
    return axios.put(`/api/v1/requestrefund/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  approve: (id, payload) => {
    return axios.put(`/api/v1/requestrefund/approve/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  setTrangThaiDuyetHoanGoiStatus: (id, payload) => {
    return axios.put(`/api/v1/requestrefund/setTrangThaiDuyetHoanGoiStatus/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  setTransferStatus: (id, payload) => {
    return axios.put(`/api/v1/requestrefund/setTransferStatus/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  setTrangThaiGiaoDich: (id, payload) => {
    return axios.put(`/api/v1/requestrefund/setTrangThaiGiaoDich/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  setHinhThuc: (id, payload) => {
    return axios.put(`/api/v1/requestrefund/setHinhThuc/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  setYeuCau: (id, payload) => {
    return axios.put(`/api/v1/requestrefund/setYeuCau/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  findAll: (page, count, payload) => {
    let url = `/api/v1/requestrefund?page=${page}&count=${count}`;

    if (payload.is_transfer) {
      url += `&is_transfer=${payload.is_transfer}`
    };

    if (payload.trang_thai_giao_dich_doi_tac) {
      url += `&trang_thai_giao_dich_doi_tac=${payload.trang_thai_giao_dich_doi_tac}`
    };

    if (payload.message) {
      url += `&message=${payload.message}`
    };

    if (payload.startDate && payload.endDate) {
      url += `&startDate=${payload.startDate}&endDate=${payload.endDate}`
    }
    
    return axios.get(url, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  findAssign: (page, count) => {
    return axios.get(`/api/v1/requestrefund/assign?page=${page}&count=${count}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  find: (id) => {
    return axios.get(`/api/v1/requestrefund/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  findPackage: (ten_goi_cuoc) => {
    return axios.get(`/api/v1/requestrefund/findPackage/${ten_goi_cuoc}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  countAssign: () => {
    return axios.get(`/api/v1/requestrefund/count-assign`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  delete: (id) => {
    return axios.delete(`/api/v1/requestrefund/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  assign: (payload) => {
    return axios.post(`/api/v1/requestrefund/assign`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  history: (payload) => {
    return axios.get(`/api/v1/care/history?${toQuery(payload)}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  updateUser: (username, id) => {
    return axios.post(`/api/v1/requestrefund/updateUser`, {username, id}, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  updatePackageCode: (package_code, id) => {
    return axios.post(`/api/v1/requestrefund/updatePackageCode`, {package_code, id}, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  updateTienHoanThem: (other_refund, id) => {
    return axios.post(`/api/v1/requestrefund/updateTienHoanThem`, {other_refund, id}, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  updateTienHoan: (refund_price, id) => {
    return axios.post(`/api/v1/requestrefund/updateTienHoan`, {refund_price, id}, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  updateTienChenhLech: (chenh_lech, id) => {
    return axios.post(`/api/v1/requestrefund/updateTienChenhLech`, {chenh_lech, id}, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  setCreatedTime: (id, payload) => {
    return axios.put(`/api/v1/requestrefund/setCreatedTime/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },
}