import { useState, useEffect } from 'react';

import { Search as SearchIcon } from 'react-feather';

import { useDispatch } from 'react-redux';

import {
  DEFAULT_ROW_PER_PAGE,
} from 'src/utils/constants';

import {
  Box,
  Button,
  Card,
  CardContent,
  InputAdornment,
  SvgIcon,
  TextField,
} from '@material-ui/core';

import AccountCreateDialog from './AccountCreateDialog';
import { AccountAction } from 'src/actions/account.acction';

const AccountListToolbar = (props) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [values, setValues] = useState({
    username: "",
  });

  const handleSearchChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onLookup();
    }
  };

  useEffect(() => {
    const today = new Date();

    const payload = {
      page: 0,
      limit: DEFAULT_ROW_PER_PAGE,
      username: "",
    };
    dispatch(AccountAction.updateFilter(payload));
  }, []);

  const onLookup = () => {
    const payload = {
      page: 'no_update',
      limit: 'no_update',
      username: values.username,
    };
    dispatch(AccountAction.updateFilter(payload));
    dispatch(AccountAction.list())
  };

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={handleClickOpen}
        >
          Tạo người dùng
        </Button>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card className="filter_card">
          <CardContent>
            <Box sx={{ maxWidth: 500 }}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                        >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                name="username"
                onChange={handleSearchChange}
                onKeyDown={handleKeyDown}
                value={values.username}
                placeholder="Tìm theo username NV"
                variant="outlined"
                style={{ height: 56, marginTop: 6, width: "200px", marginLeft: 0 }}
              />
              <Button
                color="primary"
                variant="contained"
                onClick={onLookup}
                style={{ height: 56, marginTop: 6, width: "20%", marginLeft: 15 }}
                >
                Tra cứu
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <AccountCreateDialog open={open} handleClose={handleClose} />
    </Box>
  )
}

export default AccountListToolbar;
