import { Helmet } from 'react-helmet';
import QuetdataDetail
  from 'src/components/quetdata-detail/QuetdataDetail';
  import UploadListToolbar from 'src/components/quetdata-detail/UploadListToolbar';
import {
  Box,
  Container,
} from '@material-ui/core';

const Quetdata = () => (
  <>
    <Helmet>
      <title>Quét data</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container style={{maxWidth: "100%"}}>
      <UploadListToolbar />
        <Box sx={{ pt: 3 }}>
          <QuetdataDetail />
        </Box>
      </Container>
    </Box>
  </>
);

export default Quetdata;
