import { Helmet } from 'react-helmet';
import LstracuuLookupDetail
  from 'src/components/lstracuu-lookup-detail/LstracuuLookupDetail';

import {
  Box,
  Container,
} from '@material-ui/core';

import LstracuuListToolbar from '../components/lstracuu-lookup-detail/LstracuuListToolbar';

const LstracuuLookup = () => (
  <>
    <Helmet>
      <title>Tra cứu ngân hàng</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container style={{maxWidth: "100%"}}>
        <LstracuuListToolbar />
        <Box sx={{ pt: 3 }}>
          <LstracuuLookupDetail />
        </Box>
      </Container>
    </Box>
  </>
);

export default LstracuuLookup;
