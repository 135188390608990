import {
    useEffect,
    useState,
} from 'react';

import {
    useDispatch,
    useSelector,
} from 'react-redux';
import { PermissionAction } from 'src/actions/permission.acction';
import { RoleAction } from 'src/actions/role.acction';

import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    TextField,
    Typography,
} from '@material-ui/core';

const CreateRoleDialog = (props) => {
    const dispatch = useDispatch()
    const [selectedPermissionIds, setSelectedPermissionIds] = useState([]);
    const { items } = useSelector(state => state.permission)
    const { selected } = useSelector(state => state.role)
    const [name, setName] = useState("")

    useEffect(() => {
        if (items.length > 0) {
            return;
        }
        dispatch(PermissionAction.list(0, 100))
    }, [])

    useEffect(() => {
        if (props.type === 'EDIT') {
            if (selected) {
                setName(selected.name)
                if (selected.permissions) {
                    setSelectedPermissionIds(selected.permissions.map(per => per.id))
                }
            }
        }
    }, [selected])

    const onPressPositive = () => {
        if (name === "") {
            return;
        }
        if (props.type === 'EDIT') {
            dispatch(RoleAction.update(selected.id, { name, permissions: selectedPermissionIds }))
        } else {
            dispatch(RoleAction.create({ name, permissions: selectedPermissionIds }))
        }
        resetState();
        props.handleClose();
    }

    const resetState = () => {
        setSelectedPermissionIds([])
        setName("")
    }

    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedPermissionIds.indexOf(id);
        let newSelectedCustomerIds = [];
        if (selectedIndex === -1) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds, id);
        } else if (selectedIndex === 0) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds.slice(1));
        } else if (selectedIndex === selectedPermissionIds.length - 1) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(
                selectedPermissionIds.slice(0, selectedIndex),
                selectedPermissionIds.slice(selectedIndex + 1)
            );
        }
        setSelectedPermissionIds(newSelectedCustomerIds);
    };

    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>{props.type === 'EDIT' ? "Cập nhật vai trò" : "Tạo vai trò mới"}</DialogTitle>
            <DialogContent style={{ width: 600 }}>
                <FormControl fullWidth style={{ marginTop: 20 }}>
                    <TextField
                        fullWidth
                        label="Tên vai trò"
                        name="name"
                        onChange={(e) => setName(e.target.value)}
                        required
                        value={name}
                        variant="outlined"
                    />
                </FormControl>
                <Typography
                    color="textPrimary"
                    variant="body1"
                    style={{ marginTop: 16 }}
                >
                    {"Danh sách quyền"}
                </Typography>
                {items.map((permission) => {
                    return (
                        <FormControl fullWidth style={{ width: 250 }} key={permission.id}>
                            <FormGroup >
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={selectedPermissionIds.indexOf(permission.id) !== -1}
                                        onChange={(event) => handleSelectOne(event, permission.id)}
                                        value="true"
                                    />
                                } label={permission.name} />
                            </FormGroup>
                        </FormControl>
                    )
                })}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Huỷ</Button>
                <Button onClick={onPressPositive}>{props.type === 'EDIT' ? "Cập nhật" : "Tạo"}</Button>
            </DialogActions>
        </Dialog >
    );
}

export default CreateRoleDialog;