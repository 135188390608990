import { Helmet } from 'react-helmet';
import RequestrefundDetail
  from 'src/components/requestrefund-detail/RequestrefundDetail';

import {
  Box,
  Container,
} from '@material-ui/core';

import RequestrefundListToolbar from '../components/requestrefund-detail/RequestrefundToolbar';

const Requestrefund = () => (
  <>
    <Helmet>
      <title>Yêu cầu hoàn tiền</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container style={{maxWidth: "100%"}}>
        <RequestrefundListToolbar />
        <Box sx={{ pt: 3 }}>
          <RequestrefundDetail />
        </Box>
      </Container>
    </Box>
  </>
);

export default Requestrefund;
