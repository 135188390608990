import {
    useEffect,
    useState,
} from 'react';

import {
    useDispatch,
    useSelector,
} from 'react-redux';
import { PermissionAction } from 'src/actions/permission.acction';
import { MfyAction } from 'src/actions/mfy.action';

import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    TextField,
    Typography,
    Grid,
    Box,
    MenuItem,
    Select as Select1,
} from '@material-ui/core';

import Select from 'react-select';

import {
    DatePicker,
    LocalizationProvider,
  } from '@mui/x-date-pickers';
  import {
    DEFAULT_ROW_PER_PAGE,
  } from 'src/utils/constants';
  import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import { GiagoimfyAction } from 'src/actions/giagoimfy.action';

import numeral from 'numeral';

import { Search as SearchIcon } from 'react-feather';

const CreateEditDialog = (props) => {
    const dispatch = useDispatch()
    const [selectedPermissionIds, setSelectedPermissionIds] = useState([]);
    const { role, info } = useSelector(state => state.profile);
    const { items } = useSelector(state => state.permission)
    const { goiCuocItems } = useSelector(state => state.giagoimfy)
    const { selected } = useSelector(state => state.mfy)
    const [so_dien_thoai_chu, setSoDienThoaiChu] = useState("")
    const [so_dien_thoai, setSoDienThoai] = useState("")
    const [ma_goi, setMaGoi] = useState("")
    const [gia_ban_goi, setGiaBanGoi] = useState("")
    const [username_nv, setUsernamenv] = useState("")
    const [prices, setPrices] = useState({
        gia_t1: 0,
        gia_t2: 0,
        gia_t3: 0,
    });

    useEffect(() => {
        // if (items.length > 0) {
        //     return;
        // }
        dispatch(PermissionAction.list(0, 100))
        dispatch(GiagoimfyAction.listNoLimit());
        console.log(goiCuocItems)
    }, [])

    useEffect(() => {
        if (props.type === 'EDIT') {
            if (selected) {
                setSoDienThoaiChu(selected.so_dien_thoai_chu)
                setSoDienThoai(selected.so_dien_thoai)
                setMaGoi(selected.ma_goi)
                setUsernamenv(selected.username_nv)
            }
        }
    }, [selected])

    const setMaGoi1 = async (ten_goi_cuoc) => {
        setMaGoi(ten_goi_cuoc)
        const giagoimfy = await dispatch(GiagoimfyAction.findPackage(ten_goi_cuoc));
        if (giagoimfy && typeof giagoimfy.data !== 'undefined' && giagoimfy.data) {
            setPrices({
                gia_t1: giagoimfy.data.gia_t1,
                gia_t2: giagoimfy.data.gia_t2,
                gia_t3: giagoimfy.data.gia_t3,
            });
        }
    }

    const onPressPositive = async () => {
        if (so_dien_thoai === "") {
            alert('[Lỗi]\nCó trường bắt buộc chưa được nhập hoặc chưa được chọn')
            return;
        }
        if (ma_goi === "") {
            alert('[Lỗi]\nCó trường bắt buộc chưa được nhập hoặc chưa được chọn')
            return;
        }
        if (gia_ban_goi === "") {
            alert('[Lỗi]\nCó trường bắt buộc chưa được nhập hoặc chưa được chọn')
            return;
        }
        if (role.priority < 5 && username_nv === "") {
            alert('[Lỗi]\nCó trường bắt buộc chưa được nhập hoặc chưa được chọn')
            return;
        }
        if (props.type === 'EDIT') {
            dispatch(MfyAction.update(selected.id, { so_dien_thoai_chu, so_dien_thoai, ma_goi, gia_ban_goi, username_nv }))
            resetState();
            props.handleClose();
        } else {
            try {
                const response = await dispatch(MfyAction.create({ so_dien_thoai_chu, so_dien_thoai, ma_goi, gia_ban_goi, username_nv }))
                resetState();
                props.handleClose();
            } catch (error) {
                if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
            }
        }
    }

    const resetState = () => {
        setSoDienThoaiChu("")
        setSoDienThoai("")
        setMaGoi("")
        setGiaBanGoi("")
        setUsernamenv("")
    }

    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedPermissionIds.indexOf(id);
        let newSelectedCustomerIds = [];
        if (selectedIndex === -1) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds, id);
        } else if (selectedIndex === 0) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds.slice(1));
        } else if (selectedIndex === selectedPermissionIds.length - 1) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(
                selectedPermissionIds.slice(0, selectedIndex),
                selectedPermissionIds.slice(selectedIndex + 1)
            );
        }
        setSelectedPermissionIds(newSelectedCustomerIds);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Dialog open={props.open} onClose={props.handleClose}>
                <DialogTitle>{props.type === 'EDIT' ? "Cập nhật bản ghi" : "Tạo bản ghi mới"}</DialogTitle>
                <DialogContent style={{ width: 600, paddingTop: 20 }}>
                    <Grid
                        container
                        spacing={3}
                    >
                        {(role.priority < 3) && <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Số điện thoại chủ"
                                name="so_dien_thoai_chu"
                                onChange={(e) => setSoDienThoaiChu(e.target.value)}
                                value={so_dien_thoai_chu}
                                variant="outlined"
                            />
                        </Grid>}
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Số điện thoại"
                                name="so_dien_thoai"
                                onChange={(e) => setSoDienThoai(e.target.value)}
                                required
                                value={so_dien_thoai}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                            zIndex={10}
                        >
                            <div style={{ width: '100%', zIndex: '10' }} className="full-width-select">
                                <Select
                                    styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        height: 56,
                                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                                    }),
                                    option: (provided) => ({
                                        ...provided,
                                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                                    }),
                                    }}
                                    options={goiCuocItems}
                                    getOptionLabel={(itemShow) => `${itemShow.ten_goi_cuoc}`}
                                    placeholder={ma_goi || "Chọn gói..."}
                                    components={{
                                    DropdownIndicator: () => <SearchIcon size={16} color="action" />,
                                    IndicatorSeparator: null,
                                    }}
                                    value={ma_goi || null}
                                    onChange={(newValue) => setMaGoi1(newValue.ten_goi_cuoc)}
                                    isSearchable
                                />
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <Select1 fullWidth variant="outlined" name="gia_ban_goi" value={gia_ban_goi || -99} onChange={(e) => setGiaBanGoi(e.target.value)}>
                                <MenuItem value="-99" selected>Chọn giá bán gói...</MenuItem>
                                <MenuItem value="1">Giá T1 ({numeral(prices.gia_t1).format('0,0')})</MenuItem>
                                <MenuItem value="2">Giá T2 ({numeral(prices.gia_t2).format('0,0')})</MenuItem>
                                {/* <MenuItem value="3">Giá T3 ({numeral(prices.gia_t3).format('0,0')})</MenuItem> */}
                            </Select1>
                        </Grid>
                        {role.priority < 5 && <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Nhân viên"
                                name="username_nv"
                                onChange={(e) => setUsernamenv(e.target.value)}
                                required
                                value={username_nv}
                                variant="outlined"
                            />
                        </Grid>}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose}>Huỷ</Button>
                    <Button onClick={onPressPositive}>{props.type === 'EDIT' ? "Cập nhật" : "Tạo"}</Button>
                </DialogActions>
            </Dialog >
        </LocalizationProvider>
    );
}

export default CreateEditDialog;