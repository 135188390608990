import React, { useEffect, useState } from 'react';

import { Search as SearchIcon } from 'react-feather';
import dayjs from 'dayjs';

import {
  Box,
  Button,
  Card,
  CardContent,
  InputAdornment,
  SvgIcon,
  TextField,
  MenuItem,
} from '@material-ui/core';

import Select from 'react-select';

import {
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import {
  DEFAULT_ROW_PER_PAGE,
} from 'src/utils/constants';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { TilebatsoAction } from 'src/actions/tilebatso.action';
import { AccountAction } from 'src/actions/account.acction';

import moment from 'moment';

import { AppAction } from 'src/actions/app.action';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const TilebatsoListToolbar = (props) => {
  const dispatch = useDispatch();
  const { filter } = useSelector(state => state.tilebatso)
  const { items, total } = useSelector(state => state.account)

  const [values, setValues] = useState({
    message: "",
    username: "",
    startDate: dayjs(filter.startDate, moment(new Date()).format('DD/MM/YYYY')),
    endDate: dayjs(filter.endDate, moment(new Date()).format('DD/MM/YYYY')),
  });

  const handleStartDateChange = (date) => {

    setValues({
      ...values,
      startDate: date
    });
  };

  const handleEndDateChange = (date) => {
    setValues({
      ...values,
      endDate: date
    });
  };

  const handleMessageChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleUsernameChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleUsernameChange1 = (name, newValue) => {
    setValues({
      ...values,
      [name]: newValue
    });
  };

  useEffect(() => {
    const today = new Date();

    const payload = {
      page: 0,
      limit: DEFAULT_ROW_PER_PAGE,
      message: "",
      username: "",
      startDate: dayjs(new Date(today.getFullYear(), today.getMonth(), 1)).format("DD/MM/YYYY"),
      endDate: dayjs(today).format("DD/MM/YYYY"),
    };
    dispatch(TilebatsoAction.updateFilter(payload));
    dispatch(AccountAction.listNoLimit());
  }, []);

  const onLookup = () => {
    const payload = {
      page: 'no_update',
      limit: 'no_update',
      message: values.message,
      username: values.username,
      startDate: values.startDate.format("DD/MM/YYYY"),
      endDate: values.endDate.format("DD/MM/YYYY"),
    };
    dispatch(TilebatsoAction.updateFilter(payload));
    dispatch(TilebatsoAction.list())
  };

  const exportExcel = async () => {
    dispatch(AppAction.appLoading(true))
    try {
      const payload = {
        page: 'no_update',
        limit: 'no_update',
        message: values.message,
        username: values.username,
        startDate: values.startDate.format("DD/MM/YYYY"),
        endDate: values.endDate.format("DD/MM/YYYY"),
      };
      dispatch(TilebatsoAction.updateFilter(payload));
      const response = await dispatch(TilebatsoAction.listExport());

      const data = [
        [
          '#', 
          'Username nhân viên',
          'Tên nhân viên', 
          'Đã nhận số', 
          'Số đã trả KQ', 
          'Hẹn',
          'Chấp nhận',
          'Từ chối',
          'Không nghe máy',
          'Không liên lạc được',
          'Dùng lại gói cũ',
          'Đã đăng ký lại',
        ],
      ];

      if (typeof response !== 'undefined' && response != null && typeof response.data !== 'undefined' && response.data != null && typeof response.data.values !== 'undefined' && response.data.values != null && response.data.values.length > 0) {
        var count = 1;
        response.data.values.forEach((element) => {
          data.push([
            count,
            element.username_nv,
            element.ten_nv,
            element.da_nhan_so,
            element.so_tra_ket_qua,
            element.so_tra_ket_qua == 0 ? '0 (0%)' : element.hen + " (" + parseInt(parseInt(element.hen) / parseInt(element.so_tra_ket_qua) * 100) + "%)",
            element.so_tra_ket_qua == 0 ? '0 (0%)' : element.chap_nhan + " (" + parseInt(parseInt(element.chap_nhan) / parseInt(element.so_tra_ket_qua) * 100) + "%)",
            element.so_tra_ket_qua == 0 ? '0 (0%)' : element.tu_choi + " (" + parseInt(parseInt(element.tu_choi) / parseInt(element.so_tra_ket_qua) * 100) + "%)",
            element.so_tra_ket_qua == 0 ? '0 (0%)' : element.khong_nghe_may + " (" + parseInt(parseInt(element.khong_nghe_may) / parseInt(element.so_tra_ket_qua) * 100) + "%)",
            element.so_tra_ket_qua == 0 ? '0 (0%)' : element.khong_lien_lac_duoc + " (" + parseInt(parseInt(element.khong_lien_lac_duoc) / parseInt(element.so_tra_ket_qua) * 100) + "%)",
            element.so_tra_ket_qua == 0 ? '0 (0%)' : element.dung_lai_goi_cu + " (" + parseInt(parseInt(element.dung_lai_goi_cu) / parseInt(element.so_tra_ket_qua) * 100) + "%)",
            element.so_tra_ket_qua == 0 ? '0 (0%)' : element.da_dang_ky_lai + " (" + parseInt(parseInt(element.da_dang_ky_lai) / parseInt(element.so_tra_ket_qua) * 100) + "%)",
          ])
          count++;
        })

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'Ti-le-bat-so.xlsx');
      }

      dispatch(AppAction.appLoading(false))
    } catch (error) {
      if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
    }
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onLookup();
    }
  };

  return (
    <Box {...props}>
      <Box sx={{ mt: 3 }}>
        <Card className="filter_card" style={{overflow: 'visible'}}>
          <CardContent>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box sx={{ 
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
                <div style={{ width: '250px', zIndex: '10' }} className="full-width-select">
                  <Select
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        height: 56,
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      }),
                      option: (provided) => ({
                        ...provided,
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      }),
                    }}
                    options={items}
                    getOptionLabel={(item) => `${item.role.priority == 4 ? '--' : (item.role.priority == 5 ? '----' : '')} ${item.username} - ${item.fullname}`}
                    placeholder={values.username || "Chọn nhân viên..."}
                    components={{
                      DropdownIndicator: () => <SearchIcon size={16} color="action" />,
                      IndicatorSeparator: null,
                    }}
                    value={values.username || null}
                    onChange={(newValue) => handleUsernameChange1('username', newValue.username)}
                    isSearchable
                  />
                </div>
                <DatePicker
                  label="Thời gian bắt đầu"
                  style={{ width: '100%', marginRight: 24 }}
                  format='DD/MM/YYYY'
                  value={values.startDate}
                  onChange={(newValue) => handleStartDateChange(newValue)}
                  />
                <DatePicker
                  label="Thời gian kết thúc"
                  style={{ width: '100%', marginLeft: 24 }}
                  value={values.endDate}
                  format='DD/MM/YYYY'
                  onChange={(newValue) => handleEndDateChange(newValue)}
                />
                <Button
                  color="primary"
                  variant="contained"
                  onClick={exportExcel}
                  style={{ height: 56, marginTop: 6, width: 200, marginLeft: 0 }}
                  >
                  Xuất báo cáo
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onLookup}
                  style={{ height: 56, marginTop: 6, width: 200, marginLeft: 0 }}
                  >
                  Tra cứu
                </Button>
              </Box>
            </LocalizationProvider>
          </CardContent>
        </Card>
      </Box>
    </Box>
  )
};

export default TilebatsoListToolbar;
