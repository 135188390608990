import { Helmet } from 'react-helmet';
import ApprovalListResults from 'src/components/approval/ApprovalListResults';
import ApprovalListToolbar from 'src/components/approval/ApprovalListToolbar';

import {
  Box,
  Container,
} from '@material-ui/core';

const ApprovalRefund = () => {
  return (
    <>
      <Helmet>
        <title>Quản lý yêu cầu</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <ApprovalListToolbar />
          <Box sx={{ pt: 3 }}>
            <ApprovalListResults />
          </Box>
        </Container>
      </Box>
    </>
  )
};

export default ApprovalRefund;
