import {
  AUTH_LOGOUT_SUCCESS,
  AUTH_SIGNIN_SUCCESS,
} from '../actions/auth.action';

const initialState = {
  accessToken: "",
  expiresIn: "",
  expiresAt: undefined,
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_SIGNIN_SUCCESS: {
      return {
        ...state,
        accessToken: action.data.access_token,
        expiresIn: action.data.expires_in,
        expiresAt: action.data.expires_at,
      };
    }
    case AUTH_LOGOUT_SUCCESS: {
      return {
        ...state,
        ...initialState
      };
    }
    default: {
      return state;
    }
  }
};
export default authReducer;