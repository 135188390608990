import { ProfileService } from 'src/services/profile.service';

import { AppAction } from './app.action';

export const PROFILE_INFO_SUCCESS = 'PROFILE:INFO_SUCCESS';
export const PROFILE_ROLE_SUCCESS = 'PROFILE:ROLE_SUCCESS';
export const PROFILE_PERMISSION_SUCCESS = 'PROFILE:PERMISSION_SUCCESS';
export const PROFILE_INFO_FAILD = 'PROFILE:INFO_FAILD';

export const PROFILE_LIST_SALEMAN_SUCCESS = 'PROFILE:LIST_SALEMAN_SUCCESS';
export const PROFILE_LIST_SALEMAN_FAILD = 'PROFILE:LIST_SALEMAN_FAILD';

export const PROFILE_LIST_LEADER_SUCCESS = 'PROFILE:LIST_LEADER_SUCCESS';
export const PROFILE_LIST_LEADER_FAILD = 'PROFILE:LIST_LEADER_FAILD';
export const PROFILE_LIST_BRAND_SUCCESS = 'PROFILE:LIST_BRAND_SUCCESS';
export const PROFILE_LIST_BRAND_FAILD = 'PROFILE:LIST_BRAND_FAILD';


export const ProfileAction = {
  listSaleman,
  memberOf,
  listLeader,
  listBrand
}

function listSaleman(payload) {
  return (dispatch) => {
    dispatch(AppAction.appLoading(true));
    ProfileService.findSaleman(payload).then((rs) => {
      dispatch(success(rs.data))
    }).catch((error) => {
      dispatch(failure(error));
    }).finally(() => {
      dispatch(AppAction.appLoading(false));
    })
  };
  function success(data) { return { type: PROFILE_LIST_SALEMAN_SUCCESS, data } }
  function failure(error) { return { type: PROFILE_LIST_SALEMAN_FAILD, error } }
}


function listLeader(id) {
  return (dispatch) => {
    dispatch(AppAction.appLoading(true));
    ProfileService.findLeaders(id).then((rs) => {
      dispatch(success(rs.data))
    }).catch((error) => {
      dispatch(failure(error));
    }).finally(() => {
      dispatch(AppAction.appLoading(false));
    })
  };
  function success(data) { return { type: PROFILE_LIST_LEADER_SUCCESS, data } }
  function failure(error) { return { type: PROFILE_LIST_LEADER_FAILD, error } }
}


function listBrand() {
  return (dispatch) => {
    dispatch(AppAction.appLoading(true));
    ProfileService.findMyBrands().then((rs) => {
      dispatch(success(rs.data))
    }).catch((error) => {
      dispatch(failure(error));
    }).finally(() => {
      dispatch(AppAction.appLoading(false));
    })
  };
  function success(data) { return { type: PROFILE_LIST_BRAND_SUCCESS, data } }
  function failure(error) { return { type: PROFILE_LIST_BRAND_FAILD, error } }
}

function memberOf(id) {
  return (dispatch, getState) => {
    const { profile } = getState();
    dispatch(AppAction.appLoading(true));
    ProfileService.findMember(id).then((rs) => {
      // list member
      let newMembers = [...profile.members];
      for (let i = 0; i < newMembers.length; i++) {
        if (newMembers[i].id === id) {
          newMembers[i].members = rs.data;
        }
      }
      dispatch(success(newMembers))
    }).catch((error) => {
      dispatch(failure(error));
    }).finally(() => {
      dispatch(AppAction.appLoading(false));
    })
  };
  function success(data) { return { type: PROFILE_LIST_SALEMAN_SUCCESS, data } }
  function failure(error) { return { type: PROFILE_LIST_SALEMAN_FAILD, error } }
}

