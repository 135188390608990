import { Helmet } from 'react-helmet';
import CustomerCareListResults
  from 'src/components/customer-care/CustomerCareListResults';
import CustomerCareListToolbar
  from 'src/components/customer-care/CustomerCareListToolbar';

import {
  Box,
  Container,
} from '@material-ui/core';

const CustomerCareList = () => (
  <>
    <Helmet>
      <title>Khách hàng mới</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
        <CustomerCareListToolbar />
        <Box sx={{ pt: 3 }}>
          <CustomerCareListResults />
        </Box>
      </Container>
    </Box>
  </>
);

export default CustomerCareList;
