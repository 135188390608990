import io from 'socket.io-client';

import {
  deleteAllCookies,
  getCookie,
} from '../utils/common';


const socket = io("https://sys.dvmobifone.com", { rejectUnauthorized: false });

// const socket = io("https://sys.dvmobifone.com", {
//   transports: ["websocket", "polling"], 
//   rejectUnauthorized: false, 
// });

socket.on("connect", () => {
  console.log("Đã kết nối với máy chủ");
});

socket.on("disconnect", () => {
  console.log("Mất kết nối với máy chủ");
});

const MainSocket = (state) => {
  const http = window.location.port ? 'http://' : 'https://';
  const domain = window.location.hostname + (window.location.port ? ("_" + window.location.port) : '');
  const host = http + domain;
  const token = getCookie('accessToken');
  const username_nv = state.profile.info.username
  const socket_client_id = domain + "_" + username_nv;

  socket.emit("vas-client", {
    index: socket_client_id,
    host: host,
    token: token,
  });

  return { host, token, socket, socket_client_id, domain, username_nv };
};

export default MainSocket;

// import io from 'socket.io-client';
// import { deleteAllCookies, getCookie } from '../utils/common';

// class SocketClient {
//   constructor() {
//     this.socket = null;
//     this.reconnectAttempts = 0;
//     this.maxReconnectAttempts = 5;
//     this.reconnectDelay = 5000; // 5 seconds
//   }

//   initialize(state) {
//     const config = this.createConfig(state);
//     this.setupSocket(config);
//     return {
//       host: config.host,
//       token: config.token,
//       socket: this.socket,
//       socket_client_id: config.socket_client_id,
//       domain: config.domain,
//       username_nv: config.username_nv
//     };
//   }

//   createConfig(state) {
//     const http = window.location.port ? 'http://' : 'https://';
//     const domain = window.location.hostname + (window.location.port ? ("_" + window.location.port) : '');
//     const host = http + domain;
//     const token = getCookie('accessToken');
//     const username_nv = state.profile.info.username;
//     const socket_client_id = `${domain}_${username_nv}`;

//     return {
//       host,
//       token,
//       socket_client_id,
//       domain,
//       username_nv
//     };
//   }

//   setupSocket(config) {
//     // const socketOptions = {
//     //   transports: ['websocket', 'polling'],
//     //   rejectUnauthorized: false, // Only use in development
//     //   reconnection: true,
//     //   reconnectionAttempts: this.maxReconnectAttempts,
//     //   reconnectionDelay: this.reconnectDelay,
//     //   timeout: 10000,
//     //   auth: {
//     //     token: config.token
//     //   }
//     // };

//       const socketOptions = {
//       transports: ['websocket', 'polling'], // Try WebSocket first, fallback to polling
//       rejectUnauthorized: false,
//       reconnection: true,
//       reconnectionAttempts: this.maxReconnectAttempts,
//       reconnectionDelay: this.reconnectDelay,
//       timeout: 10000,
//       upgrade: true, // Enable transport upgrade
//       forceNew: true, // Force a new connection
//       auth: {
//         token: config.token
//       },
//       extraHeaders: {
//         'Authorization': `Bearer ${config.token}`
//       },
//       path: '/socket.io/', // Explicitly set socket.io path
//       query: {
//         // Add any required query parameters
//         clientId: config.socket_client_id,
//         timestamp: Date.now()
//       }
//     };

//     try {
//       this.socket = io("https://sys.dvmobifone.com", socketOptions);
//       this.setupEventHandlers(config);
//     } catch (error) {
//       console.error('Socket initialization error:', error);
//       throw new Error('Failed to initialize socket connection');
//     }
//   }

//   setupEventHandlers(config) {
//     this.socket.on('connect', () => {
//       console.log('Connected to server');
//       this.reconnectAttempts = 0;
      
//       // Emit client information after successful connection
//       this.socket.emit("vas-client", {
//         index: config.socket_client_id,
//         host: config.host,
//         token: config.token,
//       });
//     });

//     this.socket.on('connect_error', (error) => {
//       console.error('Connection error:', error);
//       this.handleReconnection();
//     });

//     this.socket.on('disconnect', (reason) => {
//       console.log('Disconnected from server:', reason);
//       if (reason === 'io server disconnect') {
//         // Server initiated disconnect, attempt reconnection
//         this.socket.connect();
//       }
//     });

//     this.socket.on('error', (error) => {
//       console.error('Socket error:', error);
//       if (error.description === 'unauthorized') {
//         deleteAllCookies();
//         window.location.reload();
//       }
//     });
//   }

//   handleReconnection() {
//     this.reconnectAttempts++;
//     if (this.reconnectAttempts >= this.maxReconnectAttempts) {
//       console.error('Max reconnection attempts reached');
//       this.socket.disconnect();
//       // Optionally refresh the page or show user message
//       return;
//     }
    
//     console.log(`Reconnection attempt ${this.reconnectAttempts}/${this.maxReconnectAttempts}`);
//   }

//   disconnect() {
//     if (this.socket) {
//       this.socket.disconnect();
//       this.socket = null;
//     }
//   }
// }

// const socketClient = new SocketClient();

// const MainSocket = (state) => {
//   return socketClient.initialize(state);
// };

// export default MainSocket;