import {
  useEffect,
  useState,
} from 'react';
import {
  Edit3 as Edit3Icon,
  Lock,
  RefreshCcw,
  Trash2 as Trash2Icon,
  Unlock,
} from 'react-feather';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { QuetdatakhonggoiAction } from 'src/actions/quetdatakhonggoi.action';
import {
  CONFIRM_TYPES,
  DEFAULT_ROW_PER_PAGE,
  ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';
import { getQuetdatakhonggoiStatus } from 'src/utils/utils';
import ConfirmDialog from '../ConfirmDialog';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
  Button,
} from '@material-ui/core';
import { AppAction } from 'src/actions/app.action';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { element } from 'prop-types';

import MainSocket from 'src/components/MainSocket.js'

const QuetdatakhonggoiLookupDetail = ({ ...rest }) => {
  const dispatch = useDispatch()
  const [selectedQuetdatakhonggoiIds, setSelectedQuetdatakhonggoiIds] = useState([]);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);
  const { items, total, filter } = useSelector(state => state.quetdatakhonggoi);
  const [confirm, setConfirm] = useState(false);
  const [confirmType, setConfirmType] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [selected, setSelected] = useState({});
  const [open, setOpen] = useState(false);
  const { accessToken, expiresAt } = useSelector(state => state.auth)
  const [recordInfos, setRecordInfos] = useState({});
  const { socket, socket_client_id } = MainSocket(useSelector(state => state));

  useEffect(() => {
    const payload = {
      ...filter,
      limit: limit,
      page: page,
    };
    dispatch(QuetdatakhonggoiAction.updateFilter(payload));
    dispatch(QuetdatakhonggoiAction.list(page, limit))
  }, [page, limit])

  const handleSelectAll = (event) => {
    let newSelectedQuetdatakhonggoiIds;

    if (event.target.checked) {
      newSelectedQuetdatakhonggoiIds = items.map((quetdatakhonggoi) => quetdatakhonggoi.id);
    } else {
      newSelectedQuetdatakhonggoiIds = [];
    }

    setSelectedQuetdatakhonggoiIds(newSelectedQuetdatakhonggoiIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedQuetdatakhonggoiIds.indexOf(id);
    let newSelectedQuetdatakhonggoiIds = [];

    if (selectedIndex === -1) {
      newSelectedQuetdatakhonggoiIds = newSelectedQuetdatakhonggoiIds.concat(selectedQuetdatakhonggoiIds, id);
    } else if (selectedIndex === 0) {
      newSelectedQuetdatakhonggoiIds = newSelectedQuetdatakhonggoiIds.concat(selectedQuetdatakhonggoiIds.slice(1));
    } else if (selectedIndex === selectedQuetdatakhonggoiIds.length - 1) {
      newSelectedQuetdatakhonggoiIds = newSelectedQuetdatakhonggoiIds.concat(selectedQuetdatakhonggoiIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedQuetdatakhonggoiIds = newSelectedQuetdatakhonggoiIds.concat(
        selectedQuetdatakhonggoiIds.slice(0, selectedIndex),
        selectedQuetdatakhonggoiIds.slice(selectedIndex + 1)
      );
    }

    setSelectedQuetdatakhonggoiIds(newSelectedQuetdatakhonggoiIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleConfirmOpen = (type, quetdatakhonggoi) => {
    setSelected(quetdatakhonggoi);
    setConfirmType(type);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      setTitle("Xoá bản ghi")
      setContent(`Xác nhận xoá bản ghi `)
    }
    setConfirm(true);
  };

  const onConfirm = () => {
    setConfirm(false);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      dispatch(QuetdatakhonggoiAction.del(selected.id))
      return;
    }
  }

  const handleConfirmClose = () => {
    setConfirm(false);
  };

  const onSelected = (id) => {
    dispatch(QuetdatakhonggoiAction.findOne(id));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dungQuet = async (FileName) => {
    console.log(FileName)
    dispatch(AppAction.appLoading(true))
    try {
      socket.emit('dungQuetDataKhongGoiTHREAD1', {client_id: socket_client_id, data: {token: accessToken, FileName: FileName}});
      alert("Đã yêu cầu dừng quét \"" + FileName + "\"")
      dispatch(AppAction.appLoading(false))
    } catch (error) {
      if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
    }
  }

  const batDauQuet = async (FileName) => {
    console.log(FileName)
    dispatch(AppAction.appLoading(true))
    try {
      socket.emit('batDauQuetDataKhongGoiTHREAD1', {client_id: socket_client_id, data: {token: accessToken, FileName: FileName}});
      alert("Bắt đầu quét \"" + FileName + "\"")
      dispatch(AppAction.appLoading(false))
    } catch (error) {
      if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
    }
  }

  const xoaFile = async (FileName) => {
    const question = "Xin lưu ý, khi xóa file này thì toàn bộ dữ liệu quét cũng sẽ xóa theo. Bạn có chắc muốn xóa file này?";
    if (window.confirm(question)) {
      dispatch(QuetdatakhonggoiAction.deleteFile(FileName));
    } else {

    }
  }

  const getInfoByFileName = async (FileName) => {
    const info = await dispatch(QuetdatakhonggoiAction.getInfoByFileName({FileName: FileName}));
    if (info && typeof info.data !== 'undefined' && info.data) {
      setRecordInfos({
        ...recordInfos,
        [FileName]: info.data,
      });
    }
    console.log(recordInfos);
  }

  const xuatSoDaQuet = async (FileName) => {
    console.log(FileName)
    dispatch(AppAction.appLoading(true))
    try {
      const response = await dispatch(QuetdatakhonggoiAction.findAllByFileName({"FileName": FileName}));

      const data = [
        [
          '#', 
          'Số điện thoại', 
          'Loại thuê bao', 
          'Gói cước', 
          'Ngày đăng ký', 
          'Ngày hết hạn', 
          'Chu kỳ hiện tại', 
          'Trạng thái',
        ],
      ];

      if (typeof response !== 'undefined' && response != null && response.length > 0) {
        var count = 1;
        response.forEach((element) => {
          data.push([
            count,
            element.PhoneNumber,
            element.NumberType,
            element.NumberCurrentPackage,
            element.NumberPackageRegisterDate,
            element.NumberPackageRegisterExpire,
            element.CurrentCycle,
            element.NumberStatus,
          ])
          count++;
        })

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'DA-XU-LY-' + FileName);
      }

      dispatch(AppAction.appLoading(false))
    } catch (error) {
      if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
    }
  }

  return (
    <Card {...rest} style={{ width: "100%"}}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  #
                </TableCell>
                <TableCell>
                  Tên file
                </TableCell>
                <TableCell>
                  Số đã quét
                </TableCell>
                <TableCell>
                  Ngày tải lên
                </TableCell>
                <TableCell>
                  Người tải lên
                </TableCell>
                <TableCell>
                  Hành động
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items && items.slice(page * limit, page * limit + limit).map((quetdatakhonggoi, index) => (
                <TableRow
                  hover
                  key={quetdatakhonggoi.id}
                  selected={selectedQuetdatakhonggoiIds.indexOf(quetdatakhonggoi.id) !== -1}
                >
                  <TableCell>
                    {page * limit + index + 1}
                  </TableCell>
                  <TableCell>
                    {quetdatakhonggoi.FileName}
                  </TableCell>
                  <TableCell>
                    {(typeof recordInfos[quetdatakhonggoi.FileName] !== 'undefined' ? recordInfos[quetdatakhonggoi.FileName].so_da_quet : '-') + "/" + (typeof recordInfos[quetdatakhonggoi.FileName] !== 'undefined' ? recordInfos[quetdatakhonggoi.FileName].total_numbers : '-')} <br></br> {"Tổng gói: " + (typeof recordInfos[quetdatakhonggoi.FileName] !== 'undefined' ? recordInfos[quetdatakhonggoi.FileName].goi_da_quet : '-')}
                  </TableCell>
                  <TableCell>
                    {quetdatakhonggoi.ngay_upload}
                  </TableCell>
                  <TableCell>
                    {(typeof recordInfos[quetdatakhonggoi.FileName] !== 'undefined' ? recordInfos[quetdatakhonggoi.FileName].nguoi_upload : '-')}
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => getInfoByFileName(quetdatakhonggoi.FileName)}>
                      Info
                    </Button>/
                    <Button onClick={() => batDauQuet(quetdatakhonggoi.FileName)}>
                      Quét
                    </Button>/
                    <Button onClick={() => dungQuet(quetdatakhonggoi.FileName)}>
                      Dừng
                    </Button>/
                    <Button onClick={() => xuatSoDaQuet(quetdatakhonggoi.FileName)}>
                      Xuất file
                    </Button>
                    <Button onClick={() => xoaFile(quetdatakhonggoi.FileName)}>
                      Xóa file
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      />
      <ConfirmDialog open={confirm} title={title} content={content || `Xác nhận xoá bản ghi `} highlight={selected.ten_goi_cuoc} handleClose={handleConfirmClose} handleConfirm={onConfirm} />
    </Card>
  );
};

export default QuetdatakhonggoiLookupDetail;
