import 'react-toastify/dist/ReactToastify.css';

import {
  useEffect,
  useState,
} from 'react';

import moment from 'moment';

import {
  useDispatch,
  useSelector
} from 'react-redux';

import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import TextUtil from 'src/utils/text-utils';

import {
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';

import { AppAction } from '../actions/app.action';

import { toast } from 'react-toastify';

import { showToast } from 'src/utils/common';
import MainSocket from 'src/components/MainSocket.js'
import { emitter } from 'src/utils/emitter';

const DashboardLayoutRoot = styled('div')(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  })
);

const DashboardLayoutWrapper = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  })
);

const DashboardLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const DashboardLayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto'
});

const DashboardLayout = () => {
  const dispatch = useDispatch();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const { isLoading } = useSelector(state => state.app)
  const { accessToken, expiresAt } = useSelector(state => state.auth)
  const { socket, socket_client_id, domain } = MainSocket(useSelector(state => state));

  var thongBaoCu = null

  // socket.on("showThongBao", (data) => {
  //   if (domain == data.domain && (!thongBaoCu || thongBaoCu != data.content)) {
  //     toast.dismiss();
  //     showToast('success', data.content);
  //     thongBaoCu = data.content;
  //   }
  // })

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     event.preventDefault();
  //     // Put your code here to handle the event before the page is unloaded.
  //     // For example, you can show a confirmation message to the user.
  //     // Returning a value from this event will display a confirmation dialog
  //     // in some browsers to ask the user if they really want to leave the page.
  //     // You can return a string message to customize the confirmation dialog.
  //     dispatch(AppAction.appLoading(false));
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  //MENU_CUSTOM
  useEffect(() => {
    emitter.emit("add_tab", "Dashboard")

    if (typeof window != "undefined") {

      if (window.localStorage.getItem("isMenuOpen") != null) {
        setMobileNavOpen(window.localStorage.getItem("isMenuOpen") == "false")
      }

    }
  }, [])

  if (TextUtil.isEmpty(accessToken) || !expiresAt || moment(expiresAt).toDate() < Date.now()) {
    window.location.href = '/login';
    return <></>;
  }

  return (
    <DashboardLayoutRoot>
      {/* MENU_CUSTOM */}
      {/* <DashboardNavbar onMobileNavOpen={() => setMobileNavOpen(true)} /> */}
      <DashboardNavbar onMobileNavOpen={() => {
        const newMenuState = !isMobileNavOpen
        setMobileNavOpen(newMenuState)

        if (typeof window != "undefined") {
          window.localStorage.setItem("isMenuOpen", newMenuState)
        }
      }} />

      <DashboardSidebar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />

      {/* MENU_CUSTOM */}
      {/* <DashboardLayoutWrapper> */}
      <DashboardLayoutWrapper style={{ paddingLeft: isMobileNavOpen ? 256 : 0 }}>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet />
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
