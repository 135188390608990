import { ReportService } from 'src/services/report.service';
import { DEFAULT_PAGE } from 'src/utils/constants';

import { AppAction } from './app.action';

export const REPORT_9332_QUERY = 'REPORT:FIND_ALL_9332_QUERY'
export const REPORT_9332_SUCCESS = 'REPORT:FIND_ALL_9332_SUCCESS'
export const REPORT_9332_FAILD = 'REPORT:FIND_ALL_9332_FAILD'

export const REPORT_9084_QUERY = 'REPORT:FIND_ALL_9084_QUERY'
export const REPORT_9084_SUCCESS = 'REPORT:FIND_ALL_9084_SUCCESS'
export const REPORT_9084_FAILD = 'REPORT:FIND_ALL_9084_FAILD'

export const REPORT_9084new_QUERY = 'REPORT:FIND_ALL_9084new_QUERY'
export const REPORT_9084new_SUCCESS = 'REPORT:FIND_ALL_9084new_SUCCESS'
export const REPORT_9084new_FAILD = 'REPORT:FIND_ALL_9084new_FAILD'

export const REPORT_9084new1_QUERY = 'REPORT:FIND_ALL_9084new1_QUERY'
export const REPORT_9084new1_SUCCESS = 'REPORT:FIND_ALL_9084new1_SUCCESS'
export const REPORT_9084new1_FAILD = 'REPORT:FIND_ALL_9084new1_FAILD'

export const REPORT_9084new2_QUERY = 'REPORT:FIND_ALL_9084new2_QUERY'
export const REPORT_9084new2_SUCCESS = 'REPORT:FIND_ALL_9084new2_SUCCESS'
export const REPORT_9084new2_FAILD = 'REPORT:FIND_ALL_9084new2_FAILD'

export const UPDATE_FILTER = 'REPORT:UPDATE_FILTER'
export const UPDATE_FILTER1 = 'REPORT:UPDATE_FILTER1'
export const UPDATE_FILTERnew = 'REPORT:UPDATE_FILTERnew'
export const UPDATE_FILTERnew1 = 'REPORT:UPDATE_FILTERnew1'
export const UPDATE_FILTERnew2 = 'REPORT:UPDATE_FILTERnew2'

export const ReportAction = {
  list9332,
  list9084,
  list9084new,
  list9084new1,
  list9084new2,
  updateFilter,
  updateFilter1,
  updateFilternew,
  updateFilternew1,
  updateFilternew2,
}

function updateFilter(payload) {
    return { type: UPDATE_FILTER, data: payload }
}

function updateFilter1(payload) {
    return { type: UPDATE_FILTER1, data: payload }
}

function updateFilternew(payload) {
    return { type: UPDATE_FILTERnew, data: payload }
}

function updateFilternew1(payload) {
    return { type: UPDATE_FILTERnew1, data: payload }
}

function updateFilternew2(payload) {
    return { type: UPDATE_FILTERnew2, data: payload }
}

function list9084() {
  return (dispatch, getState) => {
      const { report } = getState();
      dispatch(AppAction.appLoading(true));

      const { filter } = report;
      const page = filter.page;
      const limit = filter.limit;

      ReportService.report9084(filter.page, filter.limit, filter).then((rs) => {
          if (!!filter && filter.page === 0) {
              dispatch(success({
                  items: rs.data.values,
                  total: rs.data.count
              }));
          } else {
              const oldItems = report.items9084;
              const rsDataValues = rs.data.values;

              var uniqueItems = oldItems;
              for (var i = limit * page; i < limit * page + limit; i++) {
                  if (typeof rsDataValues[i - limit * page] !== 'undefined') {
                      uniqueItems[i] = rsDataValues[i - limit * page];
                  } else if (typeof uniqueItems[i] !== 'undefined') {
                      delete uniqueItems[i];
                  }
              }
              
              dispatch(success({
                  items: uniqueItems,
                  total: rs.data.count
              }));
          }
      }).catch((error) => {
          dispatch(failure(error));
      }).finally(() => {
          dispatch(AppAction.appLoading(false));
      })
  };
  function success(data) { return { type: REPORT_9084_SUCCESS, data } }
  function failure(error) { return { type: REPORT_9084_FAILD, error } }
}

// function list9084(payload) {
//   return (dispatch, getState) => {
//     const { report } = getState()
//     dispatch(AppAction.appLoading(true));
//     dispatch(query(payload))
//     ReportService.report9084(payload).then((rs) => {
//       if (payload.page === 0 || payload.page === `0`) {
//         dispatch(success({
//           items: rs.data.values,
//           total: rs.data.count
//         }));
//       } else {
//         dispatch(success({
//           items: [...report.items9084, ...rs.data.values],
//           total: rs.data.count
//         }));
//       }
//     }).catch((error) => {
//       dispatch(failure(error));
//     }).finally(() => {
//       dispatch(AppAction.appLoading(false));
//     })
//   };
//   function query(data) { return { type: REPORT_9084_QUERY, data } }
//   function success(data) { return { type: REPORT_9084_SUCCESS, data } }
//   function failure(error) { return { type: REPORT_9084_FAILD, error } }
// }

function list9332() {
  return (dispatch, getState) => {
      const { report } = getState();
      dispatch(AppAction.appLoading(true));

      const { filter1 } = report;
      const page = filter1.page;
      const limit = filter1.limit;

      ReportService.report9332(filter1.page, filter1.limit, filter1).then((rs) => {
          if (!!filter1 && filter1.page === 0) {
              dispatch(success({
                  items: rs.data.values,
                  total: rs.data.count
              }));
          } else {
              const oldItems = report.items9332;
              const rsDataValues = rs.data.values;

              var uniqueItems = oldItems;
              for (var i = limit * page; i < limit * page + limit; i++) {
                  if (typeof rsDataValues[i - limit * page] !== 'undefined') {
                      uniqueItems[i] = rsDataValues[i - limit * page];
                  } else if (typeof uniqueItems[i] !== 'undefined') {
                      delete uniqueItems[i];
                  }
              }
              
              dispatch(success({
                  items: uniqueItems,
                  total: rs.data.count
              }));
          }
      }).catch((error) => {
          dispatch(failure(error));
      }).finally(() => {
          dispatch(AppAction.appLoading(false));
      })
  };
  function success(data) { return { type: REPORT_9332_SUCCESS, data } }
  function failure(error) { return { type: REPORT_9332_FAILD, error } }
}

// function list9332(payload) {
//   return (dispatch, getState) => {
//     const { report } = getState()
//     dispatch(AppAction.appLoading(true));
//     dispatch(query(payload))
//     ReportService.report9332(payload).then((rs) => {
//       if (payload.page === 0 || payload.page === `0`) {
//         dispatch(success({
//           items: rs.data.values,
//           total: rs.data.count
//         }));
//       } else {
//         dispatch(success({
//           items: [...report.items9332, ...rs.data.values],
//           total: rs.data.count
//         }));
//       }
//     }).catch((error) => {
//       dispatch(failure(error));
//     }).finally(() => {
//       dispatch(AppAction.appLoading(false));
//     })
//   };
//   function query(data) { return { type: REPORT_9332_QUERY, data } }
//   function success(data) { return { type: REPORT_9332_SUCCESS, data } }
//   function failure(error) { return { type: REPORT_9332_FAILD, error } }
// }

function list9084new() {
  return (dispatch, getState) => {
      const { report } = getState();
      dispatch(AppAction.appLoading(true));

      const { filternew } = report;
      const page = filternew.page;
      const limit = filternew.limit;

      ReportService.report9084new(filternew.page, filternew.limit, filternew).then((rs) => {
          if (!!filternew && filternew.page === 0) {
              dispatch(success({
                  items: rs.data.values,
                  total: rs.data.count
              }));
          } else {
              const oldItems = report.items9084new;
              const rsDataValues = rs.data.values;

              var uniqueItems = oldItems;
              for (var i = limit * page; i < limit * page + limit; i++) {
                  if (typeof rsDataValues[i - limit * page] !== 'undefined') {
                      uniqueItems[i] = rsDataValues[i - limit * page];
                  } else if (typeof uniqueItems[i] !== 'undefined') {
                      delete uniqueItems[i];
                  }
              }
              
              dispatch(success({
                  items: uniqueItems,
                  total: rs.data.count
              }));
          }
      }).catch((error) => {
          dispatch(failure(error));
      }).finally(() => {
          dispatch(AppAction.appLoading(false));
      })
  };
  function success(data) { return { type: REPORT_9084new_SUCCESS, data } }
  function failure(error) { return { type: REPORT_9084new_FAILD, error } }
}

// function list9084new(payload) {
//   return (dispatch, getState) => {
//     const { report } = getState()
//     dispatch(AppAction.appLoading(true));
//     dispatch(query(payload))
//     ReportService.report9084new(payload).then((rs) => {
//       if (payload.page === 0 || payload.page === `0`) {
//         dispatch(success({
//           items: rs.data.values,
//           total: rs.data.count
//         }));
//       } else {
//         dispatch(success({
//           items: [...report.items9084new, ...rs.data.values],
//           total: rs.data.count
//         }));
//       }
//     }).catch((error) => {
//       dispatch(failure(error));
//     }).finally(() => {
//       dispatch(AppAction.appLoading(false));
//     })
//   };
//   function query(data) { return { type: REPORT_9084new_QUERY, data } }
//   function success(data) { return { type: REPORT_9084new_SUCCESS, data } }
//   function failure(error) { return { type: REPORT_9084new_FAILD, error } }
// }

function list9084new1() {
    return (dispatch, getState) => {
        const { report } = getState();
        dispatch(AppAction.appLoading(true));
  
        const { filternew1 } = report;
        const page = filternew1.page;
        const limit = filternew1.limit;
  
        ReportService.report9084new1(filternew1.page, filternew1.limit, filternew1).then((rs) => {
            if (!!filternew1 && filternew1.page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                const oldItems = report.items9084new1;
                const rsDataValues = rs.data.values;
  
                var uniqueItems = oldItems;
                for (var i = limit * page; i < limit * page + limit; i++) {
                    if (typeof rsDataValues[i - limit * page] !== 'undefined') {
                        uniqueItems[i] = rsDataValues[i - limit * page];
                    } else if (typeof uniqueItems[i] !== 'undefined') {
                        delete uniqueItems[i];
                    }
                }
                
                dispatch(success({
                    items: uniqueItems,
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: REPORT_9084new1_SUCCESS, data } }
    function failure(error) { return { type: REPORT_9084new1_FAILD, error } }
  }

function list9084new2() {
    return (dispatch, getState) => {
        const { report } = getState();
        dispatch(AppAction.appLoading(true));
  
        const { filternew2 } = report;
        const page = filternew2.page;
        const limit = filternew2.limit;
  
        ReportService.report9084new2(filternew2.page, filternew2.limit, filternew2).then((rs) => {
            if (!!filternew2 && filternew2.page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                const oldItems = report.items9084new2;
                const rsDataValues = rs.data.values;
  
                var uniqueItems = oldItems;
                for (var i = limit * page; i < limit * page + limit; i++) {
                    if (typeof rsDataValues[i - limit * page] !== 'undefined') {
                        uniqueItems[i] = rsDataValues[i - limit * page];
                    } else if (typeof uniqueItems[i] !== 'undefined') {
                        delete uniqueItems[i];
                    }
                }
                
                dispatch(success({
                    items: uniqueItems,
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: REPORT_9084new2_SUCCESS, data } }
    function failure(error) { return { type: REPORT_9084new2_FAILD, error } }
  }