import { Helmet } from 'react-helmet';
import DsdttinhluongDetail
  from 'src/components/dsdttinhluong-detail/DsdttinhluongDetail';

import {
  Box,
  Container,
} from '@material-ui/core';

import DsdttinhluongListToolbar from '../components/dsdttinhluong-detail/DsdttinhluongListToolbar';

const DsdttinhluongList = () => (
  <>
    <Helmet>
      <title>Đối soát doanh thu tính lương</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container style={{maxWidth: "100%"}}>
        <DsdttinhluongListToolbar />
        <Box sx={{ pt: 3 }}>
          <DsdttinhluongDetail />
        </Box>
      </Container>
    </Box>
  </>
);

export default DsdttinhluongList;
