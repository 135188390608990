import { Helmet } from 'react-helmet';
import CustomerMessage999
  from 'src/components/customer-message/CustomerMessage999';

import {
  Box,
  Container,
} from '@material-ui/core';

const CustomerLookup = () => (
  <>
    <Helmet>
      <title>Trung tâm tin nhắn</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container style={{maxWidth: "100%"}}>
        <CustomerMessage999 />
      </Container>
    </Box>
  </>
);

export default CustomerLookup;
