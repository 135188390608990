import { AccountService } from 'src/services/account.service';
import { showToast } from 'src/utils/common';
import { NOTI_TYPES } from 'src/utils/constants';

import { AppAction } from './app.action';

import {
  PROFILE_INFO_SUCCESS,
  PROFILE_PERMISSION_SUCCESS,
  PROFILE_ROLE_SUCCESS,
} from './profile.action';

export const ACCOUNT_LIST_SUCCESS = 'ACCOUNT:FIND_ALL_SUCCESS'
export const ACCOUNT_LIST_FAILD = 'ACCOUNT:FIND_ALL_FAILD'
export const ACCOUNT_ONE_SUCCESS = 'ACCOUNT:FIND_ONE_SUCCESS'
export const ACCOUNT_ONE_FAILD = 'ACCOUNT:FIND_ONE_FAILD'
export const ACCOUNT_SELECTED_SUCCESS = 'ACCOUNT:SELECTED_SUCCESS'
export const ACCOUNT_SELECTED_FAILD = 'ACCOUNT:SELECTED_FAILD'

export const ACCOUNT_BRANCH_NO_LIMIT_LIST_SUCCESS = 'ACCOUNT:FIND_ALL_BRANCH_NO_LIMIT_SUCCESS'
export const ACCOUNT_BRANCH_NO_LIMIT_LIST_FAILD = 'ACCOUNT:FIND_ALL_BRANCH_NO_LIMIT_FAILD'

export const ACCOUNT_LEADER_NO_LIMIT_LIST_SUCCESS = 'ACCOUNT:FIND_ALL_LEADER_NO_LIMIT_SUCCESS'
export const ACCOUNT_LEADER_NO_LIMIT_LIST_FAILD = 'ACCOUNT:FIND_ALL_LEADER_NO_LIMIT_FAILD'

export const ACCOUNT_SALE_NO_LIMIT_LIST_SUCCESS = 'ACCOUNT:FIND_ALL_SALE_NO_LIMIT_SUCCESS'
export const ACCOUNT_SALE_NO_LIMIT_LIST_FAILD = 'ACCOUNT:FIND_ALL_SALE_NO_LIMIT_FAILD'

export const ACCOUNT_UPDATE_FILTER = 'ACCOUNT:UPDATE_FILTER'

export const AccountAction = {
  findByUsername,
  list,
  listNoLimit,
  update,
  create,
  resetPassword,
  del,
  lock,
  unlock,
  selectedAccount,
  listBranchNoLimit,
  listLeaderNoLimit,
  listSaleNoLimit,
  saveProfile,
  updateFilter,
  chonKenh,
  huyChonKenh,
  getdskenhmoigoi,
  findChannel,
  findOne,
}

function updateFilter(payload) {
  return { type: ACCOUNT_UPDATE_FILTER, data: payload }
}

function findOne(id) {
  return dispatch => {
    dispatch(AppAction.appLoading(true));
    AccountService.find(id)
      .then(response => {
        dispatch(success(response));
      })
      .catch(error => {
        dispatch(failure());
        if (error) {
          showToast(NOTI_TYPES.ERROR, error.msg)
        } else {
          showToast(NOTI_TYPES.ERROR, "Tạo nhân viên không thành công")
        }
      }).finally(() => {
        dispatch(AppAction.appLoading(false));
      });
  };
  function success(data) { return { type: ACCOUNT_SELECTED_SUCCESS, data } }
  function failure() { return { type: ACCOUNT_SELECTED_FAILD } }
}

function findChannel(accountUsername, channelName) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      // Perform your asynchronous operation (e.g., API call)
      AccountService.findChannel({accountUsername, channelName})
        .then(response => {
          // Handle the successful response
          resolve(response); // Resolve the promise with the response
        })
        .catch(error => {
          // Handle the error
          reject(error); // Reject the promise with the error
        });
    });
  };
}

function getdskenhmoigoi() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      // Perform your asynchronous operation (e.g., API call)
      AccountService.getdskenhmoigoi()
        .then(response => {
          // Handle the successful response
          resolve(response); // Resolve the promise with the response
        })
        .catch(error => {
          // Handle the error
          reject(error); // Reject the promise with the error
        });
    });
  };
}

function chonKenh(payload) {
  return (dispatch, getState) => {
    console.log('Chọn kênh', payload);
    dispatch(AppAction.appLoading(true));
    AccountService.chonKenh(payload).then((rs) => {
      showToast(NOTI_TYPES.SUCCESS, `Chọn kênh '${payload.channelText}' cho '${payload.accountUsername}' thành công`)
    }).catch((error) => {
      if (error) {
        showToast(NOTI_TYPES.ERROR, error.msg)
      } else {
        showToast(NOTI_TYPES.ERROR, `Chọn kênh '${payload.channelText}' cho '${payload.accountUsername}' thất bại`)
      }
    }).finally(() => {
      dispatch(AppAction.appLoading(false));
    })
  };
}

function huyChonKenh(payload) {
  return (dispatch, getState) => {
    console.log('Hủy chọn kênh', payload);
    dispatch(AppAction.appLoading(true));
    AccountService.huyChonKenh(payload).then((rs) => {
      showToast(NOTI_TYPES.SUCCESS, `Hủy chọn kênh '${payload.channelText}' cho '${payload.accountUsername}' thành công`)
    }).catch((error) => {
      if (error) {
        showToast(NOTI_TYPES.ERROR, error.msg)
      } else {
        showToast(NOTI_TYPES.ERROR, `Hủy chọn kênh '${payload.channelText}' cho '${payload.accountUsername}' thất bại`)
      }
    }).finally(() => {
      dispatch(AppAction.appLoading(false));
    })
  };
}

function saveProfile(payload) {
  return dispatch => {
    dispatch(AppAction.appLoading(true));
    AccountService.saveProfile(payload).then((rs) => {
      if (rs.status == 'failed') {
        showToast(NOTI_TYPES.ERROR, rs.msg)
      } else {
        dispatch(profileInfo(rs.data.user))
        showToast(NOTI_TYPES.SUCCESS, "Cập nhật thông tin cá nhân thành công")
      }
    }).catch((error) => {
      if (error) {
        showToast(NOTI_TYPES.ERROR, error.msg)
      } else {
        showToast(NOTI_TYPES.ERROR, "Cập nhật thông tin cá nhân thất bại")
      }
    }).finally(() => {
      dispatch(AppAction.appLoading(false));
    })
  };
  function profileInfo(data) { return { type: PROFILE_INFO_SUCCESS, data } }
}

function selectedAccount(account) {
  return dispatch => {
    dispatch(AppAction.appLoading(true));
    dispatch(success(account));
    AccountService.find(account.id)
      .then(response => {
        dispatch(success(response));
      })
      .catch(error => {
        dispatch(failure());
        if (error) {
          showToast(NOTI_TYPES.ERROR, error.msg)
        } else {
          showToast(NOTI_TYPES.ERROR, "Tạo nhân viên không thành công")
        }
      }).finally(() => {
        dispatch(AppAction.appLoading(false));
      });
  };
  function success(data) { return { type: ACCOUNT_SELECTED_SUCCESS, data } }
  function failure() { return { type: ACCOUNT_SELECTED_FAILD } }
}

function findByUsername(username) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      // Perform your asynchronous operation (e.g., API call)
      AccountService.findByUsername(username)
        .then(response => {
          // Handle the successful response
          dispatch(success(response));
          resolve(response); // Resolve the promise with the response
        })
        .catch(error => {
          // Handle the error
          dispatch(failure(error));
          reject(error); // Reject the promise with the error
        });
    });
  };

  function success(data) { return { type: ACCOUNT_ONE_SUCCESS, data } }
  function failure(error) { return { type: ACCOUNT_ONE_FAILD, error } }
}

function list() {
  return (dispatch, getState) => {
    dispatch(AppAction.appLoading(true));
    const { account } = getState();
    const { filter } = account;
    const page = filter.page;
    const limit = filter.limit;
    AccountService.findAll(page, limit, filter).then((rs) => {
      if (page === 0) {
        dispatch(success({
          items: rs.data.values,
          total: rs.data.count
        }));
      } else {
        const oldItems = account.items;
        const rsDataValues = rs.data.values;

        var uniqueItems = oldItems;
        for (var i = limit * page; i < limit * page + limit; i++) {
            if (typeof rsDataValues[i - limit * page] !== 'undefined') {
                uniqueItems[i] = rsDataValues[i - limit * page];
            } else if (typeof uniqueItems[i] !== 'undefined') {
                delete uniqueItems[i];
            }
        }
        
        dispatch(success({
          items: uniqueItems,
          total: rs.data.count
        }));
      }
    }).catch((error) => {
      dispatch(failure(error));
    }).finally(() => {
      dispatch(AppAction.appLoading(false));
    })
  };
  function success(data) { return { type: ACCOUNT_LIST_SUCCESS, data } }
  function failure(error) { return { type: ACCOUNT_LIST_FAILD, error } }
}

function listNoLimit(page = 0, count = 20,) {
  return (dispatch, getState) => {
    const { account } = getState()
    dispatch(AppAction.appLoading(true));
    AccountService.findAllNoLimit(page, count).then((rs) => {
      if (page === 0) {
        dispatch(success({
          items: rs.data.values,
          total: rs.data.count
        }));
      } else {
        dispatch(success({
          items: [...account.items, ...rs.data.values],
          total: rs.data.count
        }));
      }
    }).catch((error) => {
      dispatch(failure(error));
    }).finally(() => {
      dispatch(AppAction.appLoading(false));
    })
  };
  function success(data) { return { type: ACCOUNT_LIST_SUCCESS, data } }
  function failure(error) { return { type: ACCOUNT_LIST_FAILD, error } }
}

function listBranchNoLimit() {
  return (dispatch, getState) => {
    const { account } = getState()
    dispatch(AppAction.appLoading(true));
    AccountService.findAllBranchNoLimit().then((rs) => {
      dispatch(success({
        items: rs.data.values,
        total: rs.data.count
      }));
    }).catch((error) => {
      dispatch(failure(error));
    }).finally(() => {
      dispatch(AppAction.appLoading(false));
    })
  };
  function success(data) { return { type: ACCOUNT_BRANCH_NO_LIMIT_LIST_SUCCESS, data } }
  function failure(error) { return { type: ACCOUNT_BRANCH_NO_LIMIT_LIST_FAILD, error } }
}

function listLeaderNoLimit() {
  return (dispatch, getState) => {
    const { account } = getState()
    dispatch(AppAction.appLoading(true));
    AccountService.findAllLeaderNoLimit().then((rs) => {
      dispatch(success({
        items: rs.data.values,
        total: rs.data.count
      }));
    }).catch((error) => {
      dispatch(failure(error));
    }).finally(() => {
      dispatch(AppAction.appLoading(false));
    })
  };
  function success(data) { return { type: ACCOUNT_LEADER_NO_LIMIT_LIST_SUCCESS, data } }
  function failure(error) { return { type: ACCOUNT_LEADER_NO_LIMIT_LIST_FAILD, error } }
}

function listSaleNoLimit() {
  return (dispatch, getState) => {
    const { account } = getState()
    dispatch(AppAction.appLoading(true));
    AccountService.findAllSaleNoLimit().then((rs) => {
      dispatch(success({
        items: rs.data.values,
        total: rs.data.count
      }));
    }).catch((error) => {
      dispatch(failure(error));
    }).finally(() => {
      dispatch(AppAction.appLoading(false));
    })
  };
  function success(data) { return { type: ACCOUNT_SALE_NO_LIMIT_LIST_SUCCESS, data } }
  function failure(error) { return { type: ACCOUNT_SALE_NO_LIMIT_LIST_FAILD, error } }
}

function create(payload) {
  return (dispatch, getState) => {
    dispatch(AppAction.appLoading(true));
    const { account } = getState();
    AccountService.create(payload).then((rs) => {
      showToast(NOTI_TYPES.SUCCESS, "Tạo nhân viên thành công")
      dispatch(AccountAction.list())
    }).catch((error) => {
      if (error) {
        showToast(NOTI_TYPES.ERROR, error.msg)
      } else {
        showToast(NOTI_TYPES.ERROR, "Tạo nhân viên không thành công")
      }
    }).finally(() => {
      dispatch(AppAction.appLoading(false));
    })
  };
}

function update(id, payload) {
  return (dispatch, getState) => {
    dispatch(AppAction.appLoading(true));
    const { account } = getState();
    AccountService.update(id, payload).then((rs) => {
      showToast(NOTI_TYPES.SUCCESS, "Cập nhật nhân viên thành công")
      dispatch(AccountAction.list())
    }).catch((error) => {
      if (error) {
        showToast(NOTI_TYPES.ERROR, error.msg)
      } else {
        showToast(NOTI_TYPES.ERROR, "Cập nhật nhân viên không thành công")
      }
    }).finally(() => {
      dispatch(AppAction.appLoading(false));
    })
  };
}

function resetPassword(id, payload) {
  return dispatch => {
    dispatch(AppAction.appLoading(true));
    AccountService.resetPassword(id, payload).then((rs) => {
      showToast(NOTI_TYPES.SUCCESS, "Cấp mật khẩu mới thành công.")
    }).catch((error) => {
      if (error) {
        showToast(NOTI_TYPES.ERROR, error.msg)
      } else {
        showToast(NOTI_TYPES.ERROR, "Cấp mật khẩu mới không thành công.")
      }
    }).finally(() => {
      dispatch(AppAction.appLoading(false));
    })
  };
}


function del(id) {
  return (dispatch, getState) => {
    dispatch(AppAction.appLoading(true));
    const { account } = getState();
    AccountService.delete(id).then((rs) => {
      showToast(NOTI_TYPES.SUCCESS, "Khóa / Mở khóa nhân viên thành công")
      dispatch(AccountAction.list())
    }).catch((error) => {
      if (error) {
        showToast(NOTI_TYPES.ERROR, error.msg)
      } else {
        showToast(NOTI_TYPES.ERROR, "Khóa / Mở khóa nhân viên không thành công")
      }
    }).finally(() => {
      dispatch(AppAction.appLoading(false));
    })
  };
}


function lock(id) {
  return (dispatch, getState) => {
    dispatch(AppAction.appLoading(true));
    const { account } = getState();
    AccountService.lock(id).then((rs) => {
      showToast(NOTI_TYPES.SUCCESS, "Khoá nhân viên thành công")
      dispatch(AccountAction.list())
    }).catch((error) => {
      if (error) {
        showToast(NOTI_TYPES.ERROR, error.msg)
      } else {
        showToast(NOTI_TYPES.ERROR, "Khoá nhân viên không thành công")
      }
    }).finally(() => {
      dispatch(AppAction.appLoading(false));
    })
  };
}

function unlock(id) {
  return (dispatch, getState) => {
    dispatch(AppAction.appLoading(true));
    const { account } = getState();
    AccountService.unlock(id).then((rs) => {
      showToast(NOTI_TYPES.SUCCESS, "Mở khoá nhân viên thành công")
      dispatch(AccountAction.list())
    }).catch((error) => {
      if (error) {
        showToast(NOTI_TYPES.ERROR, error.msg)
      } else {
        showToast(NOTI_TYPES.ERROR, "Mở khoá nhân viên không thành công")
      }
    }).finally(() => {
      dispatch(AppAction.appLoading(false));
    })
  };
}